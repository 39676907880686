/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCompaniesSelector,
  getEditedIdForm,
  setEditedId,
  onConfirmEditCompany,
} from "../../store/companySlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import {updateCompany} from "../../actions/companyActions";

const emptyForm = {
  companyId: "",
  image: "",
  companyName: "",
  companyEmail: "",
  companyMobile: "",
  billingAddress: "",
  country: "",
  city: "",
  province: "",
  postalCode : ""
};

function CompanyEditModal(props) {
  const dispatch = useDispatch();
  const editedID = useSelector(getEditedIdForm);
  const companies = useSelector(getAllCompaniesSelector);
  const [animate, setAnimate] = useState(true);
  const [companyForm, setCompanyForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const onEditHandler = useCallback(() => {
    setIsTouched(true);
    const isValid = Object.keys(validForm).every((key) => validForm[key]);

    if (!isValid) {
      toast.error("Invalid Company Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

   try{
     dispatch(updateCompany(editedID, companyForm));
     toast.success("Successfully Updated Company!", {
       position: "bottom-center",
       autoClose: 2000,
     });
     dispatch(onConfirmEditCompany(companyForm));
     setIsTouched(false);
   } catch(error) {
     console.error("Error updating company:", error);
     toast.error("An error occurred. Please try again.", {
       position: "bottom-center",
       autoClose: 2000,
     });
   }

  }, [dispatch, validForm, companyForm, editedID]);

  const handlerCompanyValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setCompanyForm((prev) => {
      return { ...prev, [keyName]: value };
    });
  }, []);


  const onCancelHandler = useCallback(() => {
    dispatch(setEditedId(null));
  }, [dispatch]);

  useEffect(() => {
    setValidForm({
      image: !!companyForm.image,
      companyName: !!companyForm.companyName,
      companyEmail: !!companyForm.companyEmail,
      companyMobile: !!companyForm.companyMobile,
      billingAddress: !!companyForm.billingAddress,
      country: !!companyForm.country,
      city: !!companyForm.city,
      province: !!companyForm.province,
      postalCode: !!companyForm.province,
    });
  }, [companyForm]);

  useEffect(() => {
    if (editedID !== null) {
      setAnimate(true);
      const isFindIndex = companies.findIndex((company) => company._id === editedID);
      if (isFindIndex !== -1) {
        setCompanyForm({ ...companies[isFindIndex] });
      }
    } else {
      setAnimate(false);
    }
  }, [companies, editedID]);

  return editedID !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Edit Company Form
                    </h3>
                    <div className="mt-2">
                      {/*  */}
                      <div className="bg-white rounded-xl mt-4">

                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Company Name"
                              className={
                                !validForm.companyName&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.companyName}
                              onChange={(e) => handlerCompanyValue(e, "companyName")}
                            />
                          </div>
                        </div>

                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Company Email"
                              className={
                                !validForm.companyEmail&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.companyEmail}
                              onChange={(e) => handlerCompanyValue(e, "companyEmail")}
                            />
                          </div>
                        </div>

                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="company phone number"
                              className={
                                !validForm.companyMobile&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.companyMobile}
                              onChange={(e) => handlerCompanyValue(e, "companyMobile")}
                            />
                          </div>
                        </div>

                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="billingAddress"
                              className={
                                !validForm.billingAddress&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.billingAddress}
                              onChange={(e) => handlerCompanyValue(e, "billingAddress")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="country"
                              className={
                                !validForm.country&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.country}
                              onChange={(e) => handlerCompanyValue(e, "country")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="province"
                              className={
                                !validForm.province&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.province}
                              onChange={(e) => handlerCompanyValue(e, "province")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="city"
                              className={
                                !validForm.city&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.city}
                              onChange={(e) => handlerCompanyValue(e, "city")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="postalCode / Zip Code"
                              className={
                                !validForm.postalCode&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={companyForm.postalCode}
                              onChange={(e) => handlerCompanyValue(e, "postalCode")}
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onEditHandler}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default CompanyEditModal;
