import React from 'react';

const TrailerIcon = (props) => (
    <svg
         className="h-6 w-6"
         fill="none"
         viewBox="0 0 1024 1024"
         stroke="currentColor"
        {...props}>
        <path
            d="M0 213.333333h1024v85.333334H85.333333v341.333333h41.6v85.333333H0V213.333333z m343.466667 426.666667h115.2v85.333333h-115.2v-85.333333zM682.666667 640h341.333333v85.333333H682.666667v-85.333333z"
            fill="#0A0A0A"/>
        <path
            d="M234.666667 746.666667a64 64 0 1 0 0-128 64 64 0 0 0 0 128z m0 85.333333a149.333333 149.333333 0 1 0 0-298.666667 149.333333 149.333333 0 0 0 0 298.666667zM576 746.666667a64 64 0 1 0 0-128 64 64 0 0 0 0 128z m0 85.333333a149.333333 149.333333 0 1 0 0-298.666667 149.333333 149.333333 0 0 0 0 298.666667z"
            fill="#0A0A0A"/>
    </svg>
);

export default TrailerIcon;
