import React from "react";
import PageTitle from "../../components/Common/PageTitle";
import TractorTable from "../../components/Tractor/TractorTable";
import QuickAddTractor from "../../components/Tractor/QuickAddTractor";

function TractorListScreen() {
  return (
    <div>
      <div className="p-4">
        <PageTitle title="Tractors" />
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/6 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1">
          <TractorTable showAdvanceSearch />
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
          <QuickAddTractor />
        </div>
      </div>
    </div>
  );
}

export default TractorListScreen;
