// loadActions.jiis

import axios from "axios";
import { setAllLoads } from "../store/loadSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage"
import base_url from "../utils/config";

export const createLoad = (loadForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedLoadForm = { ...loadForm, company: company};

    const response = await axios.post(
      `${base_url}/api/loads`,
      updatedLoadForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Load created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllLoads(response.data));
  } catch (error) {
    console.error("Error creating product:", error);
  }
};
export const fetchLoadsSuccess = (data) => ({
  type: "FETCH_LOADS_SUCCESS",
  payload: data,
});

export const fetchLoadsFailure = (error) => ({
  type: "FETCH_LOADS_FAILURE",
  payload: error,
});

export const fetchLoads = () => async (dispatch) => {
  try {
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    if (currentCompany){
      const response = await axios.get(
          `${base_url}/api/loads/company/${currentCompany}`,
          config,
      );
      dispatch(setAllLoads(response.data));
    }
  } catch (error) {
    console.error("Error fetching loads:", error);
    // Handle error if needed
  }
};
export const deleteLoadSuccess = (loadId) => ({
  type: "DELETE_LOAD_SUCCESS",
  payload: loadId,
});

export const deleteLoadFailure = (error) => ({
  type: "DELETE_LOAD_FAILURE",
  payload: error,
});

export const deleteLoad = (loadId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/loads/${loadId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteLoadSuccess(loadId));
  } catch (error) {
    console.error("Error deleting load:", error);
    dispatch(deleteLoadFailure(error.message));
  }
};

export const updateLoadSuccess = (loadId) => ({
  type: "UPDATE_LOAD_SUCCESS",
  payload: loadId,
});

export const updateLoadFailure = (error) => ({
  type: "UPDATE_LOAD_FAILURE",
  payload: error,
});

export const updateLoadStatus= (loadId, statusName, statusIndex) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/loads/${loadId}`, { statusName, statusIndex}, config);
    dispatch(fetchLoads());
  } catch (error) {
    console.error("Error updating confirmation status:", error);
  }
};

export const updateLoad = (loadId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/loads/${loadId}`, data, config);
    dispatch(fetchLoads());
  } catch (error) {
    console.error("Error updating Load:", error);
    dispatch(updateLoadFailure(error.message));
  }
};
