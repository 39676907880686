/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCustomersSelector,
  getEditedIdForm,
  setEditedId,
  onConfirmEditCustomer,
} from "../../store/customerSlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultInputLargeStyle,
  defaultInputLargeInvalidStyle,
} from "../../constants/defaultStyles";
import { updateCustomer } from "../../actions/customerActions";
import ImageUpload from "../Common/ImageUpload";

const emptyForm = {
  image: "",
  name: "",
  email: "",
  billingAddress: "",
  mobileNo: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function CustomerEditModal(props) {
  const dispatch = useDispatch();
  const editedID = useSelector(getEditedIdForm);
  const customers = useSelector(getAllCustomersSelector);
  const [animate, setAnimate] = useState(true);
  const [customerForm, setCustomerForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const onEditHandler = useCallback(() => {
    setIsTouched(true);
    const isValid = Object.keys(validForm).every((key) => validForm[key]);

    if (!isValid) {
      toast.error("Invalid Customer Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    if(editedID){
      dispatch(updateCustomer(editedID, customerForm))
       .then(() => {
         toast.success("Successfully Updated Customer", {
           position: "bottom-center",
           autoClose: 2000,
         });
       })
       .finally(() => {
         dispatch(onConfirmEditCustomer(customerForm));         
          setIsTouched(false);
       });
    }
  }, [dispatch, validForm, customerForm, editedID]);

  const handlerCustomerValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setCustomerForm((prev) => {
      return { ...prev, [keyName]: value };
    });
  }, []);

  const onChangeImage = useCallback((str) => {
    setCustomerForm((prev) => ({ ...prev, image: str }));
  }, []);

  const onCancelHandler = useCallback(() => {
    dispatch(setEditedId(null));
  }, [dispatch]);

  // useCallback(() => {}, [])

  const imageUploadClasses = useMemo(() => {
    const defaultStyle = "rounded-xl ";

    if (!customerForm.image) {
      return defaultStyle + " border-dashed border-2 border-indigo-400 ";
    }

    return defaultStyle;
  }, [customerForm]);

  useEffect(() => {
    const isValidEmail =
      customerForm?.email?.trim() && customerForm?.email.match(emailRegex);

    setValidForm((prev) => ({
      image: true,
      name: customerForm?.name?.trim() ? true : false,
      email: isValidEmail ? true : false,
      billingAddress: customerForm?.billingAddress?.trim() ? true : false,
      mobileNo: customerForm?.mobileNo?.trim() ? true : false,
    }));
  }, [customerForm]);

  useEffect(() => {
    if (editedID !== null) {
      setAnimate(true);
      const isFindIndex = customers.findIndex((customer) => customer._id === editedID);
      if (isFindIndex !== -1) {
        setCustomerForm({ ...customers[isFindIndex] });
      }
    } else {
      setAnimate(false);
    }
  }, [customers, editedID]);

  return editedID !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Edited Customer Form
                    </h3>
                    <div className="mt-2">
                      {/*  */}
                      <div className="bg-white rounded-xl mt-4">
                        <div className="flex mt-2">
                          <ImageUpload
                              keyName="QuickEditImageUpload"
                              className={imageUploadClasses}
                              url={customerForm.image}
                              onChangeImage={onChangeImage}
                          />
                          <div className="flex flex-col w-full">
                            <div className="font-title text-sm text-default-color ml-4">Driver ID</div>
                            <div className="flex-1 pl-3">
                              <input
                                  autoComplete="nope"
                                  value={customerForm.name}
                                  placeholder="Company Name"
                                  className={
                                    !validForm.name && isTouched
                                        ? defaultInputLargeInvalidStyle
                                        : defaultInputLargeStyle
                                  }
                                  onChange={(e) => handlerCustomerValue(e, "name")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <div className="font-title text-sm text-default-color">Email</div>
                          <div className="flex-1">
                            <input
                                autoComplete="nope"
                                placeholder="Email Address"
                                className={
                                  !validForm.email && isTouched
                                      ? defaultInputInvalidStyle
                                      : defaultInputStyle
                                }
                                value={customerForm.email}
                                onChange={(e) => handlerCustomerValue(e, "email")}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <div className="font-title text-sm text-default-color">Phone Number</div>
                          <div className="flex-1">
                            <input
                                autoComplete="nope"
                                placeholder="Mobile No"
                                className={
                                  !validForm.mobileNo && isTouched
                                      ? defaultInputInvalidStyle
                                      : defaultInputStyle
                                }
                                value={customerForm.mobileNo}
                                onChange={(e) =>
                                    handlerCustomerValue(e, "mobileNo")
                                }
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <div className="font-title text-sm text-default-color">Billing Address</div>
                          <div className="flex-1">
                            <input
                                autoComplete="nope"
                                placeholder="Billing Address"
                                className={
                                  !validForm.billingAddress && isTouched
                                      ? defaultInputInvalidStyle
                                      : defaultInputStyle
                                }
                                value={customerForm.billingAddress}
                                onChange={(e) =>
                                    handlerCustomerValue(e, "billingAddress")
                                }
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onEditHandler}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default CustomerEditModal;
