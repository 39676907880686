// src/store/companySettingsSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    settings: null,
    loading: false,
    error: null,
};

const companySettingsSlice = createSlice({
    name: 'companySettings',
    initialState,
    reducers: {
        fetchSettingsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchSettingsSuccess(state, action) {
            state.settings = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchSettingsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateSettingsStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateSettingsSuccess(state, action) {
            state.settings = action.payload;
            state.loading = false;
            state.error = null;
        },
        updateSettingsFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchSettingsStart,
    fetchSettingsSuccess,
    fetchSettingsFailure,
    updateSettingsStart,
    updateSettingsSuccess,
    updateSettingsFailure,
} = companySettingsSlice.actions;
// Selector
export const selectCompanySettings = (state) => state.companySettings.settings;

export default companySettingsSlice.reducer;
