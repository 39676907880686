import React, { useCallback, useState } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedLoad,
        setSelectedLoad,} from "../../store/loadSlice";
import TractorIcon from "../Icons/TractorIcon";

function LoadDetailsDialog() {
  const dispatch = useDispatch();
  const selectedLoad = useSelector(getSelectedLoad);
  const [animate, setAnimate] = useState(true);


  const onCloseHandler = useCallback(() => {
    dispatch(setSelectedLoad(null));
  }, [dispatch]);


  return selectedLoad !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white-100 sm:mx-0 sm:h-10 sm:w-10">
                  <TractorIcon />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Load Details
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tractor: {selectedLoad.tractorId}
                      </p>
                      <p className="text-sm text-gray-500">
                        Driver Name: {selectedLoad.driverName}
                      </p>
                      <p className="text-sm text-gray-500">
                        Shipper: {selectedLoad.shipper}
                      </p>
                      <p className="text-sm text-gray-500">
                        consignee: {selectedLoad.consignee}
                      </p>
                      <p className="text-sm text-gray-500">
                        Start Date: {selectedLoad.startDate}
                      </p>
                      <p className="text-sm text-gray-500">
                        End Date: {selectedLoad.endDate}
                      </p>
                      <p className="text-sm text-gray-500">
                        Fuel: {selectedLoad.fuel}
                      </p>
                      <p className="text-sm text-gray-500">
                        Unit: {selectedLoad.unit}
                      </p>
                      <p className="text-sm text-gray-500">
                        Price: {selectedLoad.price}
                      </p>
                      <p className="text-sm text-gray-500">
                        Tax: {selectedLoad.tax}
                      </p>
                      <p className="text-sm text-gray-500">
                        Start Odometer: {selectedLoad.startOdometer}
                      </p>
                      <p className="text-sm text-gray-500">
                        End Odometer: {selectedLoad.endOdometer}
                      </p>
                      <p className="text-sm text-gray-500">
                        Mileage: {selectedLoad.mileage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCloseHandler}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default LoadDetailsDialog;
