import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import PageTitle from "../../components/Common/PageTitle";
import InvoiceIcon from "../../components/Icons/InvoiceIcon";
import ConfirmationTable from "../../components/Confirmation/ConfirmationTable";

function CarrierConfirmationListScreen() {
  const navigate = useNavigate();

  const goToNewConfirmation = useCallback(() => {
    navigate("/confirmations/new");
  }, [navigate]);

  return (
    <div>
      <div className="flex flex-col sm:flex-row flex-wrap p-4 w-40">
        <div className="sm:mr-4">
          <PageTitle title="Confirmations" />
        </div>
        <div className="flex-1 w-40">
          <Button onClick={goToNewConfirmation} block={1} size="sm">
            <InvoiceIcon />
            <span className="ml-2 w-40"> New Confirmation </span>
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full px-4 mb-4 sm:mb-1">
          <ConfirmationTable showAdvanceSearch />
        </div>
      </div>
    </div>
  );
}

export default CarrierConfirmationListScreen;
