import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openDriverSelector: false,
  selectedDriver: null,
  data: [],
  newForm: {
    id: nanoid(),
    driverId: 0,
    firstName: "",
    lastName: "",
    email: "",
    ownerOperator: false,
    wage: 0,
    rate: 0,
    address: "",
    phoneNumber: "",
    company: null,
  },
  editedID: null,
  deletedID: null,
};

export const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    addNewDriver: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        driverId: 0,
        firstName: "",
        lastName: "",
        email:"",
        ownerOperator: false,
        wage: 0,
        rate: 0,
        address: "",
        phoneNumber: "",
        company: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewDriverForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewDriverFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllDrivers: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedDriver: (state) => {
      state.data = state.data.filter(
        (driver) => driver.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditDriver: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (driver) => driver.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenDriverSelector: (state, action) => {
      state.openDriverSelector = action.payload;
      if (!action.payload) {
        state.selectedDriver = null;
      }
    },

    setDriverSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (driver) => driver._id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedDriver = state.data[isFindIndex];
      }
    },
    onDeleteDriverSuccess: (state, action) => {
      const driverId = action.payload;
      state.data = state.data.filter((driver) => driver.id !== driverId);
    },

    onDeleteDriverFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewDriver,
  updateNewDriverForm,
  updateNewDriverFormField,
  setAllDrivers,
  setDeleteId,
  setEditedId,
  onConfirmDeletedDriver,
  onConfirmEditDriver,
  setOpenDriverSelector,
  setDriverSelector,
  onDeleteDriverSuccess,
  onDeleteDriverFailure,
} = driversSlice.actions;

export const getAllDriversSelector = (state) => state.drivers.data;

export const getDriverNewForm = (state) => state.drivers.newForm;

export const getDeletedDriverForm = (state) => state.drivers.deletedID;

export const getEditedIdForm = (state) => state.drivers.editedID;

export const getIsOpenDriverSelector = (state) =>
  state.drivers.openDriverSelector;

export const getSelectedDriver = (state) => state.drivers.selectedDriver;

export default driversSlice.reducer;
