//eventActions.js
import axios from "axios";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";
import {
  markEventPendingSuccess,
  clearPendingEvents,
  markEventPendingFailure,
  setAllEvents,
} from "../store/eventSlice";

export const fetchEventsSuccess = (data) => ({
  type: "FETCH_EVENTS_SUCCESS",
  payload: data,
});

export const fetchEventsFailure = (error) => ({
  type: "FETCH_EVENTS_FAILURE",
  payload: error,
});

export const fetchEvents = () => async (dispatch) => {
  try {
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(`${base_url}/api/events/company/${currentCompany}`,
      config
    );
    dispatch(setAllEvents(response.data));
    return(response.data);
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};

export const deleteEventSuccess = (eventId) => ({
  type: "DELETE_EVENT_SUCCESS",
  payload: eventId,
});

export const deleteEventFailure = (error) => ({
  type: "DELETE_EVENT_FAILURE",
  payload: error,
});

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/events/${eventId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteEventSuccess(eventId));
    dispatch(fetchEvents());
  } catch (error) {
    console.error("Error deleting event:", error);
    dispatch(deleteEventFailure(error.message));
  }
};

export const createEvent = (eventForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedEventForm = { ...eventForm, company: company };

    const response = await axios.post(
      `${base_url}/api/events`,
      updatedEventForm,
      config,
    );
    if (response.status === 200) {
      dispatch(saveEventSuccess(response.data))
    }
    dispatch(setAllEvents(response.data));
  } catch (error) {
    console.error("Error creating event:", error);
  }
};

export const saveEventSuccess = (event) => ({
  type: "SAVE_EVENT_SUCCESS",
  payload: event,
});

export const saveEventFailure = (error) => ({
  type: "SAVE_EVENT_FAILURE",
  payload: error,
});

export const updateEvent= (eventId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/events/${eventId}`, data, config);
    dispatch(fetchEvents());
  } catch (error) {
    console.error("Error updating Event:", error);
    dispatch(saveEventFailure(error.message));
  }
};

export const completeEvent= (eventId, data) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    const updatedEventForm = { ...data, company: company };

    await axios.put(`${base_url}/api/events/complete/${eventId}`, updatedEventForm, config);
    dispatch(fetchEvents());
  } catch (error) {
    console.error("Error completing Event:", error);
    dispatch(saveEventFailure(error.message));
  }
};

export const markEventAsPending = (eventId) => async (dispatch) => {
  try {
    console.log(eventId);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const response = await axios.patch(`${base_url}/api/events/${eventId}/mark-pending`, {}, config);
    dispatch(markEventPendingSuccess(response.data));
  } catch (error) {
    console.error('Error marking event as pending:', error);
    dispatch(markEventPendingFailure(error.response?.data?.message || 'An error occurred'));
  }
};

export const dispatchEventsToDrivers = () => async (dispatch) => {
  try {
    const currentCompany = getCurrentCompanyId();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const response = await axios.post(`${base_url}/api/events/dispatch-to-drivers`, { companyId: currentCompany }, config);
    dispatch(clearPendingEvents());
    return response.data;
  } catch (error) {
    console.error('Error dispatching events to drivers:', error);
    throw error;
  }
};

export const fetchFuelInsights = (timeRange) => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/events/fuel-insights/${companyId}?timeRange=${timeRange}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching fuel insights:", error);
    throw error;
  }
};