/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect,  useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTrailersSelector,
  getEditedIdForm,
  setEditedId,
  onConfirmEditTrailer,
} from "../../store/trailerSlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import { updateTrailer } from "../../actions/trailerActions";

const emptyForm = {
    trailerID: 0,
    trailerMake: "",
    licensePlate: "",
    type: "",
};

function TrailerEditModal(props) {
  const dispatch = useDispatch();
  const editedID = useSelector(getEditedIdForm);
  const trailers = useSelector(getAllTrailersSelector);

  const [animate, setAnimate] = useState(true);
  const [trailerForm, setTrailerForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const onEditHandler = useCallback(() => {
    setIsTouched(true);
    const isValid = Object.keys(validForm).every((key) => validForm[key]);

    if (!isValid) {
      toast.error("Invalid Trailer Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    if (editedID) {
      dispatch(updateTrailer(editedID, trailerForm))
       .then(() => {
         toast.success("Successfully Updated Trailer", {
           position: "bottom-center",
           autoClose: 2000,
         });
       })
       .finally(() => {
         dispatch(onConfirmEditTrailer(trailerForm));         
          setIsTouched(false);
       });
     }
     
  }, [dispatch, validForm, trailerForm, editedID]);

  const handlerTrailerValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setTrailerForm((prev) => {
      return { ...prev, [keyName]: value };
    });
  }, []);

  const onCancelHandler = useCallback(() => {
    dispatch(setEditedId(null));
  }, [dispatch]);

  // useCallback(() => {}, [])


  useEffect(() => {

    setValidForm((prev) => ({
      trailerID: trailerForm.trailerID > 0,
      trailerMake: !!trailerForm.trailerMake.trim(),
      licensePlate: !!trailerForm.licensePlate.trim(),
      type: !!trailerForm.type.trim(),
    }));
  }, [trailerForm]);

  useEffect(() => {
    if (editedID !== null) {
      setAnimate(true);
      const isFindIndex = trailers.findIndex((trailer) => trailer._id === editedID);
      if (isFindIndex !== -1) {
        setTrailerForm({ ...trailers[isFindIndex] });
      }
    } else {
      setAnimate(false);
    }
  }, [trailers, editedID]);

  return editedID !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Edit Trailer Form
                    </h3>
                    <div className="mt-2">
                      {/*  */}
                      <div className="bg-white rounded-xl mt-4">
                        
                        <div className="mt-2">                          
                        <div className="font-title text-sm text-default-color">Trailer ID</div>                        
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="number"
                              min={0}
                              placeholder="Trailer ID"
                              className={
                                !validForm.trailerID && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={trailerForm.trailerID || 0}
                              onChange={(e) => handlerTrailerValue(e, "trailerID")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">                          
                        <div className="font-title text-sm text-default-color">Trailer Make</div>                        
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Trailer Make"
                              className={
                                !validForm.trailerMake && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={trailerForm.trailerMake}
                              onChange={(e) => handlerTrailerValue(e, "trailerMake")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">                          
                        <div className="font-title text-sm text-default-color">License Plate</div>                        
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="License Plate"
                              className={
                                !validForm.licensePlate && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={trailerForm.licensePlate}
                              onChange={(e) => handlerTrailerValue(e, "licensePlate")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Trailer Type</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="52ft, flatbed, etc..."
                              className={
                                !validForm.type&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={trailerForm.type || ""}
                              onChange={(e) => handlerTrailerValue(e, "type")}
                            />
                          </div>
                        </div></div>                      
                        
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onEditHandler}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default TrailerEditModal;
