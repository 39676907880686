import { configureStore } from "@reduxjs/toolkit";
import customersReducer from "./customerSlice";
import productReducer from "./productSlice";
import companyReducer from "./companySlice";
import invoiceReducer from "./invoiceSlice";
import eventReducer from "./eventSlice";
import userReducer from "./userSlice";
import mileageReducer from "./mileageSlice";
import partiesReducer from "./partySlice";
import tractorsReducer from "./tractorSlice";
import carriersReducer from "./carrierSlice";
import driversReducer from "./driverSlice";
import confirmationReducer from "./confirmationSlice"
import loadsReducer from "./loadSlice";
import trailersReducer from "./trailerSlice"
import companySettingsReducer from "./companySettingsSlice";
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    customers: customersReducer,
    parties: partiesReducer,
    company: companyReducer,
    products: productReducer,
    invoices: invoiceReducer,
    confirmations: confirmationReducer,
    user: userReducer,
    events: eventReducer,
    mileages: mileageReducer,
    tractors: tractorsReducer,
    trailers: trailersReducer,
    carriers: carriersReducer,
    drivers: driversReducer,
    loads: loadsReducer,
    companySettings: companySettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});
