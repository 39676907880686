import React from "react";
import LoadTabs from "../../components/Loads/LoadTabs";

function LoadsPage() {
  return (
    <div>
      <LoadTabs />
    </div>
  );
}

export default LoadsPage;
