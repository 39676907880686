/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPartiesSelector,
  getEditedIdForm,
  setEditedId,
  onConfirmEditParty,
} from "../../store/partySlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
} from "../../constants/defaultStyles";

import { updateParty } from "../../actions/partyActions";

const emptyForm = {
  name: "",
  billingAddress: "",
  city: "",
  province: "",
  postalCode: "",
  type: "",
};


function PartyEditModal() {
  const dispatch = useDispatch();
  const editedID = useSelector(getEditedIdForm);
  const parties = useSelector(getAllPartiesSelector);
  const [animate, setAnimate] = useState(true);
  const [partyForm, setPartyForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const onEditHandler = useCallback(() => {
    setIsTouched(true);
    const isValid = Object.keys(validForm).every((key) => validForm[key]);

    if (!isValid) {
      toast.error("Invalid Party Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    if (editedID) {
      const updatedPartForm = { ...partyForm, type: selectedOption};

      dispatch(updateParty(editedID, updatedPartForm))
       .then(() => {
         toast.success("Successfully Updated Party", {
           position: "bottom-center",
           autoClose: 2000,
         });
       })
       .finally(() => {
         dispatch(onConfirmEditParty(partyForm));         
          setIsTouched(false);
       });
     }

  }, [dispatch, validForm, partyForm, editedID, selectedOption]);

  const handlerPartyValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setPartyForm((prev) => {
      return { ...prev, [keyName]: value };
    });
  }, []);

  const onCancelHandler = useCallback(() => {
    dispatch(setEditedId(null));
  }, [dispatch]);

  useEffect(() => {
    setValidForm({
      name: partyForm.name? partyForm.name.trim() !== "" : false,
      billingAddress: partyForm.billingAddress? partyForm.billingAddress.trim() !== "" : false,
      city: partyForm.city? partyForm.city.trim() !== "" : false,
      province: partyForm.province? partyForm.province.trim() !== "" : false,
      postalCode: partyForm.postalCode? partyForm.postalCode.trim() !== "" : false,
      type: selectedOption? selectedOption.trim() !== "" : false,
    });
}, [partyForm, selectedOption]);

  useEffect(() => {
    if (editedID !== null) {
      setAnimate(true);
      const isFindIndex = parties.findIndex((party) => party._id === editedID);
      if (isFindIndex !== -1) {
        setPartyForm({ ...parties[isFindIndex] });
        setSelectedOption(parties[isFindIndex]?.type);
      }
    } else {
      setAnimate(false);
    }
  }, [parties, editedID]);

  return editedID !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Edit party
                    </h3>
                    <div className="mt-2">
                      {/*  */}
                      <div className="bg-white rounded-xl mt-4">
                        <div className="flex mt-2">
                          <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                              <option value="SHIPPER">Shipper</option>
                              <option value="CONSIGNEE">Consignee</option>
                          </select>

                          <div className="flex-1 flex-col pl-3">
                            <div className="font-title text-sm text-default-color">{selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1).toLowerCase()} Name</div>
                            <input
                                autoComplete="nope"
                                value={partyForm.name || ""}
                                placeholder="Name"
                                className={
                                  !validForm.name && isTouched
                                      ? defaultInputInvalidStyle
                                      : defaultInputStyle
                                }
                                onChange={(e) => handlerPartyValue(e, "name")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1 flex-col">
                            <div className="font-title text-sm text-default-color">
                             Billing Address
                            </div>
                            <input
                                autoComplete="nope"
                                placeholder="billingAddress"
                                className={
                                  !validForm.billingAddress && isTouched
                                      ? defaultInputInvalidStyle
                                      : defaultInputStyle
                                }
                                value={partyForm.billingAddress || ""}
                                onChange={(e) => handlerPartyValue(e, "billingAddress")}
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1 flex-col">
                            <div className="font-title text-sm text-default-color">
                              City
                            </div>
                            <input
                                autoComplete="nope"
                                placeholder="City"
                                className={
                                  !validForm.city && isTouched
                                      ? defaultInputInvalidStyle : defaultInputStyle
                                }
                                value={partyForm.city || ""}
                                onChange={(e) =>
                                    handlerPartyValue(e, "city")
                                }
                            />
                          </div>
                        </div>
                        <div className="flex mt-2">
                          <div className="flex-1 flex-col">
                            <div className="font-title text-sm text-default-color">
                              Billing Address
                            </div>
                          <input
                              autoComplete="nope"
                              placeholder="province"
                              className={
                                !validForm.province && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={partyForm.province || ""}
                              onChange={(e) =>
                                handlerPartyValue(e, "province")
                              }
                            />
                          </div>
                           <div className="flex-1 pl-3">
                             <div className="font-title text-sm text-default-color">
                              Postal / Zip Code
                             </div>
                             <input
                              autoComplete="nope"
                              placeholder="postal code"
                              className={
                                !validForm.postalCode && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={partyForm.postalCode || ""}
                              onChange={(e) =>
                                handlerPartyValue(e, "postalCode")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onEditHandler}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default PartyEditModal;
