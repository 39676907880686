import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import useInitApp from "./hook/useInitApp";
import CustomerDeleteConfirm from "./components/Customers/CustomerDeleteConfirm";
import CustomerEditModal from "./components/Customers/CustomerEditModal";
import ProductDeleteConfirm from "./components/Product/ProductDeleteConfirm";
import ProductEditModal from "./components/Product/ProductEditModal";
import CustomerChooseModal from "./components/Customers/CustomerChooseModal";
import ProductChooseModal from "./components/Product/ProductChooseModal";
import InvoiceSettingModal from "./components/Invoice/InvoiceSettingModal";
import InvoiceConfirmModal from "./components/Invoice/InvoiceConfirmModal";
import InvoiceDeleteConfirm from "./components/Invoice/InvoiceDeleteConfirm";
import PartyChooseModal from "./components/Party/PartyChooseModal";
import PartyEditModal from "./components/Party/PartyEditModal";
import PartyDeleteConfirm from "./components/Party/PartyDeleteConfirm"
import TractorDeleteConfirm from "./components/Tractor/TractorDeleteConfirm";
import TractorEditModal from "./components/Tractor/TractorEditModal";
import TractorChooseModal from "./components/Tractor/TractorChooseModal";
import TrailerDeleteConfirm from "./components/Trailer/TrailerDeleteConfirm";
import TrailerEditModal from "./components/Trailer/TrailerEditModal";
import TrailerChooseModal from "./components/Trailer/TrailerChooseModal";
import CarrierChooseModal from "./components/Carrier/CarrierChooseModal";
import CarrierDeleteConfirm from "./components/Carrier/CarrierDeleteConfirm";
import DriverChooseModal from "./components/Driver/DriverChooseModal";
import DriverEditModal from "./components/Driver/DriverEditModal";
import DriverDeleteConfirm from "./components/Driver/DriverDeleteConfirm";
import UserAddDialog from "./components/Company/UserAddDialog";
import CreateDriverAccountDialog from "./components/Company/CreateDriverAccountDialog";
import CompanyEditModal from "./components/Company/CompanyEditModal";
import CompanyDeleteConfirm from "./components/Company/CompanyDeleteConfirm";
import CompanyAddDialog from "./components/Company/CompanyAddDialog";
import CarrierEditModal from "./components/Carrier/CarrierEditModal";
import LoadAddDialog from "./components/Loads/LoadAddDialog";
import LoadChooseModal from "./components/Loads/LoadChooseModal";
import LoadDetailsDialog from "./components/Loads/LoadDetailsDialog";
import LoadDeleteConfirm from "./components/Loads/LoadDeleteConfrm";
import LoadEditModal from "./components/Loads/LoadEditModal";
import ConfirmationConfirmModal from "./components/Confirmation/ConfirmationConfirmModal"
import ConfirmationSettingModal from "./components/Confirmation/ConfirmationSettingModal"
import ConfirmationDeleteConfirm from "./components/Confirmation/ConfirmationDeleteConfirm"

import PageLoading from "./components/Common/PageLoading";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import axios from "axios";
import { useState } from "react";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes"
import { AnimatePresence } from "framer-motion";
import { getCurrentCompanyId } from "./utils/storage";
import base_url from "./utils/config"
import { setCurrentCompany } from "./store/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {getAllCompaniesSelector, setCompanySelector, setDefaultCompany} from "./store/companySlice";
import ErrorPage from "./pages/error/ErrorPage";
import {fetchCompanies} from "./actions/companyActions";
import {fetchCompanySettings} from "./actions/companySettingsActions";

const App = () => {
  const { initialSetData } = useInitApp();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const companyId = getCurrentCompanyId();
  const companies = useSelector(getAllCompaniesSelector);

  useEffect(() => {
    if (companyId){
      dispatch(fetchCompanies()).then(() => (
          console.log("400")
      ));
      dispatch(fetchCompanySettings()).then(() => {
        console.log("400")
      });
    }
  }, [companyId, dispatch]);

  useEffect(() => {
  const checkAuth = async () => {
    try {
      const response = await axios.get(`${base_url}/api/auth/check`, { withCredentials: true });
      setIsAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  checkAuth();
}, [isAuthenticated]);

  useEffect(() => {
    initialSetData();
  }, [initialSetData]);

  useEffect(() => {
    if (companies.length > 0 && companyId) {
      dispatch(setCompanySelector(companyId));
      dispatch(setCurrentCompany(companies[0]));
      dispatch(setDefaultCompany(companies[0]))
    }
  }, [companies, companyId, dispatch]);

  return (
    <BrowserRouter>
    <AnimatePresence mode="wait">
      <Routes>
        {isAuthenticated ? (
          <>
          <Route path="*" element={<AuthenticatedRoutes />} />
          <Route path="/login" element={<Navigate replace to="/" />} />
          <Route path="/register" element={<Navigate replace to="/" />} />
          </>
        ) : (
          <>
            <Route path="/register" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </>
        )}
        <Route component={ErrorPage} /> {/* Catch all 404 errors (prod) */}
      </Routes>
      </AnimatePresence>
      <ToastContainer />
      <CustomerDeleteConfirm />
      <CustomerEditModal />
      <CustomerChooseModal />
      <ProductDeleteConfirm />
      <ProductEditModal />
      <ProductChooseModal />
      <InvoiceSettingModal />
      <InvoiceConfirmModal />
      <InvoiceDeleteConfirm />
      <ConfirmationConfirmModal/>
      <ConfirmationSettingModal />
      <ConfirmationDeleteConfirm/>
      <PartyChooseModal/>
      <PartyEditModal/>
      <PartyDeleteConfirm />
      <TractorDeleteConfirm />
      <TractorEditModal />
      <TractorChooseModal />
      <TrailerDeleteConfirm />
      <TrailerChooseModal />
      <TrailerEditModal />
      <LoadAddDialog />
      <LoadChooseModal />
      <LoadDetailsDialog />
      <LoadDeleteConfirm />
      <LoadEditModal />
      <CarrierChooseModal />
      <CarrierDeleteConfirm />
      <CarrierEditModal />
      <DriverChooseModal />
      <DriverEditModal/>
      <DriverDeleteConfirm />
      <UserAddDialog />
      <CreateDriverAccountDialog/>
      <CompanyEditModal />
      <CompanyDeleteConfirm />
      <CompanyAddDialog />
      <PageLoading />
    </BrowserRouter>
  );
};

export default App;