import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openLoadSelector: false,
  openLoadAdder: false,
  selectedLoad: null,
  data: [],
  newForm: {
    id: nanoid(),
    tractor: {},
    driver: {},
    shipper: {},
    consignee: {},
    startDate: "",
    endDate: "",
    fuel: 0,
    unit: "",
    price: 0,
    tax: 0,
    startOdometer: 0,
    endOdometer: 0,
    mileage: 0,    
  },
  editedID: null,
  deletedID: null,
};

export const loadsSlice = createSlice({
  name: "loads",
  initialState,
  reducers: {
    addNewLoad: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        user: null,
        tractor: {},
        driver:{},
        shipper: {},
        consignee: {},
        startDate: "",
        endDate: "",
        fuel: 0,
        unit: "",
        price: 0,
        tax: 0,
        startOdometer: 0,
        endOdometer: 0,
        mileage: 0,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewLoadForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewLoadFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllLoads: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    setSelectedLoad: (state, action) => {
      state.selectedLoad = action.payload;
    },

    onConfirmDeletedLoad: (state) => {
      state.data = state.data.filter(
        (load) => load.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditLoad: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (load) => load.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenLoadAdder: (state, action) => {
      state.openLoadAdder = action.payload;
    },

    setOpenLoadSelector: (state, action) => {
      state.openLoadSelector = action.payload;
      
    },

    setLoadSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (load) => load._id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedLoad = state.data[isFindIndex];
      }
    },
    onDeleteLoadSuccess: (state, action) => {
      const loadId = action.payload;
      state.data = state.data.filter((load) => load.id !== loadId);
    },

  },
});

export const {
  addNewLoad,
  updateNewLoadForm,
  updateNewLoadFormField,
  setAllLoads,
  setDeleteId,
  setEditedId,
  onConfirmDeletedLoad,
  onConfirmEditLoad,
  setOpenLoadAdder,
  setOpenLoadSelector,
  setLoadSelector,
  setSelectedLoad,
  onDeleteLoadSuccess,
  onDeleteLoadFailure,
} = loadsSlice.actions;

export const getAllLoadsSelector = (state) => state.loads.data;

export const getLoadNewForm = (state) => state.loads.newForm;

export const getDeletedLoadForm = (state) => state.loads.deletedID;

export const getEditedIdForm = (state) => state.loads.editedID;

export const getIsOpenLoadSelector = (state) =>
  state.loads.openLoadSelector;

export const getIsOpenLoadAdder = (state) =>
  state.loads.openLoadAdder;

export const getSelectedLoad = (state) => state.loads.selectedLoad;

export default loadsSlice.reducer;
