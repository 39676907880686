// tractorActions.js

import axios from "axios";
import { setAllTractors } from "../store/tractorSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createTractor = (tractorForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedTractorForm = { ...tractorForm, company: company};

    const response = await axios.post(
      `${base_url}/api/tractors`,
      updatedTractorForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Tractor created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllTractors(response.data));
  } catch (error) {
    console.error("Error creating tractor:", error);
    toast.error("Error creating tractor: " + error.message, {
      position: "bottom-center",
      autoClose: 2000,
    });
  }
};
export const fetchTractorsSuccess = (data) => ({
  type: "FETCH_TRACTORS_SUCCESS",
  payload: data,
});

export const fetchTractorsFailure = (error) => ({
  type: "FETCH_TRACTORS_FAILURE",
  payload: error,
});

export const fetchTractors = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/tractors/company/${currentCompany}`,
      config,
    );
    dispatch(setAllTractors(response.data));
  } catch (error) {
    console.error("Error fetching tractors:", error);
    // Handle error if needed
  }
};
export const deleteTractorSuccess = (tractorId) => ({
  type: "DELETE_TRACTOR_SUCCESS",
  payload: tractorId,
});

export const deleteTractorFailure = (error) => ({
  type: "DELETE_TRACTOR_FAILURE",
  payload: error,
});

export const deleteTractor = (tractorId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/tractors/${tractorId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteTractorSuccess(tractorId));
  } catch (error) {
    console.error("Error deleting tractor:", error);
    dispatch(deleteTractorFailure(error.message));
  }
};

export const saveTractorSuccess = (tractor) => ({
  type: "SAVE_Tractor_SUCCESS",
  payload: tractor,
});

export const saveTractorFailure = (error) => ({
  type: "SAVE_Tractor_FAILURE",
  payload: error,
});

export const updateTractor= (tractorId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/tractors/${tractorId}`, data, config);
    dispatch(fetchTractors());
  } catch (error) {
    console.error("Error updating Tractor:", error);
    dispatch(saveTractorFailure(error.message));
  }
};
