// trailerActions.js

import axios from "axios";
import { setAllTrailers } from "../store/trailerSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createTrailer = (trailerForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedTrailerForm = { ...trailerForm, company: company};

    const response = await axios.post(
      `${base_url}/api/trailers`,
      updatedTrailerForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Trailer created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllTrailers(response.data));
  } catch (error) {
    console.error("Error creating trailer:", error);
    toast.error("Error creating trailer: " + error.message, {
      position: "bottom-center",
      autoClose: 2000,
    });
  }
};
export const fetchTrailersSuccess = (data) => ({
  type: "FETCH_TRACTORS_SUCCESS",
  payload: data,
});

export const fetchTrailersFailure = (error) => ({
  type: "FETCH_TRACTORS_FAILURE",
  payload: error,
});

export const fetchTrailers = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/trailers/company/${currentCompany}`,
      config,
    );
    dispatch(setAllTrailers(response.data));
  } catch (error) {
    console.error("Error fetching trailers:", error);
    // Handle error if needed
  }
};
export const deleteTrailerSuccess = (trailerId) => ({
  type: "DELETE_TRACTOR_SUCCESS",
  payload: trailerId,
});

export const deleteTrailerFailure = (error) => ({
  type: "DELETE_TRACTOR_FAILURE",
  payload: error,
});

export const deleteTrailer = (trailerId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/trailers/${trailerId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteTrailerSuccess(trailerId));
  } catch (error) {
    console.error("Error deleting trailer:", error);
    dispatch(deleteTrailerFailure(error.message));
  }
};

export const saveTrailerSuccess = (trailer) => ({
  type: "SAVE_Trailer_SUCCESS",
  payload: trailer,
});

export const saveTrailerFailure = (error) => ({
  type: "SAVE_Trailer_FAILURE",
  payload: error,
});

export const updateTrailer= (trailerId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/trailers/${trailerId}`, data, config);
    dispatch(fetchTrailers());
  } catch (error) {
    console.error("Error updating Trailer:", error);
    dispatch(saveTrailerFailure(error.message));
  }
};
