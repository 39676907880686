/* eslint-disable no-useless-escape */
import React, {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {useSelector, useDispatch} from "react-redux";
import {
    clearInvoiceSelector,
    getCurrentBGImage,
    getCurrentColor,
    getInvoiceSettingModal,
    getSelectedInvoice,
    setDefaultBackground,
    setDefaultColor,
    setSettingModalOpen,
} from "../../store/invoiceSlice";
import imageData from "../../shared/imageData.json";
import colorData from "../../shared/colorData.json";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from "@mui/material";
import {toast} from "react-toastify";
import {createInvoice} from "../../actions/invoiceActions";

function InvoiceSettingModal() {
    const dispatch = useDispatch();
    const openModal = useSelector(getInvoiceSettingModal);
    const currentBg = useSelector(getCurrentBGImage);
    const currentColor = useSelector(getCurrentColor);
    const selectedInvoice = useSelector(getSelectedInvoice);

    const [animate, setAnimate] = useState(true);
    const [numDuplicates, setNumDuplicates] = useState(1);
    const [creatingDuplicates, setCreatingDuplicates] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const onCancelHandler = useCallback(() => {
        dispatch(setSettingModalOpen(false));
    }, [dispatch]);

    const onClickBg = useCallback(
        (item) => {
            dispatch(setDefaultBackground(item));
        },
        [dispatch]
    );

    const onClickColor = useCallback(
        (item) => {
            dispatch(setDefaultColor(item));
        },
        [dispatch]
    );

    useEffect(() => {
        if (openModal) {
            setAnimate(true);
        } else {
            setAnimate(false);
        }
    }, [openModal]);

    const handleDuplicateInvoices = () => {
        if (numDuplicates > 5) {
            toast.error("You cannot create more than 5 duplicates at a time.", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }
        setShowConfirmation(true);
    };

    const confirmDuplicateInvoices = async () => {
        setShowConfirmation(false);
        setCreatingDuplicates(true);
        setProgress(0);

        const createDuplicate = async () => {
            const {_id, ...newInvoice} = selectedInvoice;
            await dispatch(createInvoice(newInvoice)).then(() => {
                // warning go away please I hate you
            });
            setProgress((prevProgress) => prevProgress + 1);
        };

        for (let i = 0; i < numDuplicates; i++) {
            setTimeout(() => createDuplicate(i), i * 1500);
        }

        setTimeout(() => {
            setCreatingDuplicates(false);
            toast.success(`Successfully created ${numDuplicates} duplicates`, {
                position: "bottom-center",
                autoClose: 2000,
            });
            dispatch(clearInvoiceSelector());
        }, numDuplicates * 1500);
    };

    return openModal ? (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: animate ? 1 : 0,
            }}
            transition={{
                type: "spring",
                damping: 18,
            }}
        >
            <div className="relative">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex justify-center min-h-full p-4 text-center">
                        <div
                            className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 flex flex-col w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex-1">
                                <div className="font-title mb-2">Choose Color</div>

                                <div className="flex flex-row flex-wrap mb-2">
                                    {colorData.map((color) => (
                                        <span
                                            key={color}
                                            onClick={() => onClickColor(color)}
                                            className={
                                                "inline-block w-8 h-8 mx-2 cursor-pointer " +
                                                (currentColor === color
                                                    ? " border-2 border-blue-500 "
                                                    : " rounded-2xl scale-75")
                                            }
                                            style={{backgroundColor: color}}
                                        ></span>
                                    ))}
                                </div>

                                <div className="font-title mb-2">Choose Background Image</div>

                                <div className="grid grid-cols-3 sm:grid-cols-5 gap-3 mx-auto">
                                    {imageData.map((image) => (
                                        <div
                                            className={
                                                "w-full overflow-hidden h-24 cursor-pointer " +
                                                (currentBg.id === image.id
                                                    ? " border-4 border-blue-500 "
                                                    : " rounded-2xl scale-75")
                                            }
                                            key={image.id}
                                            onClick={() => onClickBg(image)}
                                        >
                                            <img
                                                src={image.base64}
                                                alt="imagezz"
                                                className="object-cover w-full h-full"
                                            />
                                        </div>
                                    ))}
                                </div>
                                {selectedInvoice !== null&& (
                                    <>
                                        <div className="flex flex-col w-[20%] md:w-[20%] sm:w-full">
                                            <div className="font-title mb-2">Create Duplicate Invoices</div>
                                            <input
                                                type="number"
                                                value={numDuplicates}
                                                onChange={(e) => setNumDuplicates(Math.min(Math.max(e.target.value, 1), 10))}
                                                className="border p-2 rounded"
                                                placeholder="Number of Duplicates (1-10)"
                                            />
                                            {creatingDuplicates && (
                                                <div className="flex items-center mt-4">
                                                    <CircularProgress size={20} className="mr-2"/>
                                                    <span>Creating {progress} of {numDuplicates} invoices...</span>
                                                </div>
                                            )}
                                            <button
                                                type="button"
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={handleDuplicateInvoices}
                                                disabled={creatingDuplicates}
                                            >
                                                Create Duplicates
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onCancelHandler}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
            >
                <DialogTitle>Confirm Duplicate Invoices</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create {numDuplicates} duplicates of the current invoice? Please ensure
                        all the
                        details in the invoice form are correct.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowConfirmation(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDuplicateInvoices} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </motion.div>
    ) : null;
}

export default InvoiceSettingModal;

