import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCarriersSelector,
  setDeleteId,
  setEditedId,
} from "../../store/carrierSlice";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
  defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import EmptyBar from "../Common/EmptyBar";
import { useAppContext } from "../../context/AppContext";
import { fetchCarriers } from "../../actions/carrierActions";
import PencilIcon from "../Icons/PencilIcon";

const itemsPerPage = 10;
const emptySearchForm = {
  carrierId: "",
  carrierName: "",
};

function CarrierTable({ showAdvanceSearch = false }) {
  const { initLoading } = useAppContext();
  const dispatch = useDispatch();
  const allCarriers = useSelector(getAllCarriersSelector);

  const [searchForm, setSearchForm] = useState(emptySearchForm);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [newCarrierCreated, setNewCarrierCreated] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const carriers = useMemo(() => {
    let filterData = allCarriers.length > 0 ? [...allCarriers].reverse() : [];
    if (searchForm.carrierId) {
      filterData = filterData.filter((carrier) => {
        var str = carrier.carrierId + "";
        return str.includes(searchForm.carrierId);
      });
    }

    if (searchForm.carrierName?.trim()) {
      filterData = filterData.filter((carrier) =>
        carrier.carrierName.includes(searchForm.carrierName),
      );
    }

    return filterData;
  }, [allCarriers, searchForm]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % carriers.length;
    setItemOffset(newOffset);
  };

  const handleDelete = (carrier) => {
    dispatch(setDeleteId(carrier._id));
    setIsDeleteConfirmOpen(true);
  };

  const handleEdit = useCallback(
    (carrier) => {
      dispatch(setEditedId(carrier._id));
      
    },
    [dispatch],
  );

  const handlerSearchValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setSearchForm((prev) => {
      return { ...prev, [keyName]: value };
    });

    setItemOffset(0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchCarriers());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    setNewCarrierCreated(false);
  }, [dispatch, newCarrierCreated]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(carriers.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(carriers.length / itemsPerPage));
  }, [carriers, itemOffset]);

  return (
    <>
      {showAdvanceSearch === true && (
        <div className="bg-white rounded-xl px-3 py-3 mb-3">
          <div className="font-title mb-2">Advanced Search</div>
          <div className="flex w-full flex-col sm:flex-row">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-gray-400">
                <PencilIcon />
              </div>
              <input
                autoComplete="nope"
                value={searchForm.carrierId}
                placeholder="Carrier Id"
                className={defaultSearchStyle}
                onChange={(e) => handlerSearchValue(e, "carrierId")}
              />
            </div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-gray-400">
                <PencilIcon />
              </div>
              <input
                autoComplete="nope"
                value={searchForm.carrierName}
                placeholder="Carrier Name"
                className={defaultSearchStyle}
                onChange={(e) => handlerSearchValue(e, "carrierName")}
              />
            </div>
          </div>
        </div>
      )}

      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
          <div className="sm:text-left text-default-color font-title flex-1">
            Carrier ID
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Carrier Name
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Address
          </div>
          <div className="sm:text-left text-default-color font-title sm:w-11">
            Action
          </div>
        </div>

        <div>
          {currentItems &&
            currentItems.map((carrier) => (
              <div className={defaultTdWrapperStyle} key={carrier._id}>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>CarrierId</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {carrier.carrierId || "#"}
                    </span>
                  </div>
                </div>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Carrier Name</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {carrier.carrierName}
                    </span>
                  </div>
                </div>

                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Type</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {carrier.address}
                    </span>
                  </div>
                </div>

                <div className={defaultTdActionStyle}>
                  <div className={defaultTdContentTitleStyle}>Action</div>
                  <div className={defaultTdContent}>
                    <Menu
                      menuButton={
                        <MenuButton>
                          <div className="bg-gray-50 px-2 rounded-xl">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </div>
                        </MenuButton>
                      }
                      transition
                    >
                      <MenuItem onClick={() => handleEdit(carrier)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(carrier)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}

          {carriers.length <= 0 && !initLoading && <EmptyBar />}

          {carriers.length > 0 && (
            <ReactPaginate
              className="inline-flex items-center -space-x-px mt-2"
              previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="<"
              nextLabel={">"}
              renderOnZeroPageCount={null}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default CarrierTable;

