import Container from "../components/Container/Container";
import DashboardScreen from "../pages/DashboardScreen";
import CustomerListScreen from "../pages/customers/CustomerListScreen";
import ProductListScreen from "../pages/products/ProductListScreen";
import InvoiceListScreen from "../pages/invoices/InvoiceListScreen";
import DriverListScreen from "../pages/drivers/DriverListScreen";
import InvoiceDetailScreen from "../pages/invoices/InvoiceDetailScreen";
import Schedule from "../pages/schedule/Schedule";
import AccountSettings from "../pages/settings/AccountSettings";
import { Route, Routes } from "react-router-dom";
import CompanySettings from "../pages/settings/CompanySettings";
import PartyListScreen from "../pages/parties/PartyListScreen";
import CarrierListScreen from "../pages/carriers/CarrierListScreen";
import TractorListScreen from "../pages/tractors/TractorListScreen";
import TrailerListScreen from "../pages/trailers/TrailerListScreen";
import CarrierConfirmationDetailScreen from "../pages/carrierConfirmation/CarrierConfirmationDetailScreen";
import CarrierConfirmationListScreen from "../pages/carrierConfirmation/CarrierConfirmationListScreen";

import LoadsPage from "../pages/loads/LoadsPage";
import RateCalculator from "../components/RateCalculator/RateCalculator";
import InvoiceBatchReportPage from "../pages/invoices/InvoiceBatchReportPage";

const AuthenticatedRoutes = () => (
  <Container>
    <Routes>
      <Route index element={<DashboardScreen />} />
      <Route path="customers/*" element={<CustomerListScreen />} />
      <Route path="products/*" element={<ProductListScreen />} />
      <Route path="parties/*" element={<PartyListScreen />} />
      <Route path="invoices/*">
        <Route index element={<InvoiceListScreen />} />
        <Route path=":id" element={<InvoiceDetailScreen />} />
      </Route>
      <Route path="invoice-batch-report" element={<InvoiceBatchReportPage />} />
      <Route path="confirmations/*">
        <Route index element={<CarrierConfirmationListScreen />} />
        <Route path=":id" element={<CarrierConfirmationDetailScreen />} />
      </Route>
      <Route path="tractors/*" element={<TractorListScreen />} />
      <Route path="trailers/*" element={<TrailerListScreen />} />
      <Route path="loads/*" element={<LoadsPage />} />
      <Route path="carriers/*" element={<CarrierListScreen />} />
      <Route path="drivers/*" element={<DriverListScreen />} />
      <Route path="settings/user" element={<AccountSettings />} />
      <Route path="settings/company" element={<CompanySettings/>} />
      <Route path="schedule/*" element={<Schedule />} />
      <Route path="calculator/*" element={<RateCalculator/>} />

    </Routes>
  </Container>
);

export default AuthenticatedRoutes;
