import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openTractorSelector: false,
  selectedTractor: null,
  data: [],
  newForm: {
    id: nanoid(),
    tractorID: 0,
    tractorMake: "",
    licensePlate: "",
    odometerReading: 0,
    assignedDriver: "",
    company: null
  },
  editedID: null,
  deletedID: null,
};

export const tractorsSlice = createSlice({
  name: "tractors",
  initialState,
  reducers: {
    addNewTractor: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        tractorID: "",
        tractorMake: "",
        licensePlate: "",
        odometerReading: "",
        assignedDriver: "",
        company: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewTractorForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewTractorFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllTractors: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedTractor: (state) => {
      state.data = state.data.filter(
        (tractor) => tractor.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditTractor: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (tractor) => tractor.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenTractorSelector: (state, action) => {
      state.openTractorSelector = action.payload;
      if (!action.payload) {
        state.selectedTractor = null;
      }
    },

    setTractorSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (tractor) => tractor.id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedTractor = state.data[isFindIndex];
      }
    },
    onDeleteTractorSuccess: (state, action) => {
      const tractorId = action.payload;
      state.data = state.data.filter((tractor) => tractor.id !== tractorId);
    },

    onDeleteTractorFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewTractor,
  updateNewTractorForm,
  updateNewTractorFormField,
  setAllTractors,
  setDeleteId,
  setEditedId,
  onConfirmDeletedTractor,
  onConfirmEditTractor,
  setOpenTractorSelector,
  setTractorSelector,
  onDeleteTractorSuccess,
  onDeleteTractorFailure,
} = tractorsSlice.actions;

export const getAllTractorsSelector = (state) => state.tractors.data;

export const getTractorNewForm = (state) => state.tractors.newForm;

export const getDeletedTractorForm = (state) => state.tractors.deletedID;

export const getEditedIdForm = (state) => state.tractors.editedID;

export const getIsOpenTractorSelector = (state) =>
  state.tractors.openTractorSelector;

export const getSelectedTractor = (state) => state.tractors.selectedTractor;

export default tractorsSlice.reducer;
