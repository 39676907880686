import React, {useEffect, useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SettingsNavbar from "./SettingsNavbar";
import {
    getSelectedCompany, setOpenUserAdder, setCompanySelector, getCompanyData, setOpenAddCompany, setOpenDriverAdder,
} from "../../store/companySlice";
import {
    fetchAndSetUsersByCompany,
} from "../../actions/companyActions";
import Button from "../../components/Button/Button";
import CompanyTable from "../../components/Company/CompanyTable";
import InfoIcon from "../../components/Icons/InfoIcon";
import {Modal, Box, TextField} from '@mui/material';
import {toast} from "react-toastify";
import EmployeeTable from "../../components/Employee/EmployeeTable";
import {fetchCompanySettings, updateCompanySettings} from "../../actions/companySettingsActions";
import {selectCompanySettings} from "../../store/companySettingsSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function CompanySettings() {
    const dispatch = useDispatch();
    const selectedCompany = useSelector(getSelectedCompany);
    const companies = useSelector(getCompanyData);
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isInvoiceTermsModalOpen, setIsInvoiceTermsModalOpen] = useState(false);

    const openAddUser = useCallback(() => {
        dispatch(setOpenUserAdder(true));
    }, [dispatch]);
    const openAddDriver = useCallback(() => {
        dispatch(setOpenDriverAdder(true));
    }, [dispatch]);

    const reduxCompanySettings = useSelector(selectCompanySettings);

    const [companySettings, setCompanySettings] = useState({
        distanceUnit: "km",
        carrierTerms: "",
        invoiceTerms: "",
        netTerms: "",
        gstNo: "",
        targetProfitMargin: 0,
        minimumRatePerUnit: 0,
        fuelSurchargePercentage: 0,
        baseRateAdjustment: 0,
        peakSeasonAdjustment: 0,
        specialRateMultipliers: {
            hazmat: 1.2,
            undesirableDock: 1.1
        },
        equipmentPremiums: {
            reefer: 1.15,
            flatbed: 1.1
        },
        capacityRateMultipliers: {
            ltl: 0.80,
            ftl: 1.20
        },
    });

    useEffect(() => {
        if (selectedCompany && selectedCompany._id) {
            dispatch(fetchCompanySettings(selectedCompany._id));
        }
    }, [selectedCompany, dispatch]);

    useEffect(() => {
        if (reduxCompanySettings) {
            setCompanySettings(reduxCompanySettings);
        }
    }, [reduxCompanySettings]);

    useEffect(() => {
        if (selectedCompany && selectedCompany._id) {
            dispatch(fetchAndSetUsersByCompany(selectedCompany._id));
        }
    }, [selectedCompany, dispatch]);

    const openAddCompany = useCallback(() => {
        dispatch(setOpenAddCompany(true));
    }, [dispatch]);

    useEffect(() => {
        if (companies.length > 0 && (!selectedCompany || selectedCompany._id !== companies[0]._id)) {
            dispatch(setCompanySelector(companies[0]._id));
        }
    }, [companies, selectedCompany, dispatch]);

    useEffect(() => {
        if (selectedCompany && selectedCompany._id) {
            dispatch(fetchAndSetUsersByCompany(selectedCompany._id));
            dispatch(fetchCompanySettings(selectedCompany._id));
        }
    }, [selectedCompany, dispatch]);

    useEffect(() => {
        if (selectedCompany && selectedCompany.companySettings) {
            setCompanySettings(selectedCompany.companySettings);
        }
    }, [selectedCompany]);

    const handleSettingChange = (setting, value) => {
        setCompanySettings(prev => ({
            ...prev,
            [setting]: value
        }));
    };

    const handleSpecialRateChange = (type, value) => {
        setCompanySettings(prev => ({
            ...prev,
            specialRateMultipliers: {
                ...prev.specialRateMultipliers,
                [type]: parseFloat(value)
            }
        }));
    };

    const handleEquipmentPremiumChange = (type, value) => {
        setCompanySettings(prev => ({
            ...prev,
            equipmentPremiums: {
                ...prev.equipmentPremiums,
                [type]: parseFloat(value)
            }
        }));
    };

    const handleCapacityRateChange = (type, value) => {
        setCompanySettings(prev => ({
            ...prev,
            capacityRateMultipliers: {
                ...prev.capacityRateMultipliers,
                [type]: parseFloat(value)
            }
        }));
    };

    const saveCompanySettings = () => {
        if (selectedCompany && selectedCompany._id) {
            dispatch(updateCompanySettings(selectedCompany._id, companySettings))
                .then(() => {
                    toast.success("Company settings updated successfully!", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                })
                .catch((error) => {
                    toast.error("Failed to update company settings.", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                });
        }
    };

    return (<div className="bg-white w-full gap-5 px-3 md:px-16 lg:px-28 text-[#161931]">
        <SettingsNavbar/>
        <div className="mt-4">
            <h1 className="font-title text-lg mr-4">Company Settings</h1>
            <div className="mt-4 text-sm text-gray-400">Manage your company's settings for carrier confirmations,
                invoices, financial goals & more
            </div>
        </div>
        <div className="bg-white rounded-xl p-4 mt-4 flex flex-col md:flex-row w-full">

            <div className="flex-1 md:mr-4">
                <Button onClick={openAddCompany} outlined={1} size="sm" icon={"plus"}>
                    <span className="ml-2"> Add Company</span>
                </Button>
                <CompanyTable/>
            </div>
            <div className="flex-1 md:ml-4">
                <Button onClick={openAddUser} outlined={1} size={"sm"} icon={"plus"}>
                    <span className="inline-block ml-2"> Add Employee</span>
                </Button>
                <Button onClick={openAddDriver} outlined={1} size={"sm"} icon={"plus"}>
                    <span className="inline-block ml-2"> Create Driver Account </span>
                </Button>
                <EmployeeTable/>
            </div>
        </div>
        {/* Company Settings */}
        <div className="w-full h-1 bg-gray-200 mt-5 mb-5"></div>
        <div className="mt-4 flex flex-row sm:flex-col md:flex-row">
            <div className="flex flex-col mt-10 w-[50%]">
                <div className="font-title text-lg font-bold">Terms & conditions</div>
                <div className="text-gray-400 text-sm">
                    Customize your company's terms & conditions here for accurate invoicing
                </div>
            </div>
            <div className="flex-1 flex-col ml-2 w-[25%]">
                <div className="flex flex-row w-[50%] items-center">
                    <div className="items-center ">
                        <div className="text-sm font-bold">Carrier Terms</div>
                        <Button size={"sm"} onClick={() => setIsTermsModalOpen(true)} outlined={1}>
                            <span>View/Edit</span>
                        </Button>
                    </div>
                    <div className="items-center ml-4">
                        <div className="text-sm font-bold">Invoice Terms</div>
                        <Button size={"sm"} onClick={() => setIsInvoiceTermsModalOpen(true)} outlined={1}>
                            <span>View/Edit</span>
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex-col">
                    <h3 className="font-title text-lg">NET Terms</h3>
                    <input
                        type="text"
                        className="p-2 border rounded"
                        value={companySettings.netTerms}
                        onChange={(e) => handleSettingChange('netTerms', e.target.value)}
                    />
                </div>
                <div className="flex-1 flex-col">
                    <h3 className="font-title text-lg">HST / GST Number</h3>
                    <input
                        type="text"
                        className="p-2 border rounded"
                        value={companySettings.gstNo}
                        onChange={(e) => handleSettingChange('gstNo', e.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className="w-full h-1 bg-gray-200 mt-5"></div>
        <div className="mt-4 flex flex-row">
            <div className="flex flex-col mt-10 w-[50%]">
                <div className="font-title text-lg font-bold">Financial Settings</div>
                <div className="text-gray-400 text-sm">
                    Customize your company's financial settings and goals here
                </div>

            </div>
            <div className="flex flex-col w-[50%]">
                <div className="mt-4 flex-col">
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="capacityType">
                        unit (kilometers/miles)
                    </label>
                    <select
                        id="capacityType"
                        value={companySettings.distanceUnit}
                        onChange={(e) => handleSettingChange('distanceUnit', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="km">KILOMETERS (kms)</option>
                        <option value="m">MILES (m)</option>
                    </select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block mb-1">Target Profit Margin (%)</label>
                        <input
                            type="number"
                            className="w-full p-2 border rounded"
                            value={companySettings.targetProfitMargin}
                            onChange={(e) => handleSettingChange('targetProfitMargin', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Minimum Rate Per {companySettings.distanceUnit} ($)</label>
                        <input
                            type="number"
                            className="w-full p-2 border rounded"
                            value={companySettings.minimumRatePerUnit}
                            onChange={(e) => handleSettingChange('minimumRatePerUnit', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Fuel Surcharge (%)</label>
                        <input
                            type="number"
                            className="w-full p-2 border rounded"
                            value={companySettings.fuelSurchargePercentage}
                            onChange={(e) => handleSettingChange('fuelSurchargePercentage', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Base Rate Adjustment (%)</label>
                        <input
                            type="number"
                            className="w-full p-2 border rounded"
                            value={companySettings.baseRateAdjustment}
                            onChange={(e) => handleSettingChange('baseRateAdjustment', e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Peak Season Adjustment (%)</label>
                        <input
                            type="number"
                            className="w-full p-2 border rounded"
                            value={companySettings.peakSeasonAdjustment}
                            onChange={(e) => handleSettingChange('peakSeasonAdjustment', e.target.value)}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <h3 className="font-title text-lg mb-2">Special Rate Multipliers</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">Hazmat</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.specialRateMultipliers.hazmat}
                                onChange={(e) => handleSpecialRateChange('hazmat', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Undesirable Dock</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.specialRateMultipliers.undesirableDock}
                                onChange={(e) => handleSpecialRateChange('undesirableDock', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <h3 className="font-title text-lg mb-2">Equipment Premiums</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">Reefer</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.equipmentPremiums.reefer}
                                onChange={(e) => handleEquipmentPremiumChange('reefer', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block mb-1">Flatbed</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.equipmentPremiums.flatbed}
                                onChange={(e) => handleEquipmentPremiumChange('flatbed', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <h3 className="font-title text-lg mb-2">Capacity Multipliers</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1">LTL(less than truckload)</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.capacityRateMultipliers.ltl}
                                onChange={(e) => handleCapacityRateChange('ltl', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block mb-1">FTL(full truck load)</label>
                            <input
                                type="number"
                                className="w-full p-2 border rounded"
                                value={companySettings.capacityRateMultipliers.ftl}
                                onChange={(e) => handleCapacityRateChange('ftl', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-4">
            <Button size={"sm"} success={1} onClick={saveCompanySettings} outlined={1}>
                <span className="text-green-500">Save Company Settings</span>
            </Button>
        </div>
        <Modal
            open={isInvoiceTermsModalOpen}
            onClose={() => setIsInvoiceTermsModalOpen(false)}
            aria-labelledby="edit-terms-modal-title"
            aria-describedby="edit-terms-modal-description"
        >
            <Box sx={style}>
                <InfoIcon message={"Terms & Conditions for invoices NOTE: you still have to save settings"}/>
                <h2 id="edit-terms-modal-title" className="font-title text-lg mb-4">Invoice Terms & Conditions</h2>
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={companySettings.invoiceTerms}
                    onChange={(e) => handleSettingChange('invoiceTerms', e.target.value)}
                    className="mb-4"
                    id="company-invoice-terms"
                    name="CompanyInvoiceTerms"
                />
                <Button size={"sm"} danger={1} onClick={() => setIsInvoiceTermsModalOpen(false)} outlined={1}>
                    <span className="text-red-500">Done</span>
                </Button>
            </Box>
        </Modal>

        <Modal
            open={isTermsModalOpen}
            onClose={() => setIsTermsModalOpen(false)}
            aria-labelledby="edit-terms-modal-title"
            aria-describedby="edit-terms-modal-description"
        >
            <Box sx={style}>
                <InfoIcon
                    message={"Terms & Conditions for carrier confirmations NOTE: you still have to save settings"}/>
                <h2 id="edit-terms-modal-title" className="font-title text-lg mb-4">Carrier Confirmation Terms &
                    Conditions</h2>
                <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={companySettings.carrierTerms}
                    onChange={(e) => handleSettingChange('carrierTerms', e.target.value)}
                    className="mb-4"
                    id="company-carrier"
                    name="CompanyCarrier"
                />
                <Button size={"sm"} danger={1} onClick={() => setIsTermsModalOpen(false)} outlined={1}>
                    <span className="text-red-500">Done</span>
                </Button>
            </Box>
        </Modal>
    </div>);
}

export default CompanySettings;