import React from 'react';
import {CheckCircle} from '@mui/icons-material';

const SubmitButton = ({onClick}) => {
    return (<div className="mt-3">
        <div className="mt-10 flex justify-center items-center">
            <button
                className="bg-blue-400 hover:bg-blue-700 text-white pl-6 rounded-full flex items-center h-10 transition-colors duration-300"
                onClick={onClick}
            >
                <span className="mr-6">Submit</span>
                <span
                    className="bg-blue-500 hover:bg-blue-700 text-gray-700 hover:text-gray-100 w-14 h-10 inline-block flex items-center rounded-full">
                    <div className="h-full flex items-center justify-center">
                      <CheckCircle className="text-white"/>
                    </div>
                </span>
            </button>
        </div>
    </div>);
};

export default SubmitButton;
