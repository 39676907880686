import React, {useState, useCallback, useEffect} from "react";
import DatePicker from "react-datepicker";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import {
    getIsOpenLoadAdder,
    setOpenLoadAdder,
} from "../../store/loadSlice";
import {getAllTractorsSelector} from "../../store/tractorSlice";
import {getAllPartiesSelector} from "../../store/partySlice";
import {getAllDriversSelector} from "../../store/driverSlice";
import {fetchLoads, createLoad} from "../../actions/loadActions";
import {updateTractor} from "../../actions/tractorActions";
import {Autocomplete, TextField} from "@mui/material";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import {InboxOutlined, OutboxOutlined} from "@mui/icons-material";

const emptyForm = {
    id: "",
    user: "",
    tractor: "",
    driver: "",
    shipper: "",
    consignee: "",
    startDate: "",
    endDate: "",
    fuel: 0,
    unit: "",
    price: 0,
    tax: 0,
    startOdometer: 0,
    endOdometer: 0,
    mileage: 0,
};

function LoadAddDialog() {
    const dispatch = useDispatch();
    const [animate, setAnimate] = useState(true);
    const openModal = useSelector(getIsOpenLoadAdder);
    const [currentTractorId, setCurrentTractorId] = useState(0);

    const allTractors = useSelector(getAllTractorsSelector || []);
    const allParties = useSelector(getAllPartiesSelector) || [];
    const allDrivers = useSelector(getAllDriversSelector) || [];

    const allShippers = Array.isArray(allParties)
        ? allParties.filter((cus) => cus.type === "SHIPPER")
        : [];

    const allConsignees = Array.isArray(allParties)
        ? allParties.filter((cus) => cus.type === "CONSIGNEE")
        : [];

    const [isTouched, setIsTouched] = useState(false);
    const [loadForm, setLoadForm] = useState(emptyForm);
    const [validForm, setValidForm] = useState(
        Object.keys(emptyForm).reduce((a, b) => {
            return {...a, [b]: false};
        }, {})
    );

    const handlerLoadValue = (event, keyName) => {
        var value = "";
        if (keyName === "startDate" || keyName === "endDate") {
            value = event.toISOString();
        } else {
            value = event.target.value;
        }
        setLoadForm((prev) => ({
            ...prev,
            [keyName]: value,
            validForm: {...prev.validForm, [keyName]: !!value},
        }));
    };

    const submitHandler = async () => {
        setIsTouched(true);

        const isValid = Object.values(validForm).every(Boolean);

        if (!isValid) {
            toast.error("Invalid Load Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        try {
            if (loadForm.endOdometer > loadForm.startOdometer) {
                dispatch(
                    updateTractor(currentTractorId, {
                        odometerReading: loadForm.endOdometer,
                    })
                );
            }
            dispatch(createLoad(loadForm));
            dispatch(fetchLoads());

            setLoadForm(emptyForm);
            setIsTouched(false);
        } catch (error) {
            console.error("Error saving load:", error);
            toast.error("An error occurred. Please try again.", {
                position: "bottom-center",
                autoClose: 2000,
            });
        }
    };

    const onCancelHandler = useCallback(() => {
        dispatch(setOpenLoadAdder(false));
    }, [dispatch]);

    useEffect(() => {
        if (loadForm.tractor) {
            const trac = allTractors.find((tractor) => {
                return tractor._id === loadForm.tractor;
            });

            setCurrentTractorId(trac._id);
            loadForm.startOdometer = trac.odometerReading;
            loadForm.driver = trac.assignedDriver;
        }

        if (loadForm.endOdometer && loadForm.startOdometer) {
            loadForm.mileage = loadForm.endOdometer - loadForm.startOdometer;
        }
    }, [loadForm, allTractors]);

    useEffect(() => {
        setValidForm({
            id: true,
            tractor: !!loadForm.tractor,
            driver: !!loadForm.driver,
            shipper: !!loadForm.shipper,
            consignee: !!loadForm.consignee,
            startDate: !!loadForm.startDate,
        });
    }, [loadForm]);

    useEffect(() => {
        if (openModal) {
            setAnimate(true);
        } else {
            setAnimate(false);
        }
    }, [openModal]);

    return openModal ? (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: animate ? 1 : 0,
            }}
            transition={{
                type: "spring",
                damping: 18,
            }}
        >
            <div className="relative">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div
                            className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full">
                                        <h3
                                            className="text-lg leading-6 font-medium text-gray-900"
                                            id="modal-title"
                                        >
                                            Add a Load
                                        </h3>
                                        <div className="grid grid-cols-2 space-x-3">
                                            <div className="relative mt-2">
                                                <div className="bg-white rounded-xl mt-4">
                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            <LocalShippingOutlinedIcon/>
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                value={
                                                                    allTractors.find(
                                                                        (tractor) =>
                                                                            tractor._id === loadForm.tractor
                                                                    ) || null
                                                                }
                                                                onChange={(event, newValue) => {
                                                                    handlerLoadValue(
                                                                        {
                                                                            target: {
                                                                                value: newValue ? newValue._id : "",
                                                                            },
                                                                        },
                                                                        "tractor"
                                                                    );
                                                                }}
                                                                options={allTractors}
                                                                getOptionLabel={(option) => option ? String(option.tractorID) : ""}
                                                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Search for a tractor"
                                                                        label="Tractor"
                                                                        className={
                                                                            !validForm.tractor && isTouched
                                                                                ? defaultInputInvalidStyle
                                                                                : defaultInputStyle
                                                                        }
                                                                        id="load-tractor-id"
                                                                        name="LoadTractorId"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            <BadgeOutlinedIcon/>
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                value={
                                                                    allDrivers.find(
                                                                        (driver) =>
                                                                            driver._id === loadForm.driver
                                                                    ) || null
                                                                }
                                                                onChange={(event, newValue) => {
                                                                    handlerLoadValue(
                                                                        {
                                                                            target: {
                                                                                value: newValue ? newValue._id : "",
                                                                            },
                                                                        },
                                                                        "driver"
                                                                    );
                                                                }}
                                                                options={allDrivers}
                                                                getOptionLabel={(option) =>
                                                                    option
                                                                        ? `${option.firstName} ${option.lastName}`
                                                                        : ""
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Search for a driver"
                                                                        label="Driver"
                                                                        className={
                                                                            !validForm.driver && isTouched
                                                                                ? defaultInputInvalidStyle
                                                                                : defaultInputStyle
                                                                        }
                                                                        id="load-driver-id"
                                                                        name="LoadDriverId"
                                                                    />
                                                                )}
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option && value && option._id === value._id
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            <OutboxOutlined/>
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                value={
                                                                    allShippers.find(
                                                                        (shipper) =>
                                                                            shipper._id === loadForm.shipper
                                                                    ) || null
                                                                }
                                                                onChange={(event, newValue) => {
                                                                    handlerLoadValue(
                                                                        {
                                                                            target: {
                                                                                value: newValue ? newValue._id : "",
                                                                            },
                                                                        },
                                                                        "shipper"
                                                                    );
                                                                }}
                                                                options={allShippers}
                                                                getOptionLabel={(option) =>
                                                                    option ? `${option.name}` : ""
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Search for a shipper"
                                                                        label="Shipper"
                                                                        className={
                                                                            !validForm.shipper && isTouched
                                                                                ? defaultInputInvalidStyle
                                                                                : defaultInputStyle
                                                                        }
                                                                        id="load-shipper-id"
                                                                        name="LoadShipperId"
                                                                    />
                                                                )}
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option && value && option._id === value._id
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            <InboxOutlined/>
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                value={
                                                                    allConsignees.find(
                                                                        (consignee) =>
                                                                            consignee._id === loadForm.consignee
                                                                    ) || null
                                                                }
                                                                onChange={(event, newValue) => {
                                                                    handlerLoadValue(
                                                                        {
                                                                            target: {
                                                                                value: newValue ? newValue._id : "",
                                                                            },
                                                                        },
                                                                        "consignee"
                                                                    );
                                                                }}
                                                                options={allConsignees}
                                                                getOptionLabel={(option) =>
                                                                    option ? `${option.name}` : ""
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Search for a consignee"
                                                                        label="Consignee"
                                                                        className={
                                                                            !validForm.consignee && isTouched
                                                                                ? defaultInputInvalidStyle
                                                                                : defaultInputStyle
                                                                        }
                                                                        id="load-consignee-id"
                                                                        name="LoadConsgineeId"
                                                                    />
                                                                )}
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option && value && option._id === value._id
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Load Start Date
                                                        </div>
                                                        <div className="flex-1">
                                                            <DatePicker
                                                                dateFormat="MM-dd-yyyy"
                                                                selected={
                                                                    loadForm.startDate
                                                                        ? new Date(loadForm.startDate)
                                                                        : null
                                                                }
                                                                onChange={(date) =>
                                                                    handlerLoadValue(date, "startDate")
                                                                }
                                                                className={
                                                                    !validForm.startDate && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Load End Date:
                                                        </div>
                                                        <div className="flex-1">
                                                            <DatePicker
                                                                dateFormat="MM-dd-yyyy"
                                                                selected={
                                                                    loadForm.endDate
                                                                        ? new Date(loadForm.endDate)
                                                                        : null
                                                                }
                                                                onChange={(date) =>
                                                                    handlerLoadValue(date, "endDate")
                                                                }
                                                                className={defaultInputStyle}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="relative mt-2">
                                                <div className="bg-white rounded-xl mt-4">
                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Amount of fuel used
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Fuel QTY"
                                                                className={defaultInputStyle}
                                                                value={loadForm.fuel || 0}
                                                                onChange={(e) =>
                                                                    handlerLoadValue(e, "fuel")
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Fuel Units
                                                        </div>
                                                        <div className="flex-1">
                                                            <select
                                                                value={loadForm.unit}
                                                                onChange={(e) => handlerLoadValue(e, "unit")}
                                                                className="border rounded p-2 w-full"
                                                            >
                                                                <option value="Litres">Litres</option>
                                                                <option value="Gallons">Gallons</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Price
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Price"
                                                                className={defaultInputStyle}
                                                                value={loadForm.price || 0}
                                                                onChange={(e) => handlerLoadValue(e, "price")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Tax
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Tax"
                                                                className={defaultInputStyle}
                                                                value={loadForm.tax || 0}
                                                                onChange={(e) => handlerLoadValue(e, "tax")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Starting Odometer
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Starting Odometer"
                                                                className={defaultInputStyle}
                                                                value={loadForm.startOdometer || 0}
                                                                readOnly={true}
                                                                onChange={(e) =>
                                                                    handlerLoadValue(e, "startOdometer")
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Ending Odometer
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="End Odometer"
                                                                className={defaultInputStyle}
                                                                value={loadForm.endOdometer || 0}
                                                                onChange={(e) =>
                                                                    handlerLoadValue(e, "endOdometer")
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={submitHandler}
                                >
                                    Add
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onCancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    ) : null;
}

export default LoadAddDialog;