export const setCurrentCompanyId = (companyId) => {
  localStorage.setItem('currentCompanyId', companyId);
};

export const getCurrentCompanyId = () => {
  return localStorage.getItem('currentCompanyId');
};

export const clearCurrentCompanyId = () => {
  localStorage.removeItem('currentCompanyId');
};