import {createSlice, nanoid} from "@reduxjs/toolkit";
import imageData from "../shared/imageData.json";
import colorData from "../shared/colorData.json";

const initialState = {
    isConfirmModal: false,
    isConfirm: false,
    settingOpen: false,
    defaultColor: colorData[0],
    defaultBgImage: imageData[0],
    colors: colorData,
    images: imageData,
    data: [],
    detailList: [],
    deletedID: null,
    currentEditedID: null,
    selectedInvoice: null,
    newForm: {
        id: nanoid(),
        invoiceNo: "",
        statusIndex: "1",
        statusName: "Draft",
        totalAmount: 1200,
        color: colorData[0],
        backgroundImage: imageData[0],
        dueDate: new Date(),
        createdDate: new Date(),
        currencyUnit: "$",
        carrierCost: 0,
        driver: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
        },
        carrier: {
            id: "",
            carrierName: "",
            company: "",
        },
        client: {
            id: "",
            name: "",
            mobileNo: "",
            email: "",
            image: "",
            billingAddress: "",
        },
        products: [
            {
                amount: 0,
                id: "",
                name: "",
                productID: "",
                quantity: 1,
            },
        ],
        taxes: [],
        showParty: false,
    },
};

export const invoiceSlice = createSlice({
    name: "invoices",
    initialState,
    reducers: {
        addNewInvoice: (state, action) => {
            const newDatas = [...state.data, action.payload];
            state.data = newDatas;

            const reNewForm = {
                newForm: {
                    id: nanoid(),
                    invoiceNo: "",
                    statusIndex: "",
                    statusName: "Draft",
                    totalAmount: 0,
                    color: colorData[0],
                    backgroundImage: imageData[0],
                    dueDate: new Date(),
                    createdDate: new Date(),
                    currencyUnit: "$",
                    clientName: "",
                    carrierCost: 0,
                    driver: {
                        id: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                    },
                    carrier: {
                        id: "",
                        carrierName: "",
                        company: "",
                    },
                    client: {
                        id: "",
                        name: "",
                        mobileNo: "",
                        email: "",
                        image: "",
                        billingAddress: "",
                    },
                    products: [
                        {
                            amount: 0,
                            id: "",
                            name: "",
                            productID: "",
                            quantity: 1,
                        },
                    ],
                    taxes: [],
                    showParty: false,
                },
            };

            state.newForm = {...reNewForm};
        },
        setAllInvoice: (state, action) => {
            state.data = [...action.payload];
        },

        setAllInvoiceDetailList: (state, action) => {
            state.detailList = [...action.payload];
        },

        setDefaultColor: (state, action) => {
            const newColor = action.payload;
            state.defaultColor = newColor;
        },

        setDefaultBackground: (state, action) => {
            const newBackground = action.payload;
            state.defaultBgImage = newBackground;
        },

        setDeleteId: (state, action) => {
            state.deletedID = action.payload;
        },

        setEditedId: (state, action) => {
            state.currentEditedID = action.payload;
        },

        onConfirmDeletedInvoice: (state, action) => {
            const newDatas = state.data.filter(
                (invoice) => invoice._id !== state.deletedID,
            );
            state.data = newDatas;
            state.deletedID = null;
        },

        onConfirmEditInvoice: (state, action) => {
            const isFindIndex = state.data.findIndex(
                (product) => product.id === state.currentEditedID,
            );
            if (isFindIndex !== -1) {
                state.data[isFindIndex] = {...action.payload};
            }
            state.currentEditedID = null;
        },

        updateNewInvoiceFormField: (state, action) => {
            state.newForm[action.payload.key] = action.payload.value;
        },

        updateNewInvoiceForm: (state, action) => {
            state.newForm = {...action.payload};
        },

        updateExisitingInvoiceForm: (state, action) => {
            const {
                id,
                invoiceNo,
                statusIndex,
                statusName,
                totalAmount,
                dueDate,
                createdDate,
                client,
            } = action.payload;

            const findIndexOfList = state.data.findIndex(
                (product) => product.id === id,
            );

            const newInvoice = {
                id,
                invoiceNo,
                statusIndex,
                statusName,
                totalAmount,
                dueDate,
                createdDate,
                clientName: client?.name,
            };

            if (findIndexOfList !== -1) {
                state.data[findIndexOfList] = {...newInvoice};
            }
            const findIndexOfDetail = state.detailList.findIndex(
                (product) => product.id === id,
            );

            if (findIndexOfDetail !== -1) {
                state.detailList[findIndexOfDetail] = {...action.payload};
            }
        },

        setSettingModalOpen: (state, action) => {
            state.settingOpen = action.payload;
        },

        setConfirmModalOpen: (state, action) => {
            state.isConfirmModal = action.payload;
        },

        setIsConfirm: (state, action) => {
            state.isConfirm = action.payload;
        },
        toggleShowParty: (state) => {
            state.showParty = !state.showParty;
        },
        setShowParty: (state, action) => {
            state.showParty = action.payload;
        },
        setInvoiceSelector: (state, action) => {
            console.log(action.payload)
            const isFindIndex = state.data.findIndex(
                (invoice) => invoice._id === action.payload
            );
            if (isFindIndex !== -1) {
                state.selectedInvoice = state.data[isFindIndex];
            }
        },
        clearInvoiceSelector: (state) => {
            state.selectedInvoice = null;
        },
    },
});

export const {
    setAllInvoice,
    addNewInvoice,
    setAllInvoiceDetailList,
    setDefaultColor,
    setDefaultBackground,
    setDeleteId,
    setEditedId,
    setSettingModalOpen,
    setConfirmModalOpen,
    setIsConfirm,
    setShowParty,
    toggleShowParty,
    onConfirmDeletedInvoice,
    onConfirmEditInvoice,
    updateNewInvoiceForm,
    updateNewInvoiceFormField,
    updateExisitingInvoiceForm,
    setInvoiceSelector,
    clearInvoiceSelector,
    setStatusDataSelector
} = invoiceSlice.actions;

export const getAllInvoiceSelector = (state) => state.invoices.data;

export const getAllColorSelector = (state) => state.invoices.colors;

export const getAllImageSelector = (state) => state.invoices.images;

export const getCurrentBGImage = (state) => state.invoices.defaultBgImage;

export const getCurrentColor = (state) => state.invoices.defaultColor;

export const getAllInvoiceDetailSelector = (state) => state.invoices.detailList;

export const getInvoiceDetailByID = (id) => (state) =>
    state.invoices.detailList.find((detail) => detail._id === id);

export const getDeletedInvoiceForm = (state) => state.invoices.deletedID;

export const getInvoiceNewForm = (state) => state.invoices.newForm;

export const getInvoiceSettingModal = (state) => state.invoices.settingOpen;

export const getIsInvoiceConfirmModal = (state) =>
    state.invoices.isConfirmModal;

export const getIsConfirm = (state) => state.invoices.isConfirm;

export const getTotalBalance = (state) =>
    state.invoices.data.reduce((prev, next) => {
        var total = parseFloat(prev) + parseFloat(next.totalAmount || 0);
        return total;
    }, 0);

export const getCurrentForm = (state) => state.invoices.newForm;

export const getSelectedInvoice = (state) => state.invoices.selectedInvoice;

export default invoiceSlice.reducer;
