import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openPartySelector: false,
  partySelectorType: null,
  selectedParty: null,
  selectedShipper: null,
  selectedConsignee: null,
  data: [],
  newForm: {
    id: nanoid(),
    type:"",
    name: "",
    billingAddress: "",
    city: "",
    province: "",
    country: "",
    postalCode: "",
    user: null,
  },
  editedID: null,
  deletedID: null,
};

export const partiesSlice = createSlice({
  name: "parties",
  initialState,
  reducers: {
    addNewParty: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        type: "",
        name: "",
        billingAddress: "",
        city: "",
        province: "",
        country: "",
        postalCode: "",
        user: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewPartyForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewPartyFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllParties: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedParty: (state) => {
      state.data = state.data.filter(
        (party) => party.id !== state.deletedID,
      );
      state.deletedID = null;
    },

    onConfirmEditParty: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (party) => party.id === state.editedID,
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenPartySelector: (state, action) => {
      state.openPartySelector= action.payload;
      if (!action.payload) {
        state.selectedParty= null;
      }
    },

    setPartySelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (party) => party._id === action.payload,
      );
      if (isFindIndex !== -1) {
        state.selectedParty = state.data[isFindIndex];
      }
    },

   onDeletePartySuccess: (state, action) => {
      const partyId = action.payload;
      state.data = state.data.filter((party) => party.id !== partyId);
    },

    setSelectedShipper: (state, action) => {
      state.selectedShipper = action.payload;
    },
    setSelectedConsignee: (state, action) => {
      state.selectedConsignee = action.payload;
    },
  },
});

export const {
  addNewParty,
  updateNewPartyForm,
  updateNewPartyFormField,
  setAllParties,
  setDeleteId,
  setEditedId,
  onConfirmDeletedParty,
  onConfirmEditParty,
  setOpenPartySelector,
  setPartySelector,
  setSelectedConsignee,
  setSelectedShipper,
  onDeletePartySuccess,
  onDeletePartyFailure,
} = partiesSlice.actions;

export const getAllPartiesSelector = (state) => state.parties.data;

export const getPartyNewForm = (state) => state.parties.newForm;

export const getDeletedPartyForm = (state) => state.parties.deletedID;

export const getEditedIdForm = (state) => state.parties.editedID;

export const getIsOpenPartySelector = (state) =>
  state.parties.openPartySelector;

export const getSelectedParty = (state) => state.parties.selectedParty;

export const getSelectedShipper = (state) => state.parties.selectedShipper;

export const getSelectedConsignee = (state) => state.parties.selectedConsignee;

export default partiesSlice.reducer;
