import axios from "axios";
import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { toast } from "react-toastify";
import base_url from "../../utils/config";

const Signup = () => {
  const controls = useAnimation(); // Initialize Framer Motion controls
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "user",
  });
  const [error, setError] = useState("");
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${base_url}/api/auth/register`;
      const { data: res } = await axios.post(url, data);
      if (res) {
        toast.success("Account created successfully!");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    const bounceAnimation = async () => {
      await new Promise((resolve) => setTimeout(resolve, 200)); // Delay for 200 milliseconds

      // Apply bounce animation
      const distance = 20; // Adjust the bounce distance
      const duration = 0.3; // Adjust the bounce duration

      // Bounce up
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
          animateElement(-distance);
        }, duration * 500),
      );

      // Bounce down
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
          animateElement(distance);
        }, duration * 500),
      );

      // Final bounce reset
      animateElement(0);
    };

    const animateElement = (distance) => {
      // Animate the y property for bouncing effect
      controls.start({ y: distance });
    };

    bounceAnimation();
  }, [controls]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md"
      >
        <div className="p-4 py-6 text-white bg-blue-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
          <div className="my-3 text-4xl font-bold tracking-wider text-center">
            <a href="/login">FLEETMS</a>
          </div>
          <p className="mt-6 font-normal text-center text-gray-300 md:mt-0">
            Meet our Logistics Invoicing App – the streamlined solution for
            efficient billing in logistics. Automate, track in real-time, and
            elevate your management effortlessly.
          </p>
          <p className="flex flex-col items-center justify-center mt-10 text-center">
            <span>Want to Learn More?</span>
            <a href="/signup" className="underline">
              Back to our website!
            </a>
          </p>
          <p className="mt-6 text-sm text-center text-gray-300">
            Read our{" "}
            <a href="/login" className="underline">
              terms
            </a>{" "}
            and{" "}
            <a href="/login" className="underline">
              conditions
            </a>
          </p>
        </div>
        <div className="p-5 bg-white md:flex-1">
          <h3 className="my-4 text-2xl font-semibold text-gray-700">
            Create an Account
          </h3>
          <form action="/login" className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-1">
              <label
                htmlFor="firstName"
                className="text-sm font-semibold text-gray-500"
              >
                First Name
              </label>
              <input
                type="firstName"
                placeholder="firstName"
                name="firstName"
                onChange={handleChange}
                value={data.firstName}
                required
                className="outline-none border-none w-96 px-4 py-3 rounded-md bg-green-100 my-2 text-sm"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="lastName"
                  className="text-sm font-semibold text-gray-500"
                >
                  Last Name
                </label>
              </div>
              <input
                type="lastName"
                placeholder="lastName"
                name="lastName"
                onChange={handleChange}
                value={data.lastName}
                required
                className="outline-none border-none w-96 px-4 py-3 rounded-md bg-green-100 my-2 text-sm"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="email"
                  className="text-sm font-semibold text-gray-500"
                >
                  Email
                </label>
              </div>
              <input
                type="email"
                placeholder="email"
                name="email"
                onChange={handleChange}
                value={data.email}
                required
                className="outline-none border-none w-96 px-4 py-3 rounded-md bg-green-100 my-2 text-sm"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="text-sm font-semibold text-gray-500"
                >
                  Password
                </label>
              </div>
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
                value={data.password}
                required
                className="outline-none border-none w-96 px-4 py-3 rounded-md bg-green-100 my-2 text-sm"
              />
            </div>
            {error && (
              <div className="error_msg px-4 py-3 my-2 text-sm bg-red-500 text-white rounded-md text-center">
                {error}
              </div>
            )}
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
                onClick={handleSubmit}
              >
                Sign Up
              </button>
            </div>
            <div className="flex flex-col space-y-5">
              <span className="flex items-center justify-center space-x-2">
                <span className="h-px bg-gray-400 w-14"></span>
                <span className="font-normal text-gray-500">
                  Already have an account?
                </span>
                <span className="h-px bg-gray-400 w-14"></span>
              </span>
              <div className="flex flex-col space-y-4">
                <a
                  href="/login"
                  className="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
                >
                  <span>
                    <svg
                      className="w-5 h-5 text-gray-800 fill-current group-hover:text-white"
                      viewBox="0 0 24 24"
                      version="1.1"
                      aria-hidden="true"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12c0 2.69 1.03 5.14 2.71 7.01-.03-.26-.01-.52-.01-.79 0-4.41 3.59-8 8-8s8 3.59 8 8c0 .27.02.53-.01.79C20.97 17.14 22 14.69 22 12c0-5.52-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8 0-1.86.65-3.56 1.73-4.9l10.17 10.17C15.56 17.35 13.86 18 12 18zm6.32-1.68c-.39.39-1.02.39-1.41 0l-2.12-2.12c-.39-.39-.39-1.02 0-1.41s1.02-.39 1.41 0l.71.71 1.71-1.71c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-2.12 2.12z"></path>
                    </svg>
                  </span>
                  <span className="text-sm font-medium text-gray-800 group-hover:text-white">
                    Login
                  </span>
                </a>
              </div>
            </div>
          </form>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Signup;

