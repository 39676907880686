import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
    ReferenceLine,
} from 'recharts';
import { Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LocalGasStation, Assessment, TrendingUp, DirectionsCar } from '@mui/icons-material';
import InfoIcon from "../Icons/InfoIcon";
import { fetchFuelInsights } from '../../actions/eventActions';

const FuelTracker = () => {
    const dispatch = useDispatch();
    const [timeRange, setTimeRange] = useState('month');
    const [fuelInsights, setFuelInsights] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await dispatch(fetchFuelInsights(timeRange));
                setFuelInsights(data);
            } catch (err) {
                setError('Failed to fetch fuel insights. Please try again later.');
                console.error('Error fetching fuel insights:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch, timeRange]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!fuelInsights) {
        return <div>No fuel data available.</div>;
    }

    const {
        averageCost,
        percentageChange,
        averageCostPerMile,
        percentageChangePerMile,
        synchronizedData,
        fuelEfficiency
    } = fuelInsights;

    return (
        <div>
            <Typography variant="h5" gutterBottom className="text-blue-500">
                <Assessment fontSize="large" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                Fuel Tracker
            </Typography>
            <Box mb={2} display="flex" alignItems="center">
                <FormControl variant="outlined" minwidth={3}>
                    <InputLabel>Time Range</InputLabel>
                    <Select
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                        label="Time Range"
                    >
                        <MenuItem value="week">Last Week</MenuItem>
                        <MenuItem value="month">Last Month</MenuItem>
                    </Select>
                </FormControl>
                <InfoIcon message={"Select the time range to view insights for 'Average Fuel Cost' & 'Cost per Mile'"} />
            </Box>
            <div className="flex flex-row mb-4 justify-center">
                <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20, width: '40%' }} className="m-2 w-full md:w-1/2">
                    <Typography variant="h6" gutterBottom>
                        <TrendingUp fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                        Average Fuel Cost
                    </Typography>
                    <Typography variant="h6" color="textPrimary" paragraph>
                        ${averageCost.toFixed(2)} per unit
                    </Typography>
                    <Typography variant="body2" color={percentageChange < 0 ? "red" : "green"} paragraph>
                        {isFinite(percentageChange) ? `${percentageChange.toFixed(2)}%` : "N/A"} compared to the previous {timeRange}
                    </Typography>
                </Box>
                <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20, width: '40%' }} className="m-2 w-full md:w-1/2">
                    <Typography variant="h6" gutterBottom>
                        <DirectionsCar fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                        Cost per Mile
                    </Typography>
                    <Typography variant="h6" color="textPrimary" paragraph>
                        {averageCostPerMile > 0 ? `$${averageCostPerMile.toFixed(2)} per mile` : 'Insufficient Data'}
                    </Typography>
                    <Typography variant="body2" color={percentageChangePerMile < 0 ? "red" : "green"} paragraph>
                        {isFinite(percentageChangePerMile) ? `${percentageChangePerMile.toFixed(2)}%` : "N/A"} compared to the previous {timeRange}
                    </Typography>
                </Box>
            </div>
            <Box mb={4} component={Paper} elevation={3} p={2} style={{ backgroundColor: '#f5f5f5' }}>
                <Typography variant="h6" gutterBottom>
                    <LocalGasStation fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                    Total Fuel Consumed and Cost
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    This chart shows the total fuel consumed and cost over time.
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={synchronizedData} syncId="fuelCostSync">
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="fuel" stroke="#8884d8" fill="#8884d8" />
                        <Area type="monotone" dataKey="cost" stroke="#82ca9d" fill="#82ca9d" />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    <LocalGasStation fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }}  className="text-blue-600"/>
                    Fuel Efficiency
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    This chart shows the fuel efficiency (mileage per unit of fuel) over time.
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={fuelEfficiency}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="efficiency" stroke="#82ca9d" />
                        <ReferenceLine y={0} stroke="#000" />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </div>
    );
};

export default FuelTracker;