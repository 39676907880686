import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import ImageUpload from "../Common/ImageUpload";
import SectionTitle from "../Common/SectionTitle";
import { getCompanyData, setOpenAddCompany, updateNewCompanyForm, getOpenAddCompany } from "../../store/companySlice";
import { useAppContext } from "../../context/AppContext";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultInputLargeStyle,
  defaultInputLargeInvalidStyle,
  defaultSkeletonLargeStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import {createCompany} from "../../actions/companyActions";

const emptyForm = {
  companyId: "",
  image: "",
  companyName: "",
  companyEmail: "",
  companyMobile: "",
  billingAddress: "",
  country: "",
  city: "",
  province: "",
  postalCode: "",
};

function CompanyAddDialog() {
  const dispatch = useDispatch();
  const company = useSelector(getCompanyData);
  const { initLoading: isInitLoading } = useAppContext();
  const openAddCompany = useSelector(getOpenAddCompany);
  const [isTouched, setIsTouched] = useState(false);
  const [companyForm, setCompanyForm] = useState(emptyForm);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );
  const onChangeImage = useCallback((str) => {
    setCompanyForm((prev) => ({ ...prev, image: str }));
  }, []);

  const handleCompanyValue = useCallback((event, keyName) => {
    const value = event.target.value;
    setCompanyForm((prev) => ({ ...prev, [keyName]: value }));
  }, []);

  const submitHandler = async () => {
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid company Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
        dispatch(createCompany(companyForm));
        dispatch(updateNewCompanyForm(companyForm));

        await setCompanyForm(emptyForm);
        setIsTouched(false);
        setOpenAddCompany(false);

    } catch (error) {
      console.error("Error saving company:", error);
      toast.error("An error occurred. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const closeModal = () => setOpenAddCompany(false);

  const imageUploadClasses = useMemo(() => {
    const defaultStyle = "rounded-xl ";

    if (isTouched && !companyForm.image) {
      return defaultStyle + " border-dashed border-2 border-red-400 ";
    }

    if (!companyForm.image) {
      return defaultStyle + " border-dashed border-2 border-indigo-400 ";
    }

    return defaultStyle;
  }, [companyForm, isTouched]);

  useEffect(() => {
    if (company) {
      setCompanyForm(company);
    }
  }, [company]);

  useEffect(() => {
    setValidForm({
      companyId: true,
      image: !!companyForm.image,
      companyName: !!companyForm.companyName,
      companyEmail: !!companyForm.companyEmail,
      companyMobile: !!companyForm.companyMobile,
      billingAddress: !!companyForm.billingAddress,
      country: !!companyForm.country,
      city: !!companyForm.city,
      province: !!companyForm.province,
      postalCode: !!companyForm.postalCode,
    });
  }, [companyForm]);

  return (
    <>
      {openAddCompany && (
        <motion.div
          className="modal-container fixed inset-0 z-50 flex items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
          <div className="bg-white rounded-xl p-4 mt-4 z-10 w-full max-w-2xl">
            <SectionTitle>Company Settings</SectionTitle>
            <div className="flex mt-2">
              {isInitLoading ? (
                <Skeleton className="skeleton-input-radius skeleton-image border-dashed border-2" />
              ) : (
                <ImageUpload
                  onChangeImage={onChangeImage}
                  keyName="QuickEditImageUpload"
                  className={imageUploadClasses}
                  url={companyForm.image}
                />
              )}

              <div className="flex-1 pl-3">
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonLargeStyle} />
                ) : (
                  <input
                    value={companyForm.companyName}
                    placeholder="Company Name"
                    className={
                      !validForm.companyName && isTouched
                        ? defaultInputLargeInvalidStyle
                        : defaultInputLargeStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "companyName")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>Address</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.billingAddress}
                    placeholder="Company Address"
                    className={
                      !validForm.billingAddress && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "billingAddress")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>Email</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.companyEmail}
                    placeholder="Company Email"
                    className={
                      !validForm.companyEmail && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "companyEmail")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>Phone</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.companyMobile}
                    placeholder="Company Phone"
                    className={
                      !validForm.companyMobile && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "companyMobile")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>Country</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.country}
                    placeholder="Country"
                    className={
                      !validForm.country && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "country")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>State / Province</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.province}
                    placeholder="State / Province"
                    className={
                      !validForm.province && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "province")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>City</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.city}
                    placeholder="City"
                    className={
                      !validForm.city && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "city")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="flex mt-2">
              <div className="flex-1">
                <SectionTitle>Postal / Zip Code</SectionTitle>
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    value={companyForm.postalCode}
                    placeholder="Postal / Zip Code"
                    className={
                      !validForm.postalCode && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    onChange={(e) => handleCompanyValue(e, "postalCode")}
                    disabled={isInitLoading}
                  />
                )}
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={submitHandler}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => dispatch(setOpenAddCompany(false))}
                >
                  Cancel
                </button>
              </div>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default CompanyAddDialog;
