import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAllLoadsSelector } from "../../store/loadSlice";
import { getAllTractorsSelector } from "../../store/tractorSlice";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function LoadsAnalysis() {
  const allLoads = useSelector(getAllLoadsSelector);
  const allTractors = useSelector(getAllTractorsSelector);

  const currDate = new Date();
  var years = [];
  var tractors = [];

  const [selectedYear, setSelectedYear] = useState(
    currDate.toISOString().substring(0, 4),
  );
  const [selectedTractor, setSelectedTractor] = useState(null);

  const [selectedFuelData, setSelectedFuelData] = useState([]);

  const [monthlyData, setMonthlyData] = useState();

  tractors = allTractors.map((t) => t.tractorID);
  for (
    let year = parseInt(currDate.toISOString().substring(0, 4));
    year >= 2022;
    year--
  ) {
    years.push(year);
  }

  useEffect(() => {
    setSelectedFuelData(
      allLoads
        .filter((item) => {
          var res = true;
          if (selectedTractor) {
            res = item.tractorId === selectedTractor;
          }
          return res;
        })
        .filter((item) => {
          return item.endDate.substring(0, 4).includes(selectedYear);
        })
        .map((item) => ({
          tractor: item.tractorId,
          fuelQty:
            item.unit === "liters" ? item.fuel : parseInt(item.fuel) * 3.78541,
          date: item.endDate,
          totalPrice: item.price + item.tax,
          mileage: item.mileage,
        })),
    );
  }, [allLoads, selectedYear, selectedTractor]);

  useEffect(() => {
    setMonthlyData([
      {
        month: "JAN",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "01";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "01";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "01";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "FEB",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "02";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "02";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "02";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "MAR",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "03";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "03";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "03";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "APR",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "04";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "04";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "04";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "MAY",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "05";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "05";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "05";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "JUN",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "06";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "06";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "06";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "JUL",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "07";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "07";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "07";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "AUG",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "08";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "08";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "08";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "SEP",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "09";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "09";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "09";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "OCT",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "10";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "10";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "10";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "NOV",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "11";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "11";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "11";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
      {
        month: "DEC",
        fuel: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "12";
          })
          .reduce((total, d) => total + d.fuelQty, 0),
        price: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "12";
          })
          .reduce((total, d) => total + d.totalPrice, 0),
        fuelAvg: selectedFuelData
          .filter((d) => {
            return d.date.substring(5, 7) === "12";
          })
          .reduce((total, d) => total + d.mileage / d.totalPrice, 0),
      },
    ]);
  }, [selectedFuelData]);

  return (
    <>
      <div className="bg-white rounded-xl px-3 py-3 mb-3">
        <div className="font-title mb-2">Choose Data parameters</div>
        <div className="flex w-full flex-col sm:flex-row">
          <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
            <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-black-400">
              Year
            </div>
            <div className="flex">
              <div className="flex-1">
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {years.map((year) => (
                    <MenuItem value={"" + year}>{year}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
            <div className="h-12 w-18 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-black-400">
              Tractor
            </div>
            <div className="flex">
              <div className="flex-1">
                <Select
                  value={selectedTractor ? selectedTractor : "All Tractors"}
                  onChange={(e) => setSelectedTractor(e.target.value)}
                >
                  <MenuItem value={null}>All Tractors</MenuItem>
                  {tractors.map((ID) => (
                    <MenuItem value={+ID}>{ID}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="modal-title"
        >
          Total fuel Usage in litres
        </h3>
        <div className="flex">
          <ResponsiveContainer width="90%" height={400}>
            <LineChart data={monthlyData} margin={{ top: 40 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="fuel" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="modal-title"
        >
          Total Price Paid
        </h3>
        <div className="flex">
          <ResponsiveContainer width="90%" height={400}>
            <BarChart
              width={600}
              height={300}
              data={monthlyData}
              margin={{
                top: 40,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="price" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="modal-title"
        >
          Average fuel used per mile
        </h3>
        <div className="flex">
          <ResponsiveContainer width="90%" height={400}>
            <AreaChart
              width={600}
              height={300}
              data={monthlyData}
              margin={{
                top: 40,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="fuelAvg"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}

export default LoadsAnalysis;
