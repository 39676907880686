// partyActions.js
import axios from "axios";
import { setAllParties} from "../store/partySlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createParty = (partyForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedPartyForm = { ...partyForm, company: company};

    const response = await axios.post(
      `${base_url}/api/parties`,
      updatedPartyForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Party created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
    dispatch(setAllParties(response.data));
  } catch (error) {
    console.error("Error creating party:", error);
  }
};

export const fetchPartySuccess = (data) => ({
  type: "FETCH_CLIENTS_SUCCESS",
  payload: data,
});

export const fetchPartyFailure = (error) => ({
  type: "FETCH_CLIENTS_FAILURE",
  payload: error,
});

export const fetchParties = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/parties/company/${currentCompany}`,
      config,
    );
    dispatch(setAllParties(response.data));
  } catch (error) {
    console.error("Error fetching parties:", error);
    // Handle error if needed
  }
};

export const deletePartySuccess = (partyId) => ({
  type: "DELETE_CLIENT_SUCCESS",
  payload: partyId,
});

export const deletePartyFailure = (error) => ({
  type: "DELETE_CLIENT_FAILURE",
  payload: error,
});

export const deleteParty = (partyId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/parties/${partyId}`, {
      withCredentials: true,
    });
    dispatch(deletePartySuccess(partyId));
  } catch (error) {
    console.error("Error deleting party:", error);
    dispatch(deletePartyFailure(error.message));
  }
};

export const savePartySuccess = (PARTY) => ({
  type: "SAVE_PARTY_SUCCESS",
  payload: PARTY,
});

export const savePartyFailure = (error) => ({
  type: "SAVE_PARTY_FAILURE",
  payload: error,
});

export const updateParty = (partyId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/parties/${partyId}`, data, config);
    dispatch(fetchParties());
  } catch (error) {
    console.error("Error updating Party:", error);
    dispatch(savePartyFailure(error.message));
  }
};
