import axios from "axios";
import { setAllInvoice } from "../store/invoiceSlice";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";
import { startOfMonth, endOfMonth, parseISO } from 'date-fns';

export const createInvoice = (invoiceForm) => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedInvoiceForm = { ...invoiceForm, companyId: companyId };

    const response = await axios.post(
        `${base_url}/api/invoices`,
        updatedInvoiceForm,
        config,
    );

    if (response.status === 200) {
      dispatch(setAllInvoices(response.data));
    }
  } catch (error) {
    console.error("Error creating invoice:", error);
  }
};
export const setAllInvoices = (invoices) => ({
  type: "SET_ALL_INVOICES",
  payload: invoices,
});

export const fetchInvoicesFailure = (error) => ({
  type: "FETCH_INVOICES_FAILURE",
  payload: error,
});
export const fetchInvoices = (page = 1, limit = 50) => async (dispatch) => {
  try {
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
      params: {
        page,
        limit
      }
    };

    const response = await axios.get(
        `${base_url}/api/invoices/company/${currentCompany}`,
        config
    );

    dispatch(setAllInvoice(response.data.invoices));

    return {
      totalPages: response.data.totalPages,
      totalInvoices: response.data.totalInvoices,
      currentPage: response.data.currentPage
    };
  } catch (error) {
    console.error("Error fetching invoices:", error);
    throw error;  // Propagate the error so it can be handled in the component
  }
};

export const fetchInvoiceById = (invoiceId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(`${base_url}/api/invoices/${invoiceId}`, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching invoice details:", error);
    throw error;
  }
};

export const deleteInvoiceSuccess = (invoiceId) => ({
  type: "DELETE_INVOICE_SUCCESS",
  payload: invoiceId,
});

export const deleteInvoiceFailure = (error) => ({
  type: "DELETE_INVOICE_FAILURE",
  payload: error,
});

export const deleteInvoice = (invoiceId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/invoices/${invoiceId}`, {
      withCredentials: true,
    });
    dispatch(deleteInvoiceSuccess(invoiceId));
  } catch (error) {
    console.error("Error deleting invoice:", error);
    dispatch(deleteInvoiceFailure(error.message));
  }
};

export const updateInvoice = (invoiceId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/invoices/${invoiceId}`, data, config);
    dispatch(fetchInvoices());
  } catch (error) {
    console.error("Error updating Invoice:", error);
    dispatch(saveInvoiceFailure(error.message));
  }
};
export const saveInvoiceSuccess = (invoice) => ({
  type: "SAVE_INVOICE_SUCCESS",
  payload: invoice,
});

export const saveInvoiceFailure = (error) => ({
  type: "SAVE_INVOICE_FAILURE",
  payload: error,
});

export const generateBatchReport = (startDate, endDate) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };

    // Convert string dates to Date objects
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    const response = await axios.get(
        `${base_url}/api/invoices/batch-report`,
        {
          params: { startDate: start.toISOString(), endDate: end.toISOString() },
          ...config
        }
    );
    const invoices = response.data;
    // Calculate total amount
    const totalAmount = invoices.reduce((sum, invoice) => sum + invoice.totalAmount, 0);
    // Format the report
    const report = {
      startDate,
      endDate,
      totalAmount,
      invoices: invoices.map(invoice => ({
        invoiceNo: invoice.invoiceNo,
        customerName: invoice.customer.name,
        amount: invoice.totalAmount,
        carrierCost: invoice.carrierCost,
        date: invoice.createdDate
      }))
    };

    return report;
  } catch (error) {
    console.error('Error generating batch report:', error);
    throw error;
  }
};

// Helper function to get report for a specific month
export const getMonthlyReport = (year, month) => async (dispatch) => {
  const startDate = startOfMonth(new Date(year, month - 1));
  const endDate = endOfMonth(new Date(year, month - 1));
  return dispatch(generateBatchReport(startDate.toISOString(), endDate.toISOString()));
};

export const fetchDashboardAnalytics = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/dashboard/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard analytics:", error);
    throw error;
  }
};

export const fetchRevenueBreakdown = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/revenue-breakdown/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching revenue breakdown:", error);
    throw error;
  }
};

export const fetchInvoiceTrends = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/invoice-trends/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching invoice trends:", error);
    throw error;
  }
};

export const fetchRevenueByCompany = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/revenue-by-company/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching revenue by company:", error);
    throw error;
  }
};

export const fetchTopCustomersRevenueTrends = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/top-customers-revenue-trends/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching top customers revenue trends:", error);
    throw error;
  }
};

export const fetchCostAnalysis = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/cost-analysis/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching cost analysis:", error);
    throw error;
  }
};

export const fetchProfitMargins = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/profit-margins/${companyId}`,
        config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching profit margins:", error);
    throw error;
  }
};

export const fetchInvoiceCount = () => async (dispatch) => {
  try {
    const companyId = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
        `${base_url}/api/invoices/count/${companyId}`,
        config
    );

    return response.data.count;
  } catch (error) {
    console.error("Error fetching invoice count:", error);
    throw error;
  }
};