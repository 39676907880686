import React, {useCallback, useEffect, useMemo, useState} from "react";
import SettingsNavbar from "./SettingsNavbar";
import {
    defaultInputLargeStyle,
    defaultInputLargeInvalidStyle,
    defaultSkeletonLargeStyle,
} from "../../constants/defaultStyles";
import Skeleton from "react-loading-skeleton";
import ImageUpload from "../../components/Common/ImageUpload";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentUser, getCurrentUser, updateUserForm} from "../../store/userSlice";
import {toast} from "react-toastify";
import axios from "axios";
import {useAppContext} from "../../context/AppContext";
import base_url from "../../utils/config";
import {AccountCircle, Email, Lock} from "@mui/icons-material";

const emptyForm = {
    id: "",
    image: "",
    firstName: "",
    lastName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
};

function AccountSettings() {
    const dispatch = useDispatch();
    const user = useSelector(getCurrentUser);
    const {initLoading: isInitLoading} = useAppContext();
    const [isTouched, setIsTouched] = useState(false);
    const [userForm, setUserForm] = useState(emptyForm);
    const [validForm, setValidForm] = useState(
        Object.keys(emptyForm).reduce((a, b) => {
            return {...a, [b]: false};
        }, {})
    );
    const imageUploadClasses = useMemo(() => {
        const defaultStyle = "rounded-xl ";

        if (!userForm.image) {
            return defaultStyle + " border-dashed border-2 border-indigo-400 ";
        }

        return defaultStyle;
    }, [userForm]);

    const onChangeImage = useCallback((str) => {
        setUserForm((prev) => ({...prev, image: str}));
    }, []);

    const handleUserValue = useCallback((event, keyName) => {
        const value = event.target.value;
        setUserForm((prev) => ({...prev, [keyName]: value}));
    }, []);

    const submitHandler = async () => {
        setIsTouched(true);

        // Check for required fields
        const updatedFields = {};
        if (userForm.firstName) updatedFields.firstName = userForm.firstName;
        if (userForm.lastName) updatedFields.lastName = userForm.lastName;
        if (userForm.email) updatedFields.email = userForm.email;
        if (userForm.image) updatedFields.image = userForm.image;

        // Handle password update
        if (userForm.currentPassword || userForm.newPassword) {
            if (!userForm.currentPassword || !userForm.newPassword) {
                toast.error("Both current and new passwords are required to change the password.", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
                return;
            }
            updatedFields.currentPassword = userForm.currentPassword;
            updatedFields.newPassword = userForm.newPassword;
        }

        if (Object.keys(updatedFields).length === 0) {
            toast.error("No changes to update.", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        try {
            const response = await axios.put(
                `${base_url}/api/auth/me/${user._id}`,
                updatedFields,
                {withCredentials: true}
            );

            if (response.status === 200) {
                toast.success("User updated successfully!", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
                dispatch(updateUserForm(userForm));
                dispatch(setCurrentUser(response.data.user));
                setIsTouched(false);
            } else {
                toast.error("Failed to update user. Please try again.", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            }
        } catch (error) {
            console.error("Error updating user:", error);
            toast.error("An error occurred. Please try again.", {
                position: "bottom-center",
                autoClose: 2000,
            });
        }
    };

    useEffect(() => {
        if (user) {
            setUserForm(user);
        }
    }, [user]);

    useEffect(() => {
        setValidForm({
            id: true,
            image: !!userForm.image,
            firstName: !!userForm.firstName,
            lastName: !!userForm.lastName,
            email: !!userForm.email,
            currentPassword: !!userForm.currentPassword,
            newPassword: !!userForm.newPassword,
        });
    }, [userForm]);

    return (
        <div className="bg-white w-full flexgap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
            <SettingsNavbar/>
            <main className="w-full py-1">
                <div className="ml-5 text-lg font-bold">Account Settings</div>
                <div className="ml-5 text-sm text-gray-400">Real-time information and settings of for your personal
                    account
                </div>
                <div className="ml-5 w-[50%] h-1 mb-2 bg-gray-200"></div>
                <div className="flex flex-row ml-5 mt-5">
                    <ImageUpload
                        keyName="QuickEditImageUpload"
                        className={imageUploadClasses}
                        url={userForm.image || ""}
                        onChangeImage={onChangeImage}
                    />
                    <div className="flex flex-col ml-1">
                        <span className="pl-2 text-md font-bold mt-2">Profile Image</span>
                        <span className="pl-2 text-sm text-gray-400">PNG, JPG under 10MB</span>
                    </div>
                </div>
                <div className="p-2 md:p-4">
                    <div className="grid max-w-2xl">
                        <div className="flex flex-row pt-3">
                            <AccountCircle className="text-indigo-400"/>
                            <div className="text-md font-bold text-black">Full name</div>
                        </div>
                        <div className="items-center text-[#202142]">
                            <div
                                className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                <div className="w-full">
                                    <label
                                        htmlFor="first_name"
                                        className="block mb-2 text-sm font-medium "
                                    >
                                        Your first name
                                    </label>
                                    {isInitLoading ? (
                                        <Skeleton className={defaultSkeletonLargeStyle}/>
                                    ) : (
                                        <input
                                            value={userForm.firstName || ""}
                                            placeholder="First Name"
                                            className={
                                                !validForm.firstName && isTouched
                                                    ? defaultInputLargeInvalidStyle
                                                    : defaultInputLargeStyle
                                            }
                                            onChange={(e) => handleUserValue(e, "firstName")}
                                            disabled={isInitLoading}
                                        />
                                    )}
                                </div>

                                <div className="w-full">
                                    <label
                                        htmlFor="last_name"
                                        className="block mb-2 text-sm font-medium "
                                    >
                                        Your last name
                                    </label>
                                    {isInitLoading ? (
                                        <Skeleton className={defaultSkeletonLargeStyle}/>
                                    ) : (
                                        <input
                                            value={userForm.lastName || ""}
                                            placeholder="Last Name"
                                            className={
                                                !validForm.lastName && isTouched
                                                    ? defaultInputLargeInvalidStyle
                                                    : defaultInputLargeStyle
                                            }
                                            onChange={(e) => handleUserValue(e, "lastName")}
                                            disabled={isInitLoading}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <Email className="text-indigo-400"/>
                                <div className="text-md font-bold ">Contact Email</div>
                            </div>
                            <div className="text-sm text-gray-400">Manage your accounts email address for the
                                invoices
                            </div>
                            <div className="w-full h-1 mb-2 bg-gray-200"></div>
                            <div className="mb-2 sm:mb-6">
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium "
                                >
                                    Your email
                                </label>
                                {isInitLoading ? (
                                    <Skeleton className={defaultSkeletonLargeStyle}/>
                                ) : (
                                    <input
                                        value={userForm.email || ""}
                                        placeholder="Email"
                                        className={
                                            !validForm.email && isTouched
                                                ? defaultInputLargeInvalidStyle
                                                : defaultInputLargeStyle
                                        }
                                        onChange={(e) => handleUserValue(e, "email")}
                                        disabled={isInitLoading}
                                    />
                                )}
                            </div>
                            <div className="flex flex-row">
                                <Lock className="text-indigo-400"/>
                                <div className="text-md font-bold ">Password</div>
                            </div>
                            <div className="text-sm text-gray-400">Modify your current password</div>
                            <div className="w-full h-1 mb-2 bg-gray-200"></div>
                            <div
                                className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                <div className="w-full">
                                    <label
                                        htmlFor="current_password"
                                        className="block mb-2 text-sm font-medium w-full"
                                    >
                                        Current Password
                                    </label>
                                    <input
                                        type="password"
                                        id="current_password"
                                        className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                        placeholder="Current password"
                                        value={userForm.currentPassword || ""}
                                        onChange={(e) => handleUserValue(e, "currentPassword")}
                                    />
                                </div>

                                <div className="w-full">
                                    <label
                                        htmlFor="new_password"
                                        className="block mb-2 text-sm font-medium text-indigo-900 w-full"
                                    >
                                        New Password
                                    </label>
                                    <input
                                        type="password"
                                        id="new_password"
                                        className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                        placeholder="New password"
                                        value={userForm.newPassword || ""}
                                        onChange={(e) => handleUserValue(e, "newPassword")}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end">
                                <button
                                    onClick={submitHandler}
                                    type="submit"
                                    className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default AccountSettings;