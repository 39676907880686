import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import 'tui-calendar/dist/tui-calendar.css';
import Calendar from 'tui-calendar';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    InputAdornment, IconButton,

} from '@mui/material';
import { debounce } from 'lodash';
import {
    Close as CloseIcon,
    Save as SaveIcon,
    Delete as DeleteIcon,
    Event as EventIcon, SendToMobileOutlined,
} from '@mui/icons-material';
import TextFormatOutlinedIcon from '@mui/icons-material/TextFormatOutlined';
import InvoiceIcon from "../../components/Icons/InvoiceIcon";
import {PlaceOutlined} from "@mui/icons-material";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {
    getAllEventsSelector,
    getEventNewForm,
    onConfirmEditEvent,
    onConfirmDeletedEvent,
} from '../../store/eventSlice';
import {getAllTractorsSelector} from "../../store/tractorSlice";
import {getAllPartiesSelector} from "../../store/partySlice";
import {getAllDriversSelector} from '../../store/driverSlice';
import {
    createEvent,
    fetchEvents,
    deleteEvent,
    updateEvent,
    completeEvent,
    dispatchEventsToDrivers, markEventAsPending
} from '../../actions/eventActions';
import {fetchDrivers} from '../../actions/driverActions';
import {fetchTractors} from '../../actions/tractorActions';
import {fetchParties} from '../../actions/partyActions';
import InfoIcon from "../../components/Icons/InfoIcon";
import {getCurrentUser} from "../../store/userSlice";
import {getEventStatusColors} from "../../components/Schedule/getEventStatusColors";
import StatusWidget from "../../components/Widgets/StatusWidget";

const emptyForm = {
    id: "",
    title: "",
    description: "",
    tractor: "",
    driver: "",
    shipper: "",
    consignee: "",
    start: "",
    end: "",
    isView: false,
    completed: false,
    completedDate: "",
};

const Schedule = () => {

    const dispatch = useDispatch();
    const eventNewForm = useSelector(getEventNewForm);
    const allTractors = useSelector(getAllTractorsSelector);
    const allDrivers = useSelector(getAllDriversSelector);
    const allParties = useSelector(getAllPartiesSelector);
    const currentUser = useSelector(getCurrentUser);
    const allEvents = useSelector(getAllEventsSelector);

    const memoizedEventData = useMemo(() => {
        const safeEvents = Array.isArray(allEvents) ? allEvents : [];
        const pendingEvents = safeEvents.filter(event => event.statusName === "Pending" && event.statusIndex === "1");
        return { allEvents: safeEvents, pendingEvents };
    }, [allEvents]);

    const allShippers = allParties?.filter(cus => cus.type === "SHIPPER");
    const allConsignees = allParties?.filter(cus => cus.type === "CONSIGNEE");

    const [eventForm, setEventForm] = useState(emptyForm);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [validForm, setValidForm] = useState({
        id: false,
        title: false,
        tractor: false,
        driver: false,
        shipper: false,
        consignee: false,
        start: false,
    });
    //This function is for solving the date timezone issue.
    const formatDateForInput = (date) => {
        const local = new Date(date);
        local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return local.toISOString().slice(0, 16);
    };

    const [view, setView] = useState('month');
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [selectedDate, setSelectedDate] = useState(formatDateForInput(new Date()).split('T')[0]);

    const [widgetIndex, setWidgetIndex] = useState(0);

    const calendarRef = useRef(null);
    const calendarInstance = useRef(null);
    const eventsRef = useRef(memoizedEventData.allEvents) || "";
    const [lastUpdateTime, setLastUpdateTime] = useState(0);
    const cooldownPeriod = 300; // 1 second cooldown

    const debouncedUpdateEvent = useCallback(
        debounce((eventId, updatedEvent) => {
            dispatch(updateEvent(eventId, updatedEvent))
                .then(() => {
                    toast.success("Event updated successfully", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                    dispatch(fetchEvents());  // Refresh the events
                })
                .catch((error) => {
                    console.error("Error updating event:", error);
                    toast.error("Failed to update event", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                });
        }, cooldownPeriod),
        [dispatch]
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchEvents());
                dispatch(fetchDrivers());
                dispatch(fetchTractors());
                dispatch(fetchParties());
            } catch (error) {
                console.error(error);
            }
        };

        fetchData()

    }, [dispatch]);

    useEffect(() => {
        eventsRef.current = memoizedEventData.allEvents;
    }, [memoizedEventData, eventsRef]);

    useEffect(() => {
        if (eventNewForm) {
            const eventData = {...eventNewForm};
            setEventForm(eventData);
        }
    }, [eventNewForm]);

    useEffect(() => {
        setValidForm({
            id: true,
            title: !!eventForm.title,
            start: !!eventForm.start,
            tractor: !!eventForm.tractor,
            driver: !!eventForm.driver,
            shipper: !!eventForm.shipper,
            consignee: !!eventForm.consignee,
        });
    }, [eventForm]);

    //This is called when a date is clicked to create a new event. It opens and empty modal
    const handleDateClick = useCallback((e) => {
        const start = new Date(e.start.toDate());
        const end = e.end ? new Date(e.end.toDate()) : new Date(start);

        // Check if the selected time is between 11:00 PM and 12:00 AM (maintain styling)
        if (start.getHours() >= 23 || start.getHours() === 0) {
            start.setHours(23, 0, 0, 0);
            end.setDate(end.getDate());
            end.setHours(24, 0, 0, 0);
        }

        // Check if it's a multi-day selection
        const isMultiDay = start.getDate() !== end.getDate() ||
            start.getMonth() !== end.getMonth() ||
            start.getFullYear() !== end.getFullYear();

        const daysDiff = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

        const eventData = {
            start: formatDateForInput(start),
            end: formatDateForInput(end),
            isMultiDay: isMultiDay,
            numberOfDays: daysDiff
        };
        setModalOpen(true);
        setEventForm(prev => ({
            ...prev,
            ...eventData
        }));
    }, []);

    //This is called when event is clicked for editing. It opens a pre-filled modal
    const handleEventClick = useCallback((e) => {
        const event = eventsRef.current.find(event => event._id === e.schedule.id);
        if (event) {
            setClickedEvent(event);
            setModalOpen(true);
            setEventForm({
                id: event._id,
                title: event.title,
                description: event.description,
                start: formatDateForInput(new Date(event.start)),
                end: formatDateForInput(new Date(event.end)),
                tractor: event.tractor ? event.tractor._id : '',
                driver: event.driver ? event.driver._id : '',
                shipper: event.shipper ? event.shipper._id : '',
                consignee: event.consignee ? event.consignee._id : '',
                completed: event.completed,
                isView: event.isView,
                completedDate: event.completedDate,
                statusIndex: event.statusIndex,
                statusName: event.statusName
            });
        } else {
            console.error('Event not found:');
        }
    }, [eventsRef]);

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    //This function either updates event or deletes it based on what the user has selected
    const handleConfirmClicked = () => {
        if (confirmTitle.toLowerCase().includes("update")) {
            const isValid = Object.values(validForm).every(Boolean);

            if (!isValid) {
                toast.error("Invalid Event Form!", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
                return;
            }

            if (clickedEvent) {
                dispatch(updateEvent(clickedEvent._id, eventForm))
                    .then(() => {
                        toast.success("Successfully Updated Event", {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    })
                    .finally(() => {
                        dispatch(onConfirmEditEvent(eventForm));
                    });
            }
        }

        if (confirmTitle.toLowerCase().includes("complete")) {
            const isValid = Object.values(validForm).every(Boolean);

            if (!isValid) {
                toast.error("Invalid Event Form!", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
                return;
            }

            if (clickedEvent) {

                dispatch(completeEvent(clickedEvent._id, eventForm))
                    .then(() => {
                        toast.success("Event Completed", {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    })
                    .finally(() => {
                        dispatch(onConfirmDeletedEvent());
                    });
            }
        }

        if (confirmTitle.toLowerCase().includes("dispatch")) {
            const isValid = Object.values(validForm).every(Boolean);

            if (!isValid) {
                toast.error("Invalid Event Form!", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
                return;
            }

            if (clickedEvent) {
                dispatch(markEventAsPending(clickedEvent._id))
                    .then(() => {
                        toast.success("Added to dispatch list", {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    });
            }
        }

        if (confirmTitle.toLowerCase().includes("delete")) {
            if (clickedEvent) {
                dispatch(deleteEvent(clickedEvent._id))
                    .then(() => {
                        toast.success("Successfully Deleted", {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    })
                    .finally(() => {
                        dispatch(onConfirmDeletedEvent());
                    });
            }
        }

        handleConfirmClose();
        handleModalClose();
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setEventForm(emptyForm);
        setClickedEvent(null);
    };

    const handleInputChange = (event, keyName) => {
        let value = event.target.value;
        if (keyName === "start") {
            let inputDate = new Date(value);
            // Check if the selected time is between 11:00 PM and 12:00 AM
            if (inputDate.getHours() >= 23 || inputDate.getHours() === 0) {
                inputDate.setHours(23, 0, 0, 0);  // Set start time to 11:00 PM
            }
            let endDate = new Date(inputDate);
            endDate.setHours(endDate.getHours() + 1);
            const startStr = formatDateForInput(inputDate);
            const endStr = formatDateForInput(endDate);
            setEventForm((prev) => ({
                ...prev,
                start: startStr,
                end: endStr,
                validForm: {...prev.validForm, [keyName]: !!value},
            }));
        } else {
            setEventForm((prev) => ({
                ...prev,
                [keyName]: value,
                validForm: {...prev.validForm, [keyName]: !!value},
            }));
        }
    };

    //This function creates a new event
    const handleEventSave = () => {
        const isValid = Object.values(validForm).every(Boolean);

        if (!isValid) {
            toast.error("Invalid Event Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        try {
            const start = new Date(eventForm.start);
            const startHours = start.getHours();
            const startMinutes = start.getMinutes();

            for (let i = 0; i < eventForm.numberOfDays; i++) {
                const currentDate = new Date(start);
                currentDate.setDate(start.getDate() + i);
                currentDate.setHours(startHours, startMinutes, 0, 0);

                const endDate = new Date(currentDate);
                endDate.setHours(endDate.getHours() + 1);

                const eventData = {
                    ...eventForm,
                    start: formatDateForInput(currentDate),
                    end: formatDateForInput(endDate),
                    isMultiDay: false,
                    numberOfDays: 1,
                };
                dispatch(createEvent(eventData));
            }
            dispatch(fetchEvents()).then(()=>{
                toast.success(`Created ${eventForm.numberOfDays} event(s)`, {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            })

        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error, {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            }
            console.error("Error saving event:", error);
        }

        handleModalClose();
        dispatch(fetchEvents()).then(()=>{
           console.log("400: events")
        })
    };

    //Below three functions opens confirm modal and shows the text based on what action is being performed.
    const handleEventUpdate = useCallback(() => {
        const requiredFields = ['title', 'start', 'tractor', 'driver', 'shipper', 'consignee'];
        const isValid = requiredFields.every(field => !!eventForm[field]);

        if (isValid) {
            setConfirmTitle("Confirm Update?");
            setConfirmMessage("Are you sure you want to update this event?");
            setConfirmOpen(true);
        } else {
            toast.error("Invalid Event Form. Please ensure all required fields are filled.", {
                position: "bottom-center",
                autoClose: 2000,
            });
        }
    }, [eventForm]);

    const handleEventDelete = useCallback(() => {
        setConfirmTitle("Confirm Delete?");
        setConfirmMessage("Are you sure you want to delete this event?\nThis action cannot be reverted.");
        setConfirmOpen(true);
    }, []);

    const handleCompleted = (e) => {
        if (e.target.checked) {
            setConfirmTitle("Confirm Completed?");
            setConfirmMessage("Are you sure you want to mark this event as completed?");
            setConfirmOpen(true);
        }
    };

    const handleReadyForDispatch = (e) => {
        const isPending = e.target.checked;
        setEventForm(prev => ({ ...prev, isPending }));

        dispatch(updateEvent(clickedEvent._id, { ...eventForm, statusName: "Pending", statusIndex: 1}))
            .then(() => {
                if (isPending) {
                    toast.success("Added to dispatch list", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                } else {
                    toast.info("Removed from dispatch list", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                console.error("Error updating event dispatch status:", error);
                toast.error("Failed to update dispatch status", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            });
    };

    const handleDispatchToDrivers = () => {
        if (memoizedEventData.pendingEvents.length > 0) {
            dispatch(dispatchEventsToDrivers())
                .then(() => {
                    dispatch(fetchEvents()).then(() => {
                        toast.success("Events dispatched to drivers!", {
                            position: "bottom-center",
                            autoClose: 2000,
                        });
                    });
                })
                .catch((error) => {
                    toast.error("Failed to dispatch events to drivers", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                });
        } else {
            toast.info("No pending events to dispatch", {
                position: "bottom-center",
                autoClose: 2000,
            });
        }
    };

    const confirmDispatch = () => {
        if (clickedEvent) {
            dispatch(markEventAsPending(clickedEvent._id))
                .then(() => {
                    toast.success("Added topoopy butthole dispatch list", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                })
                .catch((error) => {
                    console.error("Error marking event as pending:", error);
                    toast.error("Failed to add to dispatch list", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                });
        }
        setConfirmOpen(false);
    };

    //Below four functions are used to navigate to different dates within the calendar
    const handlePrevClick = () => {
        if (calendarInstance.current) {
            calendarInstance.current.prev();
        }
    };

    const handleNextClick = () => {
        if (calendarInstance.current) {
            calendarInstance.current.next();
        }
    };

    const handleTodayClick = () => {
        if (calendarInstance.current) {
            calendarInstance.current.today();
        }
    };

    const handleDateSelect = (e) => {
        const selectedDate = new Date(e.target.value);
        setSelectedDate(e.target.value);
        if (calendarInstance.current) {
            calendarInstance.current.setDate(selectedDate);
        }
    };

    //This function is attached to calendar's beforeUpdateSchedule functionality to handle the drag and drop feature.
    const handleBeforeUpdateSchedule = useCallback((e) => {
        const { schedule, changes } = e;

        if (!schedule || !changes) {
            return;
        }

        const currentTime = Date.now();
        if (currentTime - lastUpdateTime < cooldownPeriod) {
            return;  // Exit if we're still in the cooldown period
        }

        const event = eventsRef.current.find(event => event._id === schedule.id);
        if (event) {
            const updatedEvent = {
                ...event,
                ...changes,
                start: changes.start ? formatDateForInput(new Date(changes.start._date)) : event.start,
                end: changes.end ? formatDateForInput(new Date(changes.end._date)) : event.end
            };

            // Ensure all required fields are present
            const requiredFields = ['title', 'start', 'tractor', 'driver', 'shipper', 'consignee'];
            const isValid = requiredFields.every(field => !!updatedEvent[field]);

            if (isValid) {
                setLastUpdateTime(currentTime);
                debouncedUpdateEvent(event._id, updatedEvent);
            } else {
                toast.error("Invalid Event Form. Please ensure all required fields are filled.", {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            }
        } else {
            console.error('Event not found:', schedule.id);
        }
    }, [eventsRef, lastUpdateTime, debouncedUpdateEvent]);

    //In this useEffect calendar is created, functions are attached to it and Default Details popup is modified.
    useEffect(() => {
        if (calendarRef.current && !calendarInstance.current) {
            calendarInstance.current = new Calendar(calendarRef.current, {
                defaultView: view,
                taskView: false,
                useCreationPopup: false,
                useDetailPopup: true,
                isReadOnly: false,
                month: {
                    startDayOfWeek: 0,
                    daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    visibleWeeksCount: 6,
                    isAlways6Weeks: false,
                },
                template: {
                    monthDayname: function (dayname) {
                        return `<span class="calendar-week-dayname-name">${dayname.label}</span>`;
                    },
                    popupDetailDate: function (isAllDay, start) {
                        return `${new Date(start).toLocaleString()}`;
                    },
                },
            });

            calendarInstance.current.on('beforeCreateSchedule', handleDateClick);
            calendarInstance.current.on('beforeUpdateSchedule', handleBeforeUpdateSchedule);

            calendarInstance.current.on('clickSchedule', (event) => {
                const popupElement = document.querySelector('.tui-full-calendar-popup');
                if (popupElement) {
                    const deleteButton = popupElement.querySelector('.tui-full-calendar-popup-delete');
                    if (deleteButton) {
                        deleteButton.addEventListener('click', (e) => {
                            e.preventDefault();
                            handleEventClick({schedule: event.schedule});
                            handleEventDelete();
                        });
                    }

                    const editButton = popupElement.querySelector('.tui-full-calendar-popup-edit');
                    if (editButton) {
                        editButton.addEventListener('click', (e) => {
                            e.preventDefault();
                            handleEventClick({schedule: event.schedule});
                        });
                    }
                }
            });
        }
    }, [view, handleDateClick, handleEventClick, handleEventDelete, handleBeforeUpdateSchedule]);

    //This useEffect takes care of current view and the current date range
    useEffect(() => {
        if (calendarInstance.current) {
            calendarInstance.current.changeView(view);
        }
    }, [view]);

    //In this useEffect all the events are created to be displayed in the calendar
    useEffect(() => {
        if (calendarInstance.current && memoizedEventData?.allEvents?.length > 0) {
            calendarInstance.current.clear();

            const calendarEvents = memoizedEventData.allEvents
                .filter(event => event.driver && event.shipper && event.consignee)
                .map(event => {
                    const { bgColor, borderColor, customStyle } = getEventStatusColors(event.statusName, event.statusIndex);

                    return {
                        id: event._id,
                        calendarId: '1',
                        title: `${event.title} - ${event.driver.firstName}`,
                        body: `
                          <div style="padding: 8px; font-size: 15px;">
                            <div style="margin-bottom: 8px;">
                              <strong>Driver:</strong> ${event.driver.firstName} ${event.driver.lastName}
                            </div>
                            <div style="margin-bottom: 4px;">
                              <strong>From:</strong> ${event.shipper.name}
                            </div>
                            <div style="margin-bottom: 4px;  font-style: normal;">
                              <strong>To:</strong> ${event.consignee.name}
                            </div>
                            <div style="margin-bottom: 4px;">
                              <strong>Description</strong> 
                            </div>
                            ${event.description ? `<div style="margin-top: 8px; font-style: normal;">${event.description}</div>` : ''}
                          </div>
                        `,
                        start: event.start,
                        end: event.end,
                        category: 'time',
                        dueDateClass: '',
                        color: borderColor,
                        bgColor: bgColor,
                        borderColor: borderColor,
                        customStyle: customStyle
                    };
                });

            calendarInstance.current.createSchedules(calendarEvents);
        }
    }, [memoizedEventData, calendarInstance]);

    useEffect(() => {
        if (clickedEvent) {
            if (parseInt(clickedEvent.statusIndex) < 3) {
                setWidgetIndex(0);
            } else if (clickedEvent.statusIndex === "3") {
                setWidgetIndex(1)
            } else if (clickedEvent.statusIndex === "4") {
                setWidgetIndex(2)
            }
        }
    }, [clickedEvent]);

    return (
        <div className="flex flex-col h-screen p-4">
            <header className="p-4 bg-white shadow-sm w-full">
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <h1 className="text-2xl font-semibold">Hi, {currentUser.firstName}👋</h1>
                        <h2 className="text-lg text-gray-600">Schedule</h2>
                    </div>
                    <InfoIcon message={"GRAY is default  |  ORANGE is ready for dispatch  |  BLUE is dispatched!"}/>
                    <button
                        onClick={handleDispatchToDrivers}
                        className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300 flex items-center"
                        disabled={memoizedEventData.pendingEvents.length === 0}
                    >
                        Dispatch to Drivers
                        <SendToMobileOutlined className="ml-2"/>
                    </button>
                </div>
                <p className="text-sm text-gray-600">Pending events to dispatch: {memoizedEventData.pendingEvents.length}</p>

                <div className="flex justify-between items-center mb-4">
                    <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
                        <button
                            className={`px-4 py-2 rounded-md transition duration-300 ${view === 'day' ? 'bg-white shadow-sm' : 'hover:bg-white'}`}
                            onClick={() => setView('day')}
                        >
                            Day
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md transition duration-300 ${view === 'week' ? 'bg-white shadow-sm' : 'hover:bg-white'}`}
                            onClick={() => setView('week')}
                        >
                            Week
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md transition duration-300 ${view === 'month' ? 'bg-white shadow-sm' : 'hover:bg-white'}`}
                            onClick={() => setView('month')}
                        >
                            Month
                        </button>
                    </div>

                    <div className="flex items-center space-x-2">
                        <button
                            className="p-2 rounded-full hover:bg-gray-100 transition duration-300"
                            onClick={handlePrevClick}
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M15 19l-7-7 7-7"></path>
                            </svg>
                        </button>
                        <button
                            className="px-4 py-2 rounded-md hover:bg-gray-100 transition duration-300"
                            onClick={handleTodayClick}
                        >
                            Today
                        </button>
                        <button
                            className="p-2 rounded-full hover:bg-gray-100 transition duration-300"
                            onClick={handleNextClick}
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M9 5l7 7-7 7"></path>
                            </svg>
                        </button>
                        <input
                            type="date"
                            className="px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={selectedDate}
                            onChange={handleDateSelect}
                        />
                    </div>
                </div>
            </header>
            <div className="flex-grow w-full h-[calc(100vh-200px)]">
                <div ref={calendarRef} className="h-full w-full"></div>
            </div>
            <div className="w-[20%]">
                <Dialog open={modalOpen} onClose={handleModalClose} fullWidth>
                    <div className="ml-10 w-[80%]">
                        {clickedEvent && (<StatusWidget index={widgetIndex} /> )}
                    </div>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleModalClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>

                    <DialogContent className='flex flex-col'>
                        <div className='flex flex-row space-x-4'>
                            <TextField
                                label="Title"
                                name="title"
                                value={eventForm.title || ""}
                                onChange={(e) => handleInputChange(e, "title")}
                                fullWidth
                                margin="dense"
                                id="event-title"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TextFormatOutlinedIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={eventForm.description || ""}
                                onChange={(e) => handleInputChange(e, "description")}
                                fullWidth
                                margin="dense"
                                id="EventDescription"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InvoiceIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className='flex flex-row space-x-4'>
                            <Autocomplete
                                options={allTractors}
                                className="w-full"
                                getOptionLabel={(option) => option ? String(option.tractorID) : ""}
                                value={allTractors.find((tractor) => tractor._id === eventForm.tractor) || null}
                                onChange={(event, newValue) => {
                                    setEventForm((prev) => ({
                                        ...prev,
                                        tractor: newValue ? newValue._id : '',
                                    }));
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tractor" margin="dense" fullWidth
                                               id="schedule-tractor-id"
                                               name="ScheduleTractorId"
                                               InputProps={{
                                                   ...params.InputProps,
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <LocalShippingOutlinedIcon/>
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />
                                )}
                            />
                            <Autocomplete
                                options={allDrivers}
                                className="w-full"
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                value={allDrivers.find((driver) => driver._id === eventForm.driver) || null}
                                onChange={(event, newValue) => {
                                    setEventForm((prev) => ({
                                        ...prev,
                                        driver: newValue ? newValue._id : '',
                                    }));
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                    <TextField {...params} id="schedule-driver-id"
                                               name="ScheduleDriverId" label="Driver" margin="dense" fullWidth
                                               InputProps={{
                                                   ...params.InputProps,
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <BadgeOutlinedIcon />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />
                                )}
                            />
                        </div>
                        <div className='flex flex-row space-x-4'>
                            <Autocomplete
                                options={allShippers}
                                className="w-full"
                                getOptionLabel={(option) => option.name}
                                value={allShippers.find((shipper) => shipper._id === eventForm.shipper) || null}
                                onChange={(event, newValue) => {
                                    setEventForm((prev) => ({
                                        ...prev,
                                        shipper: newValue ? newValue._id : '',
                                    }));
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                    <TextField {...params} id="schedule-shipper-id"
                                               name="ScheduleShipperId" label="Shipper" margin="dense" fullWidth
                                               InputProps={{
                                                   ...params.InputProps,
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <PlaceOutlined />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />
                                )}
                            />
                            <Autocomplete
                                options={allConsignees}
                                className="w-full"
                                getOptionLabel={(option) => option.name}
                                value={allConsignees.find((consignee) => consignee._id === eventForm.consignee) || null}
                                onChange={(event, newValue) => {
                                    setEventForm((prev) => ({
                                        ...prev,
                                        consignee: newValue ? newValue._id : '',
                                    }));
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                    <TextField {...params} id="schedule-consignee-id"
                                               name="ScheduleConsigneeId" label="Consignee" margin="dense" fullWidth
                                               InputProps={{
                                                   ...params.InputProps,
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <PlaceOutlined />
                                                       </InputAdornment>
                                                   ),
                                               }}
                                    />
                                )}
                            />
                        </div>
                            <div className='flex flex-row space-x-4'>
                                    <TextField
                                        name="date & time"
                                        type="datetime-local"
                                        value={eventForm.start || Date.now()}
                                        onChange={(e) => handleInputChange(e, "start")}
                                        fullWidth
                                        margin="dense"
                                        id="StartTime"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                            </div>
                        {eventForm.isMultiDay && (
                            <>
                                    <InfoIcon message={"Choose the start date, and time. the rest of the selected days will autofill."}/>
                                    <div className="text-bold text-gray-700">
                                        This will create {eventForm.numberOfDays} events, one for each day in the selected
                                        range.
                                    </div>
                            </>
                            )}
                        {clickedEvent && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={eventForm.completed}
                                            onChange={(e) => handleCompleted(e, "completed")}
                                        />
                                    }
                                    label="Mark as completed"
                                />
                            </>
                        )}
                        {clickedEvent && parseInt(clickedEvent.statusIndex) <= 1 && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={clickedEvent.statusIndex === "1" || false}
                                        onChange={handleReadyForDispatch}
                                    />
                                }
                                label="Ready for dispatch"
                            />
                        )}
                        {clickedEvent && clickedEvent.statusIndex === "2" && (
                            <div className="text-green-600 font-semibold">This event has been dispatched</div>
                        )}

                        {clickedEvent && clickedEvent.statusIndex === "4" && (
                            <div className="text-green-600 font-semibold">This event has been delivered</div>
                        )}
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleModalClose} color="primary" startIcon={<CloseIcon />}>Cancel</Button>
                        {clickedEvent && <Button onClick={handleEventDelete} color="secondary" startIcon={<DeleteIcon />}>Delete</Button>}
                        {clickedEvent ?
                            <Button onClick={handleEventUpdate} color="primary" startIcon={<SaveIcon />}>Update</Button> :
                            <Button onClick={handleEventSave} color="primary" startIcon={<SaveIcon />}>Save</Button>}
                    </DialogActions>
                </Dialog>
            </div>
            <Dialog
                open={confirmOpen}
                onClose={handleConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                             className="text-lg font-bold text-gray-800">{confirmTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="text-gray-700">
                        {confirmMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary" className="text-blue-500">
                        Cancel
                    </Button>
                    <Button
                        onClick={confirmTitle.toLowerCase().includes("dispatch") ? confirmDispatch : handleConfirmClicked}
                        color="primary"
                        className="text-blue-500"
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Schedule;
