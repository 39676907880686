import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import { useAppContext } from "../../context/AppContext";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import { getTrailerNewForm } from "../../store/trailerSlice";
import { fetchTrailers, createTrailer } from "../../actions/trailerActions";
import SubmitButton from "../Button/SubmitButton";

const emptyForm = {
  trailerID: "",
  type: "",
  trailerMake: "",
  trailerModel: "",
  licensePlate: "",
};

function QuickAddTrailer() {
  const dispatch = useDispatch();
  const trailerNewForm = useSelector(getTrailerNewForm);
  const { initLoading: isInitLoading } = useAppContext();
  const [isTouched, setIsTouched] = useState(false);
  const [trailerForm, setTrailerForm] = useState(emptyForm);
  const [validForm, setValidForm] = useState({
    id: false,
    trailerID: false,
    type: false,
    trailerMake: false,
    trailerModel: false,
    licensePlate: false,
    user: false,
  });

  const handlerTrailerValue = (event, keyName) => {
    const value = event.target.value;
    setTrailerForm((prev) => ({
      ...prev,
      [keyName]: value,
      validForm: { ...prev.validForm, [keyName]: !!value },
    }));
  };

  useEffect(() => {
    if (trailerNewForm) {
      const trailerData = { ...trailerNewForm };
      setTrailerForm(trailerData);
    }
  }, [trailerNewForm]);


  const submitHandler = async (event) => {
    event.preventDefault();
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid trailer Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      dispatch(createTrailer(trailerForm));
      dispatch(fetchTrailers());
      setTrailerForm(emptyForm);
      setIsTouched(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error, {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
      console.error("Error saving trailer:", error);
    }
  };

  useEffect(() => {
    setValidForm({
      trailerID: !!trailerForm.trailerID,
      type: !!trailerForm.type,
      trailerMake: !!trailerForm.trailerMake,
      trailerModel: !!trailerForm.trailerModel,
      licensePlate: !!trailerForm.licensePlate,
    });
  }, [trailerForm]);

  return (
      <div className="bg-white rounded-xl p-4">
        <SectionTitle> Add Trailer </SectionTitle>
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">Trailer ID</div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Trailer ID"
                      type="text"
                      className={
                        !validForm.trailerID && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={trailerForm.trailerID}
                      onChange={(e) => handlerTrailerValue(e, "trailerID")}
                  />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Trailer Make
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Trailer Make"
                      type="text"
                      className={
                        !validForm.trailerMake && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={trailerForm.trailerMake}
                      onChange={(e) => handlerTrailerValue(e, "trailerMake")}
                  />
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Trailer Model
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Trailer Model"
                      type="text"
                      className={
                        !validForm.trailerModel&& isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={trailerForm.trailerModel}
                      onChange={(e) => handlerTrailerValue(e, "trailerModel")}
                  />
              )}
            </div>
          </div>
        </div>


        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Trailer Type
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="52ft, flatbed, etc..."
                      type="text"
                      className={
                        !validForm.type && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={trailerForm.type}
                      onChange={(e) => handlerTrailerValue(e, "type")}
                  />
              )}
            </div>
          </div>
        </div>


        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            License Plate
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="License Plate"
                      type="text"
                      className={
                        !validForm.licensePlate && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={trailerForm.licensePlate}
                      onChange={(e) => handlerTrailerValue(e, "licensePlate")}
                  />
              )}
            </div>
          </div>
        </div>


        <div className="mt-3">
          <SubmitButton onClick={submitHandler}/>
        </div>
      </div>
  );
}

export default QuickAddTrailer;