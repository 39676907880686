import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import { useAppContext } from "../../context/AppContext";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import { getCarrierNewForm } from "../../store/carrierSlice";
import { fetchCarriers, createCarrier } from "../../actions/carrierActions";
import SubmitButton from "../Button/SubmitButton";

const emptyForm = {
  carrierId: "",
  carrierName: "",
  email: "",
  address: "",
  phoneNumber: "",
  mcNumber: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function QuickAddCarrier() {
  const dispatch = useDispatch();
  const carrierNewForm = useSelector(getCarrierNewForm);
  const { initLoading: isInitLoading } = useAppContext();

  const [isTouched, setIsTouched] = useState(false);
  const [carrierForm, setCarrierForm] = useState(emptyForm);
  const [validForm, setValidForm] = useState({
    carrierId: false,
    carrierName: false,
    email: false,
    mcNumber: false,
    address: false,
    phoneNumber: false,
  });

  const handlerCarrierValue = (event, keyName) => {
    const value = event.target.value;
    setCarrierForm((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };

  const submitHandler = async () => {
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid carrier form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    try {

      dispatch(createCarrier(carrierForm));
      dispatch(fetchCarriers());

      setCarrierForm(emptyForm);
      setIsTouched(false);
    } catch (error) {
      console.error("Error saving carrier:", error);
      toast.error("An error occurred. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    const isValidEmail = carrierForm?.email?.trim() && carrierForm?.email.match(emailRegex);

    setValidForm({
      carrierId: carrierForm.carrierId > 0,
      carrierName: carrierForm.carrierName ? carrierForm.carrierName.trim() !== "" : false,
      email: isValidEmail ? true : false,
      address: carrierForm.address ? carrierForm.address.trim() !== "" : false,
      phoneNumber: carrierForm.phoneNumber ? carrierForm.phoneNumber.trim() !== "" : false,
      mcNumber: carrierForm.mcNumber > 0,
    });
  }, [carrierForm]);

  useEffect(() => {
    if (carrierNewForm) {
      setCarrierForm(carrierNewForm);
    }
  }, [carrierNewForm]);

  return (
    <div className="bg-white rounded-xl p-4">
      <SectionTitle> Add Carrier </SectionTitle>

      <div className="mt-2">
        <div className="font-title text-sm text-default-color">Carrier ID</div>
        <div className="flex">
          <div className="flex-1">
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonNormalStyle} />
            ) : (
              <input
                autoComplete="nope"
                placeholder="Carrier ID"
                type="number"
                min={0}
                className={
                  !validForm.carrierId && isTouched
                    ? defaultInputInvalidStyle
                    : defaultInputStyle
                }
                disabled={isInitLoading}
                value={carrierForm.carrierId || ""}
                onChange={(e) => handlerCarrierValue(e, "carrierId")}
              />
            )}
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div className="font-title text-sm text-default-color">
          Carrier Name
        </div>
        <div className="flex">
          <div className="flex-1">
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonNormalStyle} />
            ) : (
              <input
                autoComplete="nope"
                placeholder="Carrier Name"
                type="text"
                className={
                  !validForm.carrierName && isTouched
                    ? defaultInputInvalidStyle
                    : defaultInputStyle
                }
                disabled={isInitLoading}
                value={carrierForm.carrierName || ""}
                onChange={(e) => handlerCarrierValue(e, "carrierName")}
              />
            )}
          </div>
        </div>
      </div>
          <div className="mt-2">
            <div className="font-title text-sm text-default-color">
              Email
            </div>
            <div className="flex">
              <div className="flex-1">
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    autoComplete="nope"
                    placeholder="example@email.com"
                    type="text"
                    className={
                      !validForm.email && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    disabled={isInitLoading}
                    value={carrierForm.email || ""}
                    onChange={(e) => handlerCarrierValue(e, "email")}
                  />
                )}
              </div>
            </div>
          </div>

        <div className="mt-2">
          <div className="font-title text-sm text-default-color">MC Number</div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                <Skeleton className={defaultSkeletonNormalStyle} />
              ) : (
                <input
                  autoComplete="nope"
                  placeholder="MC Number"
                  type="number"
                  min={0}
                  className={
                    !validForm.mcNumber && isTouched
                      ? defaultInputInvalidStyle
                      : defaultInputStyle
                  }
                  disabled={isInitLoading}
                  value={carrierForm.mcNumber || ""}
                  onChange={(e) => handlerCarrierValue(e, "mcNumber")}
                />
              )}
            </div>
          </div>
          <div className="mt-2">
            <div className="font-title text-sm text-default-color">Address</div>
            <div className="flex">
              <div className="flex-1">
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    autoComplete="nope"
                    placeholder="Address"
                    type="text"
                    className={
                      !validForm.address && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    disabled={isInitLoading}
                    value={carrierForm.address || ""}
                    onChange={(e) => handlerCarrierValue(e, "address")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="font-title text-sm text-default-color">
              Phone Number
            </div>
            <div className="flex">
              <div className="flex-1">
                {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle} />
                ) : (
                  <input
                    autoComplete="nope"
                    placeholder="Phone Number"
                    type="text"
                    className={
                      !validForm.phoneNumber && isTouched
                        ? defaultInputInvalidStyle
                        : defaultInputStyle
                    }
                    disabled={isInitLoading}
                    value={carrierForm.phoneNumber || ""}
                    onChange={(e) => handlerCarrierValue(e, "phoneNumber")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      <div className="mt-3">
        <SubmitButton onClick={submitHandler} />
      </div>
    </div>
  );
}

export default QuickAddCarrier;

