import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
  defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import { getAllInvoiceSelector, setDeleteId } from "../../store/invoiceSlice";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import InvoiceIcon from "../Icons/InvoiceIcon";
import { useAppContext } from "../../context/AppContext";
import EmptyBar from "../Common/EmptyBar";
import { fetchInvoices } from "../../actions/invoiceActions";

const itemsPerPage = 10;
const emptySearchForm = {
  invoiceNo: "",
  customerName: "",
};

function InvoiceTable({ showAdvanceSearch = false }) {
  const { initLoading } = useAppContext();
  const dispatch = useDispatch();
  const allInvoices = useSelector(getAllInvoiceSelector);
  const navigate = useNavigate();

  const [searchForm, setSearchForm] = useState(emptySearchForm);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  //paginate
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const itemsPerPage = 50;


  const invoices = useMemo(() => {
    let filterData = allInvoices.length > 0 ? [...allInvoices].reverse() : [];
    
    if (searchForm.invoiceNo?.trim()) {
      filterData = filterData.filter((invoice) =>
        invoice.invoiceNo.includes(searchForm.invoiceNo)
      );
    }
  
    if (searchForm.customerName?.trim()) {
      filterData = filterData.filter((invoice) =>
        invoice.customer?.name?.toLowerCase().includes(searchForm.customerName.toLowerCase())
      );
    }
  
    return filterData;
  }, [allInvoices, searchForm]);


  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const handleDelete = useCallback(
      (item) => {
        dispatch(setDeleteId(item._id));
      },
      [dispatch]
  );

  const handleEdit = useCallback(
      (item) => {
        navigate("/invoices/" + item._id);
      },
      [navigate]
  );

  useEffect(() => {
    const loadInvoices = async () => {
      try {
        const result = await dispatch(fetchInvoices(currentPage, itemsPerPage));
        setTotalPages(result.totalPages);
        setTotalInvoices(result.totalInvoices);
      } catch (error) {
        console.error("Failed to fetch invoices:", error);
      }
    };

    loadInvoices();
  }, [dispatch, currentPage]);

  const handlerSearchValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setSearchForm((prev) => {
      return { ...prev, [keyName]: value };
    });

    setItemOffset(0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchInvoices());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(invoices.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(invoices.length / itemsPerPage));
  }, [invoices, itemOffset]);

  return (
    <>
      {showAdvanceSearch === true && (
          <div className="bg-white rounded-xl px-3 py-3 mb-3">
            <div className="font-title mb-2">Advanced Search</div>
            <div className="flex w-full flex-col sm:flex-row">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                  <InvoiceIcon className="h-6 w-6 text-gray-400"/>
                </div>
                <input
                    autoComplete="nope"
                    value={searchForm.invoiceNo}
                    placeholder="Invoice No"
                    className={defaultSearchStyle}
                    onChange={(e) => handlerSearchValue(e, "invoiceNo")}
                />
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                  >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                    autoComplete="nope"
                    value={searchForm.customerName}
                    placeholder="User Name"
                    className={defaultSearchStyle}
                    onChange={(e) => handlerSearchValue(e, "customerName")}
                />
              </div>
            </div>
          </div>
      )}

      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
          <div className="sm:text-left text-default-color font-title flex-1">
            Invoice #
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Customer Name
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Status
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Amount
          </div>
          <div className="sm:text-left text-default-color font-title sm:w-11">
            Action
          </div>
        </div>

        <div>
          {currentItems &&
            currentItems.filter(invoice => invoice !== undefined && invoice !== null).map((invoice) => (
              <div className={defaultTdWrapperStyle} key={`${invoice._id}`}>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Invoice #</div>
                  <div className={defaultTdContent}>
                    <span
                      className="whitespace-nowrap text-ellipsis overflow-hidden text-blue-500 cursor-pointer"
                      onClick={() => handleEdit(invoice)}
                    >
                      {invoice.invoiceNo}
                    </span>
                  </div>
                </div>

                <div className={defaultTdStyle}>
                <div className={defaultTdContentTitleStyle}>Customer Name</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {invoice.customer?.name || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Status</div>
                  <div className={defaultTdContent}>
                    <span
                      className={
                        "whitespace-nowrap text-ellipsis overflow-hidden px-3 rounded-xl  py-1 " +
                        (invoice.statusIndex === "2"
                          ? "bg-orange-100 text-orange-400"
                          : invoice.statusIndex === "3"
                          ? "bg-green-200 text-green-600"
                          : "bg-gray-100 text-gray-600 ")
                      }
                    >
                      {invoice.statusName}
                    </span>
                  </div>
                </div>

                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Amount</div>
                  <div className={defaultTdContent + " "}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden ">
                      <NumberFormat
                        value={invoice.totalAmount}
                        className=""
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value, props) => (
                          <span {...props}>{"$"}{value}</span>
                        )}
                      />
                    </span>
                  </div>
                </div>

                <div className={defaultTdActionStyle}>
                  <div className={defaultTdContentTitleStyle}>Action</div>
                  <div className={defaultTdContent}>
                    <Menu
                      menuButton={
                        <MenuButton>
                          <div className="bg-gray-50 px-2 rounded-xl">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </div>
                        </MenuButton>
                      }
                      transition
                    >
                      <MenuItem onClick={() => handleEdit(invoice)}>
                        Detail
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(invoice)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}

          {invoices.length <= 0 && !initLoading && (
            <EmptyBar title={"Invoice"} />
          )}

          {totalPages > 1 && (
              <ReactPaginate
                  className="inline-flex items-center -space-x-px mt-2"
                  previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={totalPages}
                  previousLabel="<"
                  nextLabel=">"
                  renderOnZeroPageCount={null}
                  forcePage={currentPage - 1}
              />
          )}
        </div>
      </div>
    </>
  );
}

export default InvoiceTable;