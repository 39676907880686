import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import { useAppContext } from "../../context/AppContext";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import { fetchDrivers, createDriver } from "../../actions/driverActions";
import SubmitButton from "../Button/SubmitButton";

const emptyForm = {
  driverId: 0,
  firstName: "",
  lastName: "",
  email: "",
  ownerOperator: false,
  wage: 0,
  rate: 0,
  address: "",
  phoneNumber: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function QuickAddDriver() {
  const dispatch = useDispatch();
  const { initLoading: isInitLoading } = useAppContext();

  const [isTouched, setIsTouched] = useState(false);
  const [driverForm, setDriverForm] = useState(emptyForm);
  const [validForm, setValidForm] = useState({
    driverId: false,
    firstName: false,
    lastName: false,
    email: false,
    ownerOperator: false,
    wage: false,
    rate: false,
    address: false,
    phoneNumber: false,
  });

  const handlerDriverValue = (event, keyName) => {
    const value = keyName === "ownerOperator" ? event.target.checked : event.target.value;
    setDriverForm((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };

  const submitHandler = async () => {
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid driver form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      // Ensure `ownerOperator` and `isCompany` are booleans before submission
      const formattedDriverForm = {
        ...driverForm,
        ownerOperator: Boolean(driverForm.ownerOperator),
      };

      dispatch(createDriver(formattedDriverForm));
      dispatch(fetchDrivers());

      setDriverForm(emptyForm);
      setIsTouched(false);
    } catch (error) {
      console.error("Error saving driver:", error);
      toast.error("An error occurred. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    const isValidEmail = driverForm?.email?.trim() && driverForm?.email.match(emailRegex);

    setValidForm({
      driverId: driverForm.driverId > 0,
      firstName: driverForm.firstName? driverForm.firstName.trim() !== "" : false,
      lastName: driverForm.lastName? driverForm.lastName.trim() !== "" : false,
      email: isValidEmail ? true : false,
      ownerOperator: driverForm.ownerOperator !== undefined,
      wage: !driverForm.ownerOperator ? driverForm.wage >= 0 : true,
      rate: driverForm.ownerOperator ? driverForm.rate >= 0 : true,
      address: driverForm.address? driverForm.address.trim() !== "" : false,
      phoneNumber: driverForm.phoneNumber? driverForm.phoneNumber.trim() !== "" : false,
    });
  }, [driverForm]);

  return (
      <div className="bg-white rounded-xl p-4">
        <SectionTitle> Add Driver </SectionTitle>

        <div className="mt-2">
          <div className="font-title text-sm text-default-color">Driver ID</div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Driver ID"
                      type="number"
                      min={0}
                      className={
                        !validForm.driverId && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.driverId || ""}
                      onChange={(e) => handlerDriverValue(e, "driverId")}
                  />
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            First Name
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="First Name"
                      type="text"
                      className={
                        !validForm.firstName && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.firstName || ""}
                      onChange={(e) => handlerDriverValue(e, "firstName")}
                  />
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Last Name
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Last Name"
                      type="text"
                      className={
                        !validForm.lastName && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.lastName || ""}
                      onChange={(e) => handlerDriverValue(e, "lastName")}
                  />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Email
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="example@email.com"
                      type="email"
                      className={
                        !validForm.email && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.email|| ""}
                      onChange={(e) => handlerDriverValue(e, "email")}
                  />
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <label className="font-title text-sm text-default-color">
            <input
                type="checkbox"
                checked={driverForm.ownerOperator}
                onChange={(e) => handlerDriverValue(e, "ownerOperator")}
            />{" "}
            Owner/Operator
          </label>
        </div>

        {!driverForm.ownerOperator &&
            <div className="mt-2">
              <div className="font-title text-sm text-default-color">
                Wage $/hr
              </div>
              <div className="flex">
                <div className="flex-1">
                  {isInitLoading ? (
                      <Skeleton className={defaultSkeletonNormalStyle}/>
                  ) : (
                      <input
                          autoComplete="nope"
                          placeholder="Wage"
                          type="number"
                          min={0}
                          className={
                            !validForm.wage && isTouched
                                ? defaultInputInvalidStyle
                                : defaultInputStyle
                          }
                          disabled={isInitLoading}
                          value={driverForm.wage || 0}
                          onChange={(e) => handlerDriverValue(e, "wage")}
                      />
                  )}
                </div>
              </div>
            </div>
        }
        {driverForm.ownerOperator &&
            <div className="mt-2">
              <div className="font-title text-sm text-default-color">
                Rate %
              </div>
              <div className="flex">
                <div className="flex-1">
                  {isInitLoading ? (
                      <Skeleton className={defaultSkeletonNormalStyle}/>
                  ) : (
                      <input
                          autoComplete="nope"
                          placeholder="%"
                          type="number"
                          min={0}
                          className={
                            !validForm.rate && isTouched
                                ? defaultInputInvalidStyle
                                : defaultInputStyle
                          }
                          disabled={isInitLoading}
                          value={driverForm.rate || 0}
                          onChange={(e) => handlerDriverValue(e, "rate")}
                      />
                  )}
                </div>
              </div>
            </div>
        }
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">Address</div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Address"
                      type="text"
                      className={
                        !validForm.address && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.address || ""}
                      onChange={(e) => handlerDriverValue(e, "address")}
                  />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">
            Phone Number
          </div>
          <div className="flex">
            <div className="flex-1">
              {isInitLoading ? (
                  <Skeleton className={defaultSkeletonNormalStyle}/>
              ) : (
                  <input
                      autoComplete="nope"
                      placeholder="Phone Number"
                      type="text"
                      className={
                        !validForm.phoneNumber && isTouched
                            ? defaultInputInvalidStyle
                            : defaultInputStyle
                      }
                      disabled={isInitLoading}
                      value={driverForm.phoneNumber || ""}
                      onChange={(e) => handlerDriverValue(e, "phoneNumber")}
                  />
              )}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <SubmitButton onClick={submitHandler} />
        </div>
      </div>
  );
}

export default QuickAddDriver;

