// productActions.js

import axios from "axios";
import { setAllProducts } from "../store/productSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createProduct = (productForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedProductForm = { ...productForm, company: company };

    const response = await axios.post(
      `${base_url}/api/products`,
      updatedProductForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Product created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllProducts(response.data));
  } catch (error) {
    console.error("Error creating product:", error);
  }
};

export const fetchProductsSuccess = (data) => ({
  type: "FETCH_PRODUCTS_SUCCESS",
  payload: data,
});

export const fetchProductsFailure = (error) => ({
  type: "FETCH_PRODUCTS_FAILURE",
  payload: error,
});

export const fetchProducts = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/products/company/${currentCompany}`,
      config,
    );
    dispatch(setAllProducts(response.data));
  } catch (error) {
    console.error("Error fetching products:", error);
    // Handle error if needed
  }
};
export const deleteProductSuccess = (productId) => ({
  type: "DELETE_PRODUCT_SUCCESS",
  payload: productId,
});

export const deleteProductFailure = (error) => ({
  type: "DELETE_PRODUCT_FAILURE",
  payload: error,
});

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/products/${productId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteProductSuccess(productId));
  } catch (error) {
    console.error("Error deleting product:", error);
    dispatch(deleteProductFailure(error.message));
  }
};
