import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Menu, MenuItem, MenuButton} from "@szhsin/react-menu";
import {
    defaultTdStyle,
    defaultTdActionStyle,
    defaultTdWrapperStyle,
    defaultTdContent,
    defaultTdContentTitleStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import {useAppContext} from "../../context/AppContext";
import EmptyBar from "../Common/EmptyBar";
import {getUsers} from "../../store/companySlice";
import {fetchAndSetUsersByCompany} from "../../actions/companyActions";
import {getCurrentCompanyId} from "../../utils/storage";
import EmployeeProfileModal from "./EmployeeProfileModal";

// Example items, to simulate fetching from another resources.
const itemsPerPage = 5;

function EmployeeTable() {
    const {initLoading} = useAppContext();
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const dispatch = useDispatch();
    const companyId = getCurrentCompanyId();
    const users = useSelector(getUsers);

    useEffect(() => {
        if (companyId) {
            dispatch(fetchAndSetUsersByCompany(companyId));
        }
    }, [dispatch, companyId]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % users.length;
        setItemOffset(newOffset);
    };
    const handleView = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
    };

    return (<>
        <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
            <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
                <div className="sm:text-left text-default-color font-title flex-1">
                    First
                </div>
                <div className="sm:text-left text-default-color font-title flex-1">
                    Last
                </div>
                <div className="sm:text-left text-default-color font-title flex-1">
                    Email
                </div>
                <div className="sm:text-left text-default-color font-title sm:w-11">
                    Action
                </div>
            </div>

            <div>
                {users && users.map((user) => (<div className={defaultTdWrapperStyle} key={user._id}>
                    <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Name</div>
                        <div className={defaultTdContent}>
                            <span className="whitespace-nowrap text-ellipsis overflow-hidden pl-1">
                      {user.firstName}
                    </span>
                        </div>
                    </div>
                    <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Mobile</div>
                        <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {user.lastName}
                    </span>
                        </div>
                    </div>
                    <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Email</div>
                        <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {user.email}{" "}
                    </span>
                        </div>
                    </div>
                    <div className={defaultTdActionStyle}>
                        <div className={defaultTdContentTitleStyle}>Action</div>
                        <div className={defaultTdContent}>
                            <Menu
                                menuButton={<MenuButton>
                                    <div className="bg-gray-50 px-2 rounded-xl">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-blue-400"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                            />
                                        </svg>
                                    </div>
                                </MenuButton>}
                                transition
                            >
                                <MenuItem onClick={() => handleView(user)}>
                                    View
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>))}

                {users.length <= 0 && !initLoading && (<EmptyBar title="Employee Data"/>)}

                {users.length > 0 && (<ReactPaginate
                    className="inline-flex items-center -space-x-px mt-2"
                    previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="<"
                    nextLabel={">"}
                    renderOnZeroPageCount={null}
                />)}
            </div>
        </div>
        <EmployeeProfileModal
            open={isModalOpen}
            onClose={handleModalClose}
            user={selectedUser}
        />
    </>);
}

export default EmployeeTable;