import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openTrailerSelector: false,
  selectedTrailer: null,
  data: [],
  newForm: {
    id: nanoid(),
    trailerID: "",
    type: "",
    trailerMake: "",
    licensePlate: "",
    company: null
  },
  editedID: null,
  deletedID: null,
};

export const trailersSlice = createSlice({
  name: "trailers",
  initialState,
  reducers: {
    addNewTrailer: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        trailerID: "",
        type: "",
        trailerMake: "",
        licensePlate: "",
        company: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewTrailerForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewTrailerFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllTrailers: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedTrailer: (state) => {
      state.data = state.data.filter(
        (trailer) => trailer.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditTrailer: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (trailer) => trailer.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenTrailerSelector: (state, action) => {
      state.openTrailerSelector = action.payload;
      if (!action.payload) {
        state.selectedTrailer = null;
      }
    },

    setTrailerSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (trailer) => trailer.id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedTrailer = state.data[isFindIndex];
      }
    },
    onDeleteTrailerSuccess: (state, action) => {
      const trailerId = action.payload;
      state.data = state.data.filter((trailer) => trailer.id !== trailerId);
    },

    onDeleteTrailerFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewTrailer,
  updateNewTrailerForm,
  updateNewTrailerFormField,
  setAllTrailers,
  setDeleteId,
  setEditedId,
  onConfirmDeletedTrailer,
  onConfirmEditTrailer,
  setOpenTrailerSelector,
  setTrailerSelector,
  onDeleteTrailerSuccess,
  onDeleteTrailerFailure,
} = trailersSlice.actions;

export const getAllTrailersSelector = (state) => state.trailers.data;

export const getTrailerNewForm = (state) => state.trailers.newForm;

export const getDeletedTrailerForm = (state) => state.trailers.deletedID;

export const getEditedIdForm = (state) => state.trailers.editedID;

export const getIsOpenTrailerSelector = (state) =>
  state.trailers.openTrailerSelector;

export const getSelectedTrailer = (state) => state.trailers.selectedTrailer;

export default trailersSlice.reducer;
