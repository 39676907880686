import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTractorsSelector,
  setDeleteId,
  setEditedId,
} from "../../store/tractorSlice";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
  defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import PinIcon from '@mui/icons-material/Pin';
import EmptyBar from "../Common/EmptyBar";
import { useAppContext } from "../../context/AppContext";
import { fetchTractors } from "../../actions/tractorActions";
import { fetchDrivers } from '../../actions/driverActions';

const itemsPerPage = 10;
const emptySearchForm = {
  tractorID: "",
  licensePlate: "",
  tractorMake: "",
};

function TractorTable({ showAdvanceSearch = false }) {
  const { initLoading } = useAppContext();
  const dispatch = useDispatch();
  const allTractors = useSelector(getAllTractorsSelector);

  const [searchForm, setSearchForm] = useState(emptySearchForm);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [newTractorCreated, setNewTractorCreated] = useState(false);

  const tractors = useMemo(() => {
    let filterData = allTractors.length > 0 ? [...allTractors].reverse() : [];
    if (searchForm.licensePlate?.trim()) {
      filterData = filterData.filter((tractor) =>
      tractor.licensePlate.includes(searchForm.licensePlate)
      );
    }

    if (searchForm.tractorID) {
      filterData = filterData.filter((tractor) =>{
      var str = tractor.tractorID + "";
      return str.includes(searchForm.tractorID);}
      );
    }

    if (searchForm.tractorMake?.trim()) {
        filterData = filterData.filter((tractor) =>
        tractor.tractorMake.includes(searchForm.tractorMake)
        );
    }
  
    return filterData;
  }, [allTractors, searchForm]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tractors.length;
    setItemOffset(newOffset);
  };

  const handleDelete = (tractor) => {
    dispatch(setDeleteId(tractor._id));
  };

  const handleEdit = useCallback(
    (tractor) => {
      dispatch(setEditedId(tractor._id));
    },
    [dispatch]
  );

  const handlerSearchValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setSearchForm((prev) => {
      return { ...prev, [keyName]: value };
    });

    setItemOffset(0);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        
        dispatch(fetchTractors());
        dispatch(fetchDrivers());   
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    setNewTractorCreated(false);
  }, [dispatch, newTractorCreated]); 

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tractors.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tractors.length / itemsPerPage));
  }, [tractors, itemOffset]);

  return (
    <>
      {showAdvanceSearch === true && (
        <div className="bg-white rounded-xl px-3 py-3 mb-3">
          <div className="font-title mb-2">Advanced Search</div>
          <div className="flex w-full flex-col sm:flex-row">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
              <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-gray-400">
                <LocalShippingIcon />
              </div>
              <input
                autoComplete="nope"
                value={searchForm.tractorID}
                placeholder="Tractor Number"
                className={defaultSearchStyle}
                onChange={(e) => handlerSearchValue(e, "tractorID")}
              />
            </div>
            
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-gray-400">
                <FormatColorTextIcon />
              </div>
              <input
                autoComplete="nope"
                value={searchForm.tractorMake}
                placeholder="Tractor Make"
                className={defaultSearchStyle}
                onChange={(e) => handlerSearchValue(e, "tractorMake")}
              />
            </div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
              <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center text-gray-400">
                <PinIcon />
              </div>
              <input
                autoComplete="nope"
                value={searchForm.licensePlate}
                placeholder="License Plate"
                className={defaultSearchStyle}
                onChange={(e) => handlerSearchValue(e, "licensePlate")}
              />
            </div>
          </div>
        </div>
      )}

      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
          <div className="sm:text-left text-default-color font-title flex-1">
            TractorID
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Make
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            License Plate
          </div>
          <div className="sm:text-left text-default-color font-title sm:w-11">
            Action
          </div>
        </div>

        <div>
          {currentItems &&
            currentItems.map((tractor) => (
              <div className={defaultTdWrapperStyle} key={tractor._id}>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>TractorID</div>
                  <div className={defaultTdContent}>
                    {tractor.image ? (
                      <img
                        className="object-cover h-10 w-10 rounded-2xl"
                        src={tractor.image}
                        alt={tractor.name}
                      />
                    ) : (
                      <span className="h-10 w-10 rounded-2xl bg-gray-100 flex justify-center items-center">
                        <LocalShippingIcon />
                      </span>
                    )}
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden pl-1">
                      {tractor.tractorID || "#"}
                    </span>
                  </div>
                </div>

                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Make</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {tractor.tractorMake}
                    </span>
                  </div>
                </div>

                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>License Plate</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {tractor.licensePlate}
                    </span>
                  </div>
                </div>

                <div className={defaultTdActionStyle}>
                  <div className={defaultTdContentTitleStyle}>Action</div>
                  <div className={defaultTdContent}>
                    <Menu
                      menuButton={
                        <MenuButton>
                          <div className="bg-gray-50 px-2 rounded-xl">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </div>
                        </MenuButton>
                      }
                      transition
                    >
                      <MenuItem onClick={() => handleEdit(tractor)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(tractor)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))}

          {tractors.length <= 0 && !initLoading && <EmptyBar />}

          {tractors.length > 0 && (
            <ReactPaginate
              className="inline-flex items-center -space-x-px mt-2"
              previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              breakLabel="..."
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="<"
              nextLabel={">"}
              renderOnZeroPageCount={null}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TractorTable;
