import React from "react";
import PageTitle from "../../components/Common/PageTitle";
import DriverTable from "../../components/Driver/DriverTable";
import QuickAddDriver from "../../components/Driver/QuickAddDriver";

function DriverListScreen() {
  return (
    <div>
      <div className="p-4">
        <PageTitle title="Drivers" />
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/6 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1">
          <DriverTable showAdvanceSearch />
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
          <QuickAddDriver />
        </div>
      </div>
    </div>
  );
}

export default DriverListScreen;
