import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCompaniesSelector,
  setDeleteId,
  setEditedId,
  getDeletedCompanyForm, setCompanySelector, setDefaultCompany,
} from "../../store/companySlice";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
} from "../../constants/defaultStyles";
import { useAppContext } from "../../context/AppContext";
import EmptyBar from "../Common/EmptyBar";
import {fetchAndSetUsersByCompany, fetchCompanies} from "../../actions/companyActions";
import {setCurrentCompany} from "../../store/userSlice";
import {getCurrentCompanyId, setCurrentCompanyId} from "../../utils/storage";
import CheckCircleIcon from "../../components/Icons/CheckCircleIcon"
import EditIcon from "../../components/Icons/PencilIcon"
import DeleteIcon from "../../components/Icons/DeleteIcon"
import { toast } from "react-toastify";

const itemsPerPage = 10;

function CompanyTable() {
  const { initLoading } = useAppContext();

  const [itemOffset, setItemOffset] = useState(0);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const dispatch = useDispatch();
  const deletedID = useSelector(getDeletedCompanyForm);
  const allCompanies = useSelector(getAllCompaniesSelector || []);
  const companyId = getCurrentCompanyId();

  useEffect(() => {
    if (companyId) {
      setCurrentCompany({id:companyId});
      setCurrentCompanyId(companyId);
    }
  }, [companyId]);

  const companies = useMemo(() => {
    if (!Array.isArray(allCompanies)) {
      console.error('allCompanies is not an array:', allCompanies);
      return [];
    }
    const endOffset = itemOffset + itemsPerPage;
    return allCompanies.slice(itemOffset, endOffset);
  }, [allCompanies, itemOffset]);

  const handleDelete = (company) => {
    dispatch(setDeleteId(company._id));
    setIsDeleteConfirmOpen(true);
  };

  const handleEdit = useCallback(
    (item) => {
      dispatch(setEditedId(item._id));
    },
    [dispatch],
  );

  const handleSelect = useCallback(
      (company) => {
        dispatch(setCompanySelector(company));
        dispatch(setCurrentCompany(company)); // user current company
        dispatch(setDefaultCompany(company)); // companyslice current company
        dispatch(fetchAndSetUsersByCompany(company._id));
        setCurrentCompanyId(company._id); // local storage current company

        toast.success(`data loaded for ${company.companyName}`, {
        position: "bottom-center",
        autoClose: 2000,
      });
      },
      [dispatch]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCompanies());
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchData();
  }, [dispatch, deletedID]);

  if (!Array.isArray(allCompanies)) {
    return <div>Loading companies...</div>;
  }

  return (
    <>
      <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
        <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
          <div className="sm:text-left text-default-color font-title flex-1">
            Name
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Billing Address
          </div>
          <div className="sm:text-left text-default-color font-title flex-1">
            Country
          </div>
          <div className="sm:text-left text-default-color font-title sm:w-11">
            Action
          </div>
        </div>

        <div>
          {companies.length > 0 ? (
            companies.map((company) => (
              <div
                className={`${defaultTdWrapperStyle} ${
                  companyId && companyId === company._id ? 'bg-green-100 sm:bg-green-100' : ''
                }`}
                key={company._id}
              >
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Name</div>
                  <div className={defaultTdContent}>
                    {company.image ? (
                      <img
                        className="object-cover h-10 w-10 rounded-2xl"
                        src={company.image}
                        alt={company.name}
                      />
                    ) : (
                      <span className="h-10 w-10 rounded-2xl bg-gray-100 flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    )}

                    <span className="whitespace-nowrap text-ellipsis overflow-hidden pl-1">
                      {company.companyName}
                    </span>
                  </div>
                </div>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Address</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {company.billingAddress}
                    </span>
                  </div>
                </div>
                <div className={defaultTdStyle}>
                  <div className={defaultTdContentTitleStyle}>Country</div>
                  <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {company.country}{" "}
                    </span>
                  </div>
                </div>
                <div className={defaultTdActionStyle}>
                  <div className={defaultTdContentTitleStyle}>Action</div>
                  <div className={defaultTdContent}>
                    <Menu
                      menuButton={
                        <MenuButton>
                          <div className="bg-gray-50 px-2 rounded-xl">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-blue-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </div>
                        </MenuButton>
                      }
                      transition
                    >
                      <MenuItem onClick={() => handleSelect(company)}>
                       <CheckCircleIcon/> 
                       Select
                      </MenuItem>
                      <MenuItem onClick={() => handleEdit(company)}>
                      <EditIcon />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(company)}>
                      <DeleteIcon />
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            ))
          ) : !initLoading ? (
              <EmptyBar title="Company Data" />
          ) : null
          }
        </div>
      </div>
    </>
  );
}

export default CompanyTable;
