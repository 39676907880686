import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import NumberFormat from "react-number-format";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from "recharts";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import { People, BarChart as BarChartIcon, ShowChart } from "@mui/icons-material";
import {PieChartOutline} from "@mui/icons-material";
import {
  fetchDashboardAnalytics,
  fetchInvoiceTrends,
  fetchRevenueBreakdown,
  fetchRevenueByCompany
} from "../../actions/invoiceActions";


const DashboardWidgets = () => {
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState(null);
  const [revenueBreakdown, setRevenueBreakdown] = useState([]);
  const [invoiceTrends, setInvoiceTrends] = useState([]);
  const [revenueByCompany, setRevenueByCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [analyticsData, breakdownData, trendsData, revenueData] = await Promise.all([
        dispatch(fetchDashboardAnalytics()),
        dispatch(fetchRevenueBreakdown()),
        dispatch(fetchInvoiceTrends()),
        dispatch(fetchRevenueByCompany())
      ]);

      setAnalytics(analyticsData);
      setRevenueBreakdown(breakdownData);
      setInvoiceTrends(trendsData);
      setRevenueByCompany(revenueData);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError("Failed to load dashboard data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const pieData = revenueBreakdown.map(item => ({
    name: item._id,
    value: item.value,
    color: item._id === "Draft" ? "#808080" : item._id === "Processed" ? "#ee9d12" : "#008000"
  }));

  const lineData = invoiceTrends.map(item => ({
    date: item._id,
    amount: item.amount
  }));

  const barData = revenueByCompany.map(item => ({
    name: item._id,
    revenue: item.revenue
  }));

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) {
      return current === 0 ? 0 : Infinity;
    }
    return ((current - previous) / previous) * 100;
  };

  const percentageChangeToday = analytics ? calculatePercentageChange(analytics.invoicesToday, analytics.invoicesYesterday) : 0;
  const percentageChangeMonth = analytics ? calculatePercentageChange(analytics.invoicesThisMonth, analytics.invoicesLastMonth) : 0;

  if (loading) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
    );
  }

  if (error) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography color="error">{error}</Typography>
        </Box>
    );
  }

  return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full mb-4 md:w-1/2">
            <div className="flex flex-row">
              <div className="p-4 h-full rounded-xl md:mr-4 hover:shadow-sm w-1/2">
                <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
                  <div className="text-md text-center font-bold mb-2">Daily Invoice Snapshot</div>
                  <div className="text-md text-center">Total invoices today</div>
                  <div className="text-2xl font-bold text-center">
                    <NumberFormat
                        value={analytics?.invoicesToday}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value, props) => <span {...props}>{value}</span>}
                    />
                  </div>
                  <div className={`text-xl font-bold text-center ${percentageChangeToday >= 0 ? "text-green-500" : "text-red-500"}`}>
                    {isFinite(percentageChangeToday) ? `${percentageChangeToday.toFixed(2)}%` : "N/A"}
                  </div>
                  <div className="text-sm text-center">change from yesterday</div>
                  <div className="text-xs text-center mt-2">This metric helps track daily business activity and cash flow.</div>
                </Box>
              </div>
              <div className="p-4 rounded-xl md:mr-4 hover:shadow-sm w-1/2 h-full">
                <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
                  <div className="text-md text-center font-bold mb-2">Monthly Invoice Progress</div>
                  <div className="text-md text-center">Invoices Completed this month</div>
                  <div className="text-2xl font-bold text-center">
                    <NumberFormat
                        value={analytics.invoicesThisMonth}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value, props) => <span {...props}>{value}</span>}
                    />
                  </div>
                  <div className={`text-xl font-bold text-center ${percentageChangeMonth >= 0 ? "text-green-500" : "text-red-500"}`}>
                    {isFinite(percentageChangeMonth) ? `${percentageChangeMonth.toFixed(2)}%` : "N/A"}
                  </div>
                  <div className="text-sm text-center">change from last month</div>
                  <div className="text-xs text-center mt-2">This comparison helps identify monthly trends in business volume.</div>
                </Box>
              </div>
            </div>
          </div>
          <div className="w-full mb-3 md:w-1/2">
            <div className="p-4 bg-white rounded-xl hover:shadow-sm">
              <div className="font-title font-bold">Customer Base Overview <People className="inline-block mb-1" /></div>
              <div className="flex justify-between items-center">
                <div className="h-30">
                  <People className="w-30 h-30" />
                </div>
                <div className="text-2xl mr-2">
                  <NumberFormat
                      value={analytics.customerCount}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => <span {...props}>{value}</span>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[50%] h-full">
            <div className="p-4 rounded-xl md:mr-4 hover:shadow-sm">
              <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
                <div className="text-xs">EXPECTED</div>
                <div className="font-2xl font-bold">Revenue Breakdown <PieChartOutline className="inline-block mb-1" /></div>
                <div className="text-xl self-end mr-20">
                  <NumberFormat
                      value={Math.round(analytics.totalBalance).toFixed(2)}
                      className="text-green-700"
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => <span {...props}>{value}$</span>}
                  />
                </div>
                <div className="flex justify-between items-center">
                  {pieData.length > 0 && (
                      <ResponsiveContainer width="100%" height={250}>
                        <PieChart>
                          <Pie
                              data={pieData}
                              dataKey="value"
                              nameKey="name"
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              label
                              labelLine
                          >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                  )}
                </div>
                <div className="text-xs mt-2">This chart visualizes the distribution of revenue across different invoice statuses, helping to identify areas for cash flow improvement.</div>
              </Box>
            </div>
          </div>
          <div className="w-full mb-4 md:w-1/2">
            <div className="p-5 rounded-xl md:mr-4 hover:shadow-sm">
              <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
                <div className="font-title font-bold">Invoice Trend Analysis <ShowChart className="inline-block mb-1" /></div>
                <div className="flex justify-between items-center">
                  <div className="h-50 w-full">
                    {lineData.length > 0 && (
                        <ResponsiveContainer width="100%" height={250}>
                          <LineChart data={lineData}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="amount" stroke="#8884d8" />
                          </LineChart>
                        </ResponsiveContainer>
                    )}
                  </div>
                </div>
                <div className="text-xs mt-2">This graph shows the total invoice amount over time, helping to identify seasonal trends and overall business growth.</div>
              </Box>
            </div>
          </div>
          <div className="w-full mb-3 md:w-1/2 mt-10">
            <div className="p-4 rounded-xl hover:shadow-sm">
              <Box component={Paper} elevation={1} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
                <div className="font-title font-bold">Revenue by Company <BarChartIcon className="inline-block mb-1" /></div>
                <div className="flex justify-between items-center">
                  <div className="h-30 w-full">
                    {barData.length > 0 && (
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart data={barData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="revenue" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                    )}
                  </div>
                </div>
                <div className="text-xs mt-2">This chart displays revenue distribution across different companies, helping to identify key clients and potential areas for business development.</div>
              </Box>
            </div>
          </div>
        </div>
      </>
  );
};

export default DashboardWidgets;