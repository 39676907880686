import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import PageTitle from "../../components/Common/PageTitle";
import InvoiceIcon from "../../components/Icons/InvoiceIcon";
import InvoiceTable from "../../components/Invoice/InvoiceTable";

function InvoiceListScreen() {
  const navigate = useNavigate();

  const goToNewInvoice = useCallback(() => {
    navigate("/invoices/new");
  }, [navigate]);

  const handleBatchReport = useCallback(() => {
    navigate('/invoice-batch-report');
  }, [navigate]);

  return (
    <div>
      <div className="flex flex-col sm:flex-row flex-wrap p-4 w-40">
        <div className="sm:mr-4">
          <PageTitle title="Invoices"/>
        </div>
        <div className="flex flex-row">
        <div className="flex w-40">
          <Button onClick={goToNewInvoice} block={1} size="sm">
            <InvoiceIcon/>
            <span className="ml-2 w-40">New Invoice </span>
          </Button>
        </div>
        <div className="flex w-60 ml-4">
          <Button
              block={1} size="sm"
              onClick={handleBatchReport}
          >
            <InvoiceIcon/>
            <span className="ml-2 w-40">Create Batch Report </span>
          </Button>
        </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full px-4 mb-4 sm:mb-1">
          <InvoiceTable showAdvanceSearch/>
        </div>
      </div>
    </div>
  );
}

export default InvoiceListScreen;
