import React, {useState, useEffect} from "react";
import Skeleton from "react-loading-skeleton";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import SectionTitle from "../Common/SectionTitle";
import {useAppContext} from "../../context/AppContext";
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
    defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import {getTractorNewForm} from "../../store/tractorSlice";
import {fetchTractors, createTractor} from "../../actions/tractorActions";
import {getAllDriversSelector} from "../../store/driverSlice";
import SubmitButton from "../Button/SubmitButton";
import {Autocomplete, TextField} from "@mui/material";

const emptyForm = {
    tractorID: 0,
    tractorMake: "",
    tractorModel:"",
    licensePlate: "",
    assignedDriver: "",
    odometerReading: 0,
};

function QuickAddTractor() {
    const dispatch = useDispatch();
    const tractorNewForm = useSelector(getTractorNewForm);
    const {initLoading: isInitLoading} = useAppContext();
    const allDrivers = useSelector(getAllDriversSelector) || [];

    const [isTouched, setIsTouched] = useState(false);
    const [tractorForm, setTractorForm] = useState(emptyForm);
    const [validForm, setValidForm] = useState({
        id: false,
        tractorID: false,
        tractorMake: false,
        tractorModel: false,
        licensePlate: false,
        assignedDriver: false,
        odometerReading: false,
        user: false,
    });

    const handlerTractorValue = (event, keyName) => {
        const value = event.target.value;
        setTractorForm((prev) => ({
            ...prev,
            [keyName]: value,
            validForm: {...prev.validForm, [keyName]: !!value},
        }));
    };

    useEffect(() => {
        if (tractorNewForm) {
            const tractorData = {...tractorNewForm};
            setTractorForm(tractorData);
        }
    }, [tractorNewForm]);

    const handleSelectChange = (event) => {
        const driver = event.target.value;
        setTractorForm((prev) => ({
            ...prev,
            assignedDriver: driver,
        }));
        setValidForm((prev) => ({
            ...prev,
            assignedDriver: !!driver,
        }));
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setIsTouched(true);

        const isValid = Object.values(validForm).every(Boolean);

        if (!isValid) {
            toast.error("Invalid tractor Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        try {
            dispatch(createTractor(tractorForm));
            dispatch(fetchTractors());
            setTractorForm(emptyForm);
            setIsTouched(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(error.response.data.error, {
                    position: "bottom-center",
                    autoClose: 2000,
                });
            }
            console.error("Error saving tractor:", error);
        }
    };

    useEffect(() => {
        setValidForm({
            tractorID: tractorForm.tractorID > 0,
            tractorMake: !!tractorForm.tractorMake,
            tractorModel: !!tractorForm.tractorModel,
            licensePlate: !!tractorForm.licensePlate,
            assignedDriver: !!tractorForm.assignedDriver,
            odometerReading: tractorForm.odometerReading > 0,
        });
    }, [tractorForm]);

    return (
        <div className="bg-white rounded-xl p-4">
            <SectionTitle> Add Tractor </SectionTitle>
            <div className="mt-2">
                <div className="font-title text-sm text-default-color">Tractor ID</div>
                <div className="flex">
                    <div className="flex-1">
                        {isInitLoading ? (
                            <Skeleton className={defaultSkeletonNormalStyle}/>
                        ) : (
                            <input
                                autoComplete="nope"
                                placeholder="Tractor ID"
                                type="number"
                                min={0}
                                className={
                                    !validForm.tractorID && isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                disabled={isInitLoading}
                                value={tractorForm.tractorID || 0}
                                onChange={(e) => handlerTractorValue(e, "tractorID")}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <div className="font-title text-sm text-default-color">
                    Tractor Make
                </div>
                <div className="flex">
                    <div className="flex-1">
                        {isInitLoading ? (
                            <Skeleton className={defaultSkeletonNormalStyle}/>
                        ) : (
                            <input
                                autoComplete="nope"
                                placeholder="Tractor Make"
                                type="text"
                                className={
                                    !validForm.tractorMake && isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                disabled={isInitLoading}
                                value={tractorForm.tractorMake}
                                onChange={(e) => handlerTractorValue(e, "tractorMake")}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <div className="font-title text-sm text-default-color">
                    Tractor Model
                </div>
                <div className="flex">
                    <div className="flex-1">
                        {isInitLoading ? (
                            <Skeleton className={defaultSkeletonNormalStyle}/>
                        ) : (
                            <input
                                autoComplete="nope"
                                placeholder="Tractor Model"
                                type="text"
                                className={
                                    !validForm.tractorModel&& isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                disabled={isInitLoading}
                                value={tractorForm.tractorModel}
                                onChange={(e) => handlerTractorValue(e, "tractorModel")}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <div className="font-title text-sm text-default-color">
                    License Plate
                </div>
                <div className="flex">
                    <div className="flex-1">
                        {isInitLoading ? (
                            <Skeleton className={defaultSkeletonNormalStyle}/>
                        ) : (
                            <input
                                autoComplete="nope"
                                placeholder="License Plate"
                                type="text"
                                className={
                                    !validForm.licensePlate && isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                disabled={isInitLoading}
                                value={tractorForm.licensePlate}
                                onChange={(e) => handlerTractorValue(e, "licensePlate")}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-2">
                <div className="font-title text-sm text-default-color">
                    Odometer Reading
                </div>
                <div className="flex">
                    <div className="flex-1">
                        {isInitLoading ? (
                            <Skeleton className={defaultSkeletonNormalStyle}/>
                        ) : (
                            <input
                                autoComplete="nope"
                                placeholder="Odometer Reading"
                                type="number"
                                min={0}
                                className={
                                    !validForm.odometerReading && isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                disabled={isInitLoading}
                                value={tractorForm.odometerReading || 0}
                                onChange={(e) => handlerTractorValue(e, "odometerReading")}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-4">
                <Autocomplete
                    value={allDrivers.find(driver => driver._id === tractorForm.assignedDriver) || null}
                    onChange={(event, newValue) => {
                        handleSelectChange({
                            target: {value: newValue ? newValue._id : ''}
                        });
                    }}
                    options={allDrivers}
                    getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Assigned Driver"}
                            placeholder="Search for a driver"
                            className="border border-blue-300"
                            id="tractor-id"
                            name="TractorId"
                        />
                    )}
                    isOptionEqualToValue={(option, value) => value === "" || value === null ? false : option._id === value._id}
                />
            </div>

            <div className="mt-3">
                <SubmitButton onClick={submitHandler}/>
            </div>
        </div>
    );
}

export default QuickAddTractor;