import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    getAllEventsSelector,
    setDeleteId,
    setEditedId,
    getDeletedEventForm,
} from "../../store/eventSlice";
import {Menu, MenuItem, MenuButton} from "@szhsin/react-menu";
import {
    defaultTdStyle,
    defaultTdActionStyle,
    defaultTdWrapperStyle,
    defaultTdContent,
    defaultTdContentTitleStyle,
    defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import {useAppContext} from "../../context/AppContext";
import EmptyBar from "../Common/EmptyBar";
import {fetchEvents, updateEvent} from "../../actions/eventActions";
import {fetchDrivers} from '../../actions/driverActions';
import {fetchTractors} from '../../actions/tractorActions';
import {fetchParties} from '../../actions/partyActions';

// Example items, to simulate fetching from another resources.
const itemsPerPage = 10;
const emptySearchForm = {
    shipper: "",
    consignee: "",
    price: "",
    driver: ""
};

const statuses = [
    { index: "0", name: "Default", colorClass: "bg-gray-200 text-gray-600" },
    { index: "1", name: "Pending", colorClass: "bg-yellow-100 text-yellow-600" },
    { index: "2", name: "Dispatched", colorClass: "bg-blue-100 text-blue-600" },
    { index: "3", name: "EnRoute", colorClass: "bg-orange-100 text-orange-600" },
    { index: "4", name: "Delivered", colorClass: "bg-green-100 text-green-600" },
    { index: "5", name: "Cancelled", colorClass: "bg-red-100 text-red-600" },
];

function EventTable({showAdvanceSearch = false}) {
    const {initLoading} = useAppContext();

    const [searchForm, setSearchForm] = useState(emptySearchForm);
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const dispatch = useDispatch();
    const deletedID = useSelector(getDeletedEventForm);
    const allEvents = useSelector(getAllEventsSelector);

    const getStatusColor = (statusIndex) => {
        const status = statuses.find(s => s.index === statusIndex);
        return status ? status.colorClass : "bg-gray-200 text-gray-600";
    };

    const events = useMemo(() => {
        let filterData = allEvents.length > 0 ? [...allEvents].reverse() : [];
        if (searchForm.shipper?.trim()) {
            filterData = filterData.filter((event) =>
                event.shipper?.name.includes(searchForm.shipper),
            );
        }

        if (searchForm.consignee?.trim()) {
            filterData = filterData.filter((event) =>
                event.consignee?.name.includes(searchForm.consignee),
            );
        }

        if (searchForm.price?.trim()) {
            filterData = filterData.filter((event) => {
                var str = event.price + "";
                return str.includes(searchForm.price);
            });
        }

        if (searchForm.driver?.trim()) {
            filterData = filterData.filter((event) =>
                event.driver?.firstName.includes(searchForm.driver),
            );
        }

        return filterData;
    }, [allEvents, searchForm]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % events.length;
        setItemOffset(newOffset);
    };

    const handleDelete = (event) => {
        dispatch(setDeleteId(event._id));
        setIsDeleteConfirmOpen(true);
    };

    const handleEdit = useCallback(
        (item) => {
            dispatch(setEditedId(item._id));
        },
        [dispatch],
    );

    const handlerSearchValue = useCallback((event, keyName) => {
        const value = event.target.value;

        setSearchForm((prev) => {
            return {...prev, [keyName]: value};
        });

        setItemOffset(0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchEvents());
                await dispatch(fetchTractors());
                await dispatch(fetchDrivers());
                await dispatch(fetchParties());
            } catch (error) {
            }
        };

        fetchData();
    }, [dispatch, deletedID]);

    const handleIndexChange = (event, status) => {
        dispatch(updateEvent(event._id, {statusName: status.name, statusIndex: status.index}));
    };

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(events.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(events.length / itemsPerPage));
    }, [events, itemOffset]);

    return (
        <>
            {showAdvanceSearch === true && (
                <div className="bg-white rounded-xl px-3 py-3 mb-3">
                    <div className="font-title mb-2">Advanced Search</div>
                    <div className="flex w-full flex-col md:flex-row">
                        <div
                            className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                            <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <input
                                autoComplete="nope"
                                value={searchForm.driver}
                                placeholder="Driver"
                                className={defaultSearchStyle}
                                onChange={(e) => handlerSearchValue(e, "driver")}
                            />
                        </div>
                        <div
                            className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                            <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <input
                                autoComplete="nope"
                                value={searchForm.shipper}
                                placeholder="Shipper"
                                className={defaultSearchStyle}
                                onChange={(e) => handlerSearchValue(e, "shipper")}
                            />
                        </div>
                        <div
                            className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                            <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                            <input
                                autoComplete="nope"
                                value={searchForm.consignee}
                                placeholder="Consignee"
                                className={defaultSearchStyle}
                                onChange={(e) => handlerSearchValue(e, "consignee")}
                            />
                        </div>
                        <div
                            className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                            <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                            <input
                                autoComplete="nope"
                                value={searchForm.price}
                                placeholder="Price"
                                className={defaultSearchStyle}
                                onChange={(e) => handlerSearchValue(e, "price")}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
                <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
                    <div className="sm:text-left text-default-color font-title flex-1">
                        Status
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                        Shipper
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                        Consignee
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                        Price
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                        Driver
                    </div>
                    <div className="sm:text-left text-default-color font-title sm:w-11">
                        Action
                    </div>
                </div>

                <div>
                    {currentItems &&
                        currentItems.map((event) => (
                            <div className={defaultTdWrapperStyle} key={event._id}>
                                <div className={defaultTdStyle}>
                                    <div className={defaultTdContentTitleStyle}>Status</div>
                                    <div className={defaultTdContent}>
                                        <Menu
                                            menuButton={
                                                <MenuButton>
                                                    <span
                                                        className={`whitespace-nowrap text-ellipsis overflow-hidden px-3 rounded-xl py-1 ${getStatusColor(event.statusIndex)}`}>
                                                        {event.statusName}
                                                    </span>
                                                </MenuButton>
                                            }
                                            transition
                                        >
                                            {statuses.map((status) => (
                                                <MenuItem
                                                    key={status.index}
                                                    onClick={() => handleIndexChange(event, status)}
                                                    className={status.colorClass}
                                                >
                                                    {status.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                                <div className={defaultTdStyle}>
                                    <div className={defaultTdContentTitleStyle}>Shipper</div>
                                    <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden pl-1">
                      {event.shipper?.name}
                    </span>
                                    </div>
                                </div>
                                <div className={defaultTdStyle}>
                                    <div className={defaultTdContentTitleStyle}>Consignee</div>
                                    <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {event.consignee?.name}
                    </span>
                                    </div>
                                </div>
                                <div className={defaultTdStyle}>
                                    <div className={defaultTdContentTitleStyle}>Price</div>
                                    <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {"$"}{event.price}
                    </span>
                                    </div>
                                </div>
                                <div className={defaultTdStyle}>
                                    <div className={defaultTdContentTitleStyle}>Driver</div>
                                    <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {event.driver?.firstName} {event.driver?.lastName}
                    </span>
                                    </div>
                                </div>
                                <div className={defaultTdActionStyle}>
                                    <div className={defaultTdContentTitleStyle}>Action</div>
                                    <div className={defaultTdContent}>
                                        <Menu
                                            menuButton={
                                                <MenuButton>
                                                    <div className="bg-gray-50 px-2 rounded-xl">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-6 w-6 text-blue-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </MenuButton>
                                            }
                                            transition
                                        >
                                            <MenuItem onClick={() => handleEdit(event)}>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => handleDelete(event)}>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        ))}

                    {events.length <= 0 && !initLoading&& (
                        <EmptyBar title="Event Data"/>
                    )}

                    {events.length > 0 && (
                        <ReactPaginate
                            className="inline-flex items-center -space-x-px mt-2"
                            previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            breakLabel="..."
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="<"
                            nextLabel={">"}
                            renderOnZeroPageCount={null}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default EventTable;
