// customerActions.js

import axios from "axios";
import { setAllCustomers } from "../store/customerSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

// Action to create a new customer
export const createCustomer = (customerForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedCustomerForm = { ...customerForm, company: company};

    const response = await axios.post(
      `${base_url}/api/customers`,
      updatedCustomerForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Customer created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
    dispatch(setAllCustomers(response.data));
  } catch (error) {
    console.error("Error creating customer:", error);
  }
};
export const fetchCustomersSuccess = (data) => ({
  type: "FETCH_CLIENTS_SUCCESS",
  payload: data,
});

export const fetchCustomersFailure = (error) => ({
  type: "FETCH_CLIENTS_FAILURE",
  payload: error,
});

export const fetchCustomers = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {

      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/customers/company/${currentCompany}`,
      config,
    );
    dispatch(setAllCustomers(response.data));
  } catch (error) {
    console.error("Error fetching customers:", error);
    // Handle error if needed
  }
};

export const deleteCustomerSuccess = (customerId) => ({
  type: "DELETE_CLIENT_SUCCESS",
  payload: customerId,
});

export const deleteCustomerFailure = (error) => ({
  type: "DELETE_CLIENT_FAILURE",
  payload: error,
});

export const deleteCustomer = (customerId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/customers/${customerId}`, {
      withCredentials: true,
    });
    dispatch(deleteCustomerSuccess(customerId));
  } catch (error) {
    console.error("Error deleting customer:", error);
    dispatch(deleteCustomerFailure(error.message));
  }
};

export const saveCustomerSuccess = (customer) => ({
  type: "SAVE_CUSTOMER_SUCCESS",
  payload: customer,
});

export const saveCustomerFailure = (error) => ({
  type: "SAVE_CUSTOMER_FAILURE",
  payload: error,
});

export const updateCustomer= (customerId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/customers/${customerId}`, data, config);
    dispatch(fetchCustomers());
  } catch (error) {
    console.error("Error updating Customer:", error);
    dispatch(saveCustomerFailure(error.message));
  }
};