import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import ImageUpload from "../Common/ImageUpload";
import SectionTitle from "../Common/SectionTitle";
import { useAppContext } from "../../context/AppContext";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";

import { useDispatch } from "react-redux";
import { fetchCustomers, createCustomer } from "../../actions/customerActions";
import SubmitButton from "../Button/SubmitButton";

const emptyForm = {
  image: "",
  name: "",
  email: "",
  billingAddress: "",
  mobileNo: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function QuickAddCustomer() {
  const [customerForm, setCustomerForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const { initLoading: isInitLoading } = useAppContext();
  const dispatch = useDispatch();

  const [validForm, setValidForm] = useState({
    name: false,
    email: false,
    billingAddress: false,
    mobileNo: false,
  });

  const onChangeImage = (str) => {
    setCustomerForm((prev) => ({ ...prev, image: str }));
  };

  const handlerCustomerValue = (event, keyName) => {
    const value = event.target.value;
    setCustomerForm((prev) => ({
      ...prev,
      [keyName]: value,
    }));
  };

  const submitHandler = async () => {
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid Customer Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      dispatch(createCustomer(customerForm));
      dispatch(fetchCustomers());

      setCustomerForm(emptyForm);
      setIsTouched(false);
    } catch (error) {
      console.error("Error saving customer:", error);
      toast.error("An error occurred. Please try again.", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const imageUploadClasses = customerForm.image
  ? "rounded-xl "
  : "rounded-xl border-dashed border-2 border-indigo-400";

  useEffect(() => {
    const isValidEmail = customerForm?.email?.trim() && customerForm?.email.match(emailRegex);
    setValidForm({
      name: customerForm.name? customerForm.name.trim() !== "" : false,
      email: isValidEmail ? true : false,
      billingAddress: customerForm.billingAddress? customerForm.billingAddress.trim() !== "" : false,
      mobileNo: customerForm.mobileNo? customerForm.mobileNo.trim() !== "" : false,
    });
  }, [customerForm]);


  return (
    <div className="bg-white rounded-xl p-4">
      <SectionTitle> Add Customer </SectionTitle>
      <div className="flex mt-2">
        {isInitLoading ? (
          <Skeleton className="skeleton-input-radius skeleton-image border-dashed border-2" />
        ) : (
          <ImageUpload
            keyName="QuickEditImageUpload"
            className={imageUploadClasses}
            url={customerForm.image}
            onChangeImage={onChangeImage}
          />
        )}

        <div className="flex-1 pl-3">
          {isInitLoading ? (
            <Skeleton className="skeleton-input-radius skeleton-image border-dashed border-2" />
          ) : (
            <div>
              <input
                autoComplete="nope"
                value={customerForm.name}
                placeholder="Customer Name"
                className={
                  !validForm.name && isTouched
                      ? defaultInputInvalidStyle
                      : defaultInputStyle
                }
                onChange={(e) => handlerCustomerValue(e, "name")}
                disabled={isInitLoading}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-1">
          {isInitLoading ? (
            <Skeleton className={defaultSkeletonNormalStyle} />
          ) : (
            <input
              autoComplete="nope"
              placeholder="Email Address"
              className={
                !validForm.email && isTouched
                  ? defaultInputInvalidStyle
                  : defaultInputStyle
              }
              disabled={isInitLoading}
              value={customerForm.email}
              onChange={(e) => handlerCustomerValue(e, "email")}
            />
          )}
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-1">
          {isInitLoading ? (
            <Skeleton className={defaultSkeletonNormalStyle} />
          ) : (
            <input
              autoComplete="nope"
              placeholder="Mobile No"
              className={
                !validForm.mobileNo && isTouched
                  ? defaultInputInvalidStyle
                  : defaultInputStyle
              }
              disabled={isInitLoading}
              value={customerForm.mobileNo}
              onChange={(e) => handlerCustomerValue(e, "mobileNo")}
            />
          )}
        </div>
      </div>
      <div className="flex mt-2">
        <div className="flex-1">
          {isInitLoading ? (
            <Skeleton className={defaultSkeletonNormalStyle} />
          ) : (
            <input
              autoComplete="nope"
              placeholder="Billing Address"
              className={
                !validForm.billingAddress && isTouched
                  ? defaultInputInvalidStyle
                  : defaultInputStyle
              }
              disabled={isInitLoading}
              value={customerForm.billingAddress}
              onChange={(e) => handlerCustomerValue(e, "billingAddress")}
            />
          )}
        </div>
      </div>

      <div className="mt-3">
        <SubmitButton onClick={submitHandler} />
      </div>
    </div>
  );
}

export default QuickAddCustomer;
