import React, {useState, useRef, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generateBatchReport, getMonthlyReport} from '../../actions/invoiceActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import PageTitle from "../../components/Common/PageTitle";
import {useReactToPrint} from 'react-to-print';
import {useAppContext} from "../../context/AppContext";
import Button from "../../components/Button/Button";
import {getCurrentCompany} from "../../store/userSlice";
import {toast} from "react-toastify";
import EmptyBar from "../../components/Common/EmptyBar";
import InfoIcon from "../../components/Icons/InfoIcon";
import {CircularProgress} from "@mui/material";

const InvoiceBatchReportPage = () => {
    const dispatch = useDispatch();
    const {setEscapeOverflow} = useAppContext();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [report, setReport] = useState(null);
    const [isExporting, setIsExporting] = useState(false);
    const [hasReport, setHasReport] = useState(false);
    const componentRef = useRef(null);
    const currentCompany = useSelector(getCurrentCompany);
    const [isLoading, setIsLoading] = useState(false);

    const LoadingSpinner = () => (
        <div className="flex flex-col justify-center items-center">
            <CircularProgress size={80}/>
            <span className="text-blue-600 text-xl text-bold">
                Creating Report...
            </span>
        </div>
    );

    const handleGenerateReport = async () => {
        try {
            setIsLoading(true)
            setHasReport(true);
            const result = await dispatch(generateBatchReport(startDate.toISOString(), endDate.toISOString()));
            setReport(result);
        } catch (error) {
            console.error('Failed to generate report:', error);
            toast.error("sorry, an error has occurred. Please try again later ", {
                position: "bottom-center",
                autoClose: 2000,
            })
        } finally {
            setIsLoading(false)
        }
    };

    const handleMonthlyReport = async () => {
        try {
            setIsLoading(true)
            setHasReport(true);
            const result = await dispatch(getMonthlyReport(startDate.getFullYear(), startDate.getMonth() + 1));
            setReport(result);
        } catch (error) {
            console.error('Failed to generate monthly report:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Invoice Batch Report",
        onAfterPrint: useCallback(() => {
            setIsExporting(false);
            setEscapeOverflow(false);
        }, [setEscapeOverflow]),
        removeAfterPrint: true,
    });

    const handleExport = useCallback(() => {
        setEscapeOverflow(true);
        setIsExporting(true);
        setTimeout(() => {
            handlePrint();
        }, 1000);
    }, [handlePrint, setEscapeOverflow]);

    const ReportSummary = ({ report }) => {
        const invoiceCount = report.invoices.length;
        const totalCarrierCosts = report.invoices.reduce((sum, invoice) => sum + (invoice.carrierCost || 0), 0);
        const grossProfit = report.totalAmount;
        const netProfit = grossProfit - totalCarrierCosts;

        return (
            <div className="mt-5">
                <h4 className="text-md font-semibold mb-4">Report Summary</h4>
                <div className="grid grid-cols-4 gap-4">
                    <div className="bg-white p-4 rounded-lg shadow">
                        <p className="text-sm text-gray-600">Total Invoices</p>
                        <p className="text-sm font-bold text-blue-900">{invoiceCount}</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <p className="text-sm text-gray-600">Total Carrier Costs</p>
                        <NumberFormat
                            value={totalCarrierCosts}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => <p className="text-sm font-bold text-red-900">{value}</p>}
                        />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <p className="text-sm text-gray-600">Gross Profit</p>
                        <NumberFormat
                            value={grossProfit}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => <p className="text-sm font-bold text-green-900">{value}</p>}
                        />
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow">
                        <p className="text-sm text-gray-600">Net Profit</p>
                        <NumberFormat
                            value={netProfit}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={(value) => <p className="text-sm font-bold text-green-900">{value}</p>}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const CompanyHeader = () => (
        <div className="mb-8 text-center">
            <h2 className="text-2xl font-bold">{currentCompany?.companyName}</h2>
            <p>{currentCompany?.billingAddress}</p>
            <p>Phone: {currentCompany?.companyMobile}</p>
            <p>Email: {currentCompany?.companyEmail}</p>
        </div>
    );

    return (
        <>
            <div className="p-4">
                <PageTitle title="Invoice Batch Report"/>
                <div className="bg-white rounded-xl p-4 mt-4">
                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="border rounded p-2"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="border rounded p-2"
                        />
                        <InfoIcon message={"select a start date, and end date to get a report in that range. select monthly to get a report for the month of start date"} />
                        <Button
                            onClick={handleGenerateReport}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                        >
                            Generate Custom Report
                        </Button>
                        <Button
                            onClick={handleMonthlyReport}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                        >
                            Generate Monthly Report
                        </Button>
                        {report && (
                            <Button
                                onClick={handleExport}
                                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition duration-300"
                            >
                                Export Report
                            </Button>
                        )}
                    </div>

                    {hasReport ? (

                        <div ref={componentRef}>
                            {isExporting && <CompanyHeader/>}
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : report ? (
                                <div className={`mt-8 ${isExporting ? 'bg-white px-8 py-6' : ''}`}>
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
                                        <div className="bg-gray-100 p-4 rounded">
                                            <p className="text-sm text-gray-600">Start Date</p>
                                            <p className="font-semibold">{new Date(report.startDate).toLocaleDateString()}</p>
                                        </div>
                                        <div className="bg-gray-100 p-4 rounded">
                                            <p className="text-sm text-gray-600">End Date</p>
                                            <p className="font-semibold">{new Date(report.endDate).toLocaleDateString()}</p>
                                        </div>
                                        <div className="bg-gray-100 p-4 rounded">
                                            <p className="text-sm text-gray-600">Total Amount</p>
                                            <p className="font-semibold">
                                                <NumberFormat
                                                    value={report.totalAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </p>
                                        </div>
                                    </div>

                                    <h4 className="text-lg font-semibold mt-8 mb-4">Invoices</h4>
                                    <div className="overflow-x-auto">
                                        <table className="w-full">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice
                                                    #
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {report.invoices.map((invoice) => (
                                                <tr key={invoice.invoiceNo}>
                                                    <td className="px-6 py-4 whitespace-nowrap">{invoice.invoiceNo}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{invoice.customerName}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right">
                                                        <NumberFormat
                                                            value={invoice.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                        />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right">{new Date(invoice.date).toLocaleDateString()}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ReportSummary report={report}/>
                                </div>
                            ) : (
                                <div className="text-center text-gray-600 mt-8">No data available for the selected date
                                    range.</div>
                            )}
                            {report && (
                                <div className={`mt-8 ${isExporting ? 'bg-white px-8 py-6' : ''}`}>

                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center h-full">
                            <EmptyBar title="Batch Reports"/>
                            <p className="mt-4 text-lg text-gray-600">
                                Create a <i>Batch Report</i> to view invoices over time, and export to a pdf!
                            </p>
                            <p className="mt-4 text-lg text-gray-600">
                                Make sure your invoices are <i><b>Invoiced</b></i> and then, Select "Custom Report" or
                                "Monthly Report" to get started!
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default InvoiceBatchReportPage;