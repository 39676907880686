/* eslint-disable no-useless-escape */
import React, {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {
    getAllTractorsSelector,
    getEditedIdForm,
    setEditedId,
    onConfirmEditTractor,
} from "../../store/tractorSlice";
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import {getAllDriversSelector} from "../../store/driverSlice";
import {updateTractor} from "../../actions/tractorActions";
import {Autocomplete, TextField} from "@mui/material";

const emptyForm = {
    tractorID: 0,
    tractorMake: "",
    tractorModel: "",
    licensePlate: "",
    assignedDriver: "",
    odometerReading: 0,
};

function TractorEditModal(props) {
    const dispatch = useDispatch();
    const editedID = useSelector(getEditedIdForm);
    const tractors = useSelector(getAllTractorsSelector);
    const allDrivers = useSelector(getAllDriversSelector);

    const [animate, setAnimate] = useState(true);
    const [tractorForm, setTractorForm] = useState({
        ...emptyForm,
    });
    const [isTouched, setIsTouched] = useState(false);
    const [validForm, setValidForm] = useState(
        Object.keys(emptyForm).reduce((a, b) => {
            return {...a, [b]: false};
        }, {})
    );

    const onEditHandler = useCallback(() => {
        setIsTouched(true);
        const isValid = Object.keys(validForm).every((key) => validForm[key]);

        if (!isValid || tractorForm.assignedDriver === '') {
            toast.error("Invalid Tractor Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        if (editedID) {
            dispatch(updateTractor(editedID, tractorForm))
                .then(() => {
                    toast.success("Successfully Updated Tractor", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                })
                .finally(() => {
                    dispatch(onConfirmEditTractor(tractorForm));
                    dispatch(setEditedId(null));
                    setIsTouched(false);
                });
        }

    }, [dispatch, validForm, tractorForm, editedID]);

    const handlerTractorValue = useCallback((event, keyName) => {
        const value = event.target.value;
        setTractorForm((prev) => ({...prev, [keyName]: value}));
    }, []);

    const handleSelectChange = (event, newValue) => {
        let driverId = '';
        if (typeof newValue === 'string') {
            // If it's a string, find the matching driver
            const matchedDriver = allDrivers.find(driver =>
                `${driver.firstName} ${driver.lastName}`.toLowerCase().includes(newValue.toLowerCase())
            );
            driverId = matchedDriver ? matchedDriver._id : '';
        } else if (newValue && newValue._id) {
            // If it's an object (selected from dropdown), use its ID
            driverId = newValue._id;
        }

        setTractorForm((prev) => ({
            ...prev,
            assignedDriver: driverId,
        }));
        setValidForm((prev) => ({
            ...prev,
            assignedDriver: !!driverId,
        }));
    };

    const onCancelHandler = useCallback(() => {
        dispatch(setEditedId(null));
    }, [dispatch]);

    useEffect(() => {
        setValidForm((prev) => ({
            ...prev,
            tractorID: tractorForm.tractorID > 0,
            tractorMake: !!tractorForm.tractorMake.trim(),
            tractorModel: !!tractorForm.tractorModel.trim(),
            licensePlate: !!tractorForm.licensePlate.trim(),
            assignedDriver: !!tractorForm.assignedDriver,
            odometerReading: tractorForm.odometerReading > 0,
        }));
    }, [tractorForm]);

    useEffect(() => {
        if (editedID !== null) {
            setAnimate(true);
            const editedTractor = tractors.find((tractor) => tractor._id === editedID);
            if (editedTractor) {
                setTractorForm({
                    ...editedTractor,
                    assignedDriver: editedTractor.assignedDriver || '',
                });
            }
        } else {
            setAnimate(false);
            setTractorForm({...emptyForm, assignedDriver: ''});
        }
    }, [tractors, editedID]);

    useEffect(() => {
        if (editedID && tractors.length > 0 && allDrivers.length > 0) {
            const editedTractor = tractors.find(tractor => tractor._id === editedID);
            if (editedTractor) {
                const assignedDriver = allDrivers.find(driver => driver._id === editedTractor.assignedDriver);
                if (assignedDriver) {
                    setTractorForm(prev => ({
                        ...prev,
                        assignedDriver: assignedDriver._id
                    }));
                }
            }
        }
    }, [editedID, tractors, allDrivers]);

    return editedID !== null ? (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{opacity: 0}}
            animate={{opacity: animate ? 1 : 0}}
            transition={{type: "spring", damping: 18}}
        >
            <div className="relative">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div
                            className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <h3
                                            className="text-lg leading-6 font-medium text-gray-900"
                                            id="modal-title"
                                        >
                                            Edit Tractor Form
                                        </h3>
                                        <div className="mt-2">
                                            <div className="bg-white rounded-xl mt-4">
                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">Tractor ID
                                                    </div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="number"
                                                        min={0}
                                                        placeholder="Tractor ID"
                                                        className={
                                                            !validForm.tractorID && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={tractorForm.tractorID || 0}
                                                        onChange={(e) => handlerTractorValue(e, "tractorID")}
                                                    />
                                                </div>

                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">Tractor
                                                        Make
                                                    </div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        placeholder="Tractor Make"
                                                        className={
                                                            !validForm.tractorMake && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={tractorForm.tractorMake || ""}
                                                        onChange={(e) => handlerTractorValue(e, "tractorMake")}
                                                    />
                                                </div>
                                                <div className="font-title text-sm text-default-color">Tractor
                                                    Model
                                                </div>

                                                <div className="mt-2">
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        placeholder="Tractor Model"
                                                        className={
                                                            !validForm.tractorModel&& isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={tractorForm.tractorModel|| ""}
                                                        onChange={(e) => handlerTractorValue(e, "tractorModel")}
                                                    />
                                                </div>

                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">License
                                                        Plate
                                                    </div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        placeholder="License Plate"
                                                        className={
                                                            !validForm.licensePlate && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={tractorForm.licensePlate || ""}
                                                        onChange={(e) => handlerTractorValue(e, "licensePlate")}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">Odometer
                                                        Reading
                                                    </div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="number"
                                                        min={0}
                                                        placeholder="Odometer Reading"
                                                        className={
                                                            !validForm.odometerReading && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={tractorForm.odometerReading || 0}
                                                        onChange={(e) => handlerTractorValue(e, "odometerReading")}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">Assigned
                                                        Driver
                                                    </div>
                                                    <Autocomplete
                                                        value={allDrivers.find(driver => driver._id === tractorForm.assignedDriver) || null}
                                                        onChange={handleSelectChange}
                                                        options={allDrivers}
                                                        getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Search for a driver"
                                                                className=""
                                                                id="edit-tractor-id"
                                                                name="EditTractorId"
                                                            />
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option && value && option._id === value._id}
                                                        freeSolo
                                                        autoSelect
                                                    />
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={onEditHandler}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onCancelHandler}
                                >
                                Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    ) : null;
}

export default TractorEditModal;

