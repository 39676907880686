import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openCustomerSelector: false,
  selectedCustomer: null,
  data: [],
  newForm: {
    id: nanoid(),
    image: "",
    name: "",
    email: "",
    billingAddress: "",
    mobileNo: "",
    user: null,
  },
  editedID: null,
  deletedID: null,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    addNewCustomer: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        image: "",
        name: "",
        email: "",
        billingAddress: "",
        mobileNo: "",
        user: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewCustomerForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewCustomerFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllCustomers: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedCustomer: (state) => {
      state.data = state.data.filter(
        (customer) => customer.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditCustomer: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (customer) => customer.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenCustomerSelector: (state, action) => {
      state.openCustomerSelector = action.payload;
      if (!action.payload) {
        state.selectedCustomer = null;
      }
    },

    setCustomerSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (customer) => customer._id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedCustomer = state.data[isFindIndex];
      }
    },
    onDeleteCustomerSuccess: (state, action) => {
      const customerId = action.payload;
      state.data = state.data.filter((customer) => customer.id !== customerId);
    },

    onDeleteCustomerFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewCustomer,
  updateNewCustomerForm,
  updateNewCustomerFormField,
  setAllCustomers,
  setDeleteId,
  setEditedId,
  onConfirmDeletedCustomer,
  onConfirmEditCustomer,
  setOpenCustomerSelector,
  setCustomerSelector,
  onDeleteCustomerSuccess,
  onDeleteCustomerFailure,
} = customersSlice.actions;

export const getAllCustomersSelector = (state) => state.customers.data;

export const getCustomerNewForm = (state) => state.customers.newForm;

export const getDeletedCustomerForm = (state) => state.customers.deletedID;

export const getEditedIdForm = (state) => state.customers.editedID;

export const getIsOpenCustomerSelector = (state) =>
  state.customers.openCustomerSelector;

export const getSelectedCustomer = (state) => state.customers.selectedCustomer;

export default customersSlice.reducer;
