import axios from "axios";
import { setAllConfirmation } from "../store/confirmationSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createConfirmation = (confirmationForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId()
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedConfirmationForm = { ...confirmationForm, companyId: company };

    const response = await axios.post(
      `${base_url}/api/confirmations`,
      updatedConfirmationForm,
      config,
    );
    if (response.status === 200) {
      toast.success("successfully created carrier confirmation!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
    dispatch(setAllConfirmations(response.data));
  } catch (error) {
    console.error("Error creating product:", error);
  }
};
export const setAllConfirmations = (confirmations) => ({
  type: "SET_ALL_INVOICES",
  payload: confirmations,
});

export const fetchConfirmationsFailure = (error) => ({
  type: "FETCH_INVOICES_FAILURE",
  payload: error,
});

export const fetchConfirmations = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();
    if (!currentCompany) {
      return;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/confirmations/company/${currentCompany}`,
      config,
    );
    dispatch(setAllConfirmation(response.data));
  } catch (error) {
    console.error("Error fetching confirmations:", error);
    // Handle error if needed
  }
};

export const deleteConfirmationSuccess = (confirmationId) => ({
  type: "DELETE_INVOICE_SUCCESS",
  payload: confirmationId,
});

export const deleteConfirmationFailure = (error) => ({
  type: "DELETE_INVOICE_FAILURE",
  payload: error,
});

export const deleteConfirmation = (confirmationId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/confirmations/${confirmationId}`, {
      withCredentials: true,
    });
    dispatch(deleteConfirmationSuccess(confirmationId));
    dispatch(fetchConfirmations());
  } catch (error) {
    console.error("Error deleting confirmation:", error);
    dispatch(deleteConfirmationFailure(error.message));
  }
};

export const updateConfirmationStatus = (confirmationId, statusName, statusIndex) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/confirmations/${confirmationId}`, { statusName, statusIndex}, config);
    dispatch(fetchConfirmations());
  } catch (error) {
    console.error("Error updating confirmation status:", error);
    dispatch(saveConfirmationFailure(error.message));
  }
};

export const saveConfirmationSuccess = (confirmation) => ({
  type: "SAVE_INVOICE_SUCCESS",
  payconfirmation: confirmation,
});

export const saveConfirmationFailure = (error) => ({
  type: "SAVE_INVOICE_FAILURE",
  payconfirmation: error,
});
