// companyActions.js
import axios from "axios";
import {
  setAllCompanies,
  setUsers,
  setLoading, setDefaultCompany,
} from "../store/companySlice";
import {toast} from "react-toastify";
import base_url from "../utils/config";
import {setCurrentCompany} from "../store/userSlice";

export const createCompany = (companyForm) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.post(
        `${base_url}/api/company`,
        companyForm,
        config,
    );
    if (response.status === 200) {
      toast.success("Company created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllCompanies(response.data));
  } catch (error) {
    console.error("Error creating product:", error);
  }
};
export const fetchCompaniesSuccess = (data) => ({
  type: "FETCH_COMPANIES_SUCCESS",
  payload: data,
});

export const fetchCompaniesFailure = (error) => ({
  type: "FETCH_COMPANIES_FAILURE",
  payload: error,
});

export const fetchCompanies = () => async (dispatch) => {
  try {
    const response = await axios.get(`${base_url}/api/company`, {
      withCredentials: true,
    });
    dispatch(setAllCompanies(response.data));
    dispatch(setCurrentCompany(response.data[0])); // user current
    dispatch(setDefaultCompany(response.data[0]));
  } catch (error) {
    // Handle error if needed
  }
};

export const fetchAndSetUsersByCompany = (companyId) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(
      `${base_url}/api/company/${companyId}/users`,
      {
        withCredentials: true,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    dispatch(setUsers({ companyId, users: response.data }));
    dispatch(setLoading(false));
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const updateCompany= (companyId, companyForm) => async (dispatch) => {
  try {
    await axios.put(`${base_url}/api/company/${companyId}`, companyForm, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(fetchCompanies());
  } catch (error) {
    console.error("Error updating Company:", error);
  }
};

export const fetchNextInvoiceNumber = (companyId) => async () => {
  try {
    const response = await axios.get(`${base_url}/api/company/next-invoice-number/${companyId}`,
        {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data.nextInvoiceNumber;
  } catch (error) {
    console.error('Error fetching next invoice number:', error);
    throw error;
  }
};

export const fetchNextConfirmationNumber = (companyId) => async () => {
  try {
    const response = await axios.get(`${base_url}/api/company/next-confirmation-number/${companyId}`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
    return response.data.nextConfirmationNumber;
  } catch (error) {
    console.error('Error fetching next invoice number:', error);
    throw error;
  }
};

export const deleteCompanySuccess = (companyId) => ({
  type: "DELETE_COMPANY_SUCCESS",
  payload: companyId,
});

export const onDeleteCompanyFailure = (error) => ({
  type: "DELETE_COMPANY_FAILURE",
  payload: error,
});

export const deleteCompany = (companyId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/company/${companyId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteCompanySuccess(companyId));
  } catch (error) {
    console.error("Error deleting company:", error);
    dispatch(onDeleteCompanyFailure(error.message));
  }
};
