import React, {useCallback, useEffect, useState, useRef, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import {useReactToPrint} from "react-to-print";
import {useDispatch, useSelector} from "react-redux";
import NumberFormat from "react-number-format";
import {toast} from "react-toastify";
import domtoimage from "dom-to-image";
import ConfirmationTopBar from "../../components/Confirmation/ConfirmationTopBar";
import {
    getCurrentBGImage,
    getCurrentColor,
    getConfirmationNewForm,
    getIsConfirm,
    setConfirmModalOpen,
    setDefaultBackground,
    setDefaultColor,
    setIsConfirm,
    addNewConfirmation,
    setSettingModalOpen,
    updateExisitingConfirmationForm,
    updateNewConfirmationForm,
    getAllConfirmationSelector, removeStop, addStop, updateStop, getAllStops, setAllConfirmation,
} from "../../store/confirmationSlice";
import {
    setOpenPartySelector, getSelectedConsignee, getSelectedShipper, setSelectedConsignee, setSelectedShipper,
} from "../../store/partySlice";
import {
    setOpenCarrierSelector, getSelectedCarrier,
} from "../../store/carrierSlice";
import {
    defaultInputSmStyle, IconStyle
} from "../../constants/defaultStyles";
import Button from "../../components/Button/Button";
import ClientPlusIcon from "../../components/Icons/ClientPlusIcon";
import InvoiceIcon from "../../components/Icons/InvoiceIcon";
import PlusCircleIcon from "../../components/Icons/PlusCircleIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import {nanoid} from "nanoid";
import {
    getSelectedProduct, setOpenProductSelector,
} from "../../store/productSlice";
import {useAppContext} from "../../context/AppContext";
import DollarIcon from "../../components/Icons/DollarIcon";
import CheckCircleIcon from "../../components/Icons/CheckCircleIcon";
import {
    getTotalTaxesWithPercent, sumProductTotal, sumTotalAmount, sumTotalTaxes,
} from "../../utils/match";
import PageTitle from "../../components/Common/PageTitle";
import {
    createConfirmation,
} from "../../actions/confirmationActions";
import {getCurrentCompany} from "../../store/userSlice";
import {Menu, MenuButton} from "@szhsin/react-menu";
import {MenuItem} from "@mui/material";
import {fetchNextConfirmationNumber} from "../../actions/companyActions";
import {getCurrentCompanyId} from "../../utils/storage";
import {fetchCompanySettings} from "../../actions/companySettingsActions";
import {selectCompanySettings} from "../../store/companySettingsSlice";

function CarrierConfirmationDetailScreen() {
    const {initLoading, showNavbar, toggleNavbar, setEscapeOverflow} = useAppContext();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const componentRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);
    const handlePrint = useReactToPrint({
        content: reactToPrintContent, documentTitle: "Carrier Confirmation", onAfterPrint: useCallback(() => {
            setIsExporting(false);
            setEscapeOverflow(false);
        }, [setEscapeOverflow]), removeAfterPrint: true,
    });

    const confirmationNewForm = useSelector(getConfirmationNewForm);
    const allConfirmationDetails = useSelector(getAllConfirmationSelector);
    const selectedProduct = useSelector(getSelectedProduct);
    const currentBg = useSelector(getCurrentBGImage);
    const currentColor = useSelector(getCurrentColor);
    const isConfirm = useSelector(getIsConfirm);
    const selectedShipper = useSelector(getSelectedShipper);
    const selectedConsignee = useSelector(getSelectedConsignee);
    const selectedCarrier = useSelector(getSelectedCarrier);
    const currentCompany = useSelector(getCurrentCompany);
    const companyId = getCurrentCompanyId();
    const companySettings = useSelector(selectCompanySettings);
    const stops = useSelector(getAllStops);

    const [currentRole, setCurrentRole] = useState(null);
    const [confirmationForm, setConfirmationForm] = useState(null);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [statusData, setStatusData] = useState({
        statusName: "Draft", statusIndex: 1,
    });
    const [editingStopId, setEditingStopId] = useState(null);
    const [editingPartyType, setEditingPartyType] = useState(null);

    const handleAddStop = useCallback(() => {
            const newStop = {
                id: nanoid(),
                type: stops.length % 2 === 0 ? 'pickup' : 'dropoff',
                party: null,
                scheduledTime: "",
            };
        dispatch(addStop(newStop));
    }, [dispatch, stops]);

    const handleRemoveStop = useCallback((stopId) => {
        dispatch(removeStop(stopId));
    }, [dispatch]);

    const handleUpdateStop = useCallback((stopId, field, value) => {
        dispatch(updateStop({ stopId, data: { [field]: value } }));
    }, [dispatch]);


    useEffect(() => {
        if ((selectedShipper || selectedConsignee) && editingStopId !== null) {
            const selectedParty = selectedShipper || selectedConsignee;
            const partyType = editingPartyType;
            if (selectedParty && partyType) {
                dispatch(updateStop({
                    stopId: editingStopId,
                    data: {
                        type: partyType,
                        party: {
                            id: selectedParty._id,
                            name: selectedParty.name,
                            billingAddress: selectedParty.billingAddress,
                            city: selectedParty.city
                        },
                    },
                }));
                setEditingStopId(null);
                setEditingPartyType(null);
                dispatch(setSelectedShipper(null));
                dispatch(setSelectedConsignee(null));
            }
        }
    }, [selectedShipper, selectedConsignee, editingStopId, editingPartyType, dispatch]);

    const handlerConfirmationCurrencyValue = (newCurrency) => {
        setConfirmationForm((prevForm) => ({
            ...prevForm, currencyUnit: newCurrency,
        }));
    };

    const handleExport = useCallback(() => {
        if (showNavbar) {
            toggleNavbar();
        }
        setEscapeOverflow(true);
        setIsViewMode(true);
        setIsExporting(true);
        setTimeout(() => {
            handlePrint();
        }, 3000);
    }, [handlePrint, setEscapeOverflow, showNavbar, toggleNavbar]);

    const handleDownloadImg = useCallback(() => {
        if (showNavbar) {
            toggleNavbar();
        }
        setEscapeOverflow(true);
        setIsViewMode(true);
        setIsExporting(true);
        domtoimage
            .toJpeg(componentRef.current, {quality: 1})
            .then(async (dataUrl) => {
                try {
                    const res = await fetch(dataUrl);
                    const blob = await res.blob();
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(blob);
                    a.download = "invoice.jpeg";
                    a.hidden = true;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } catch (e) {
                    console.log(e);
                } finally {
                    setIsExporting(false);
                    setEscapeOverflow(false);
                }
            });
    }, [setEscapeOverflow, showNavbar, toggleNavbar]);

    const toggleViewMode = useCallback(() => {
        if (confirmationForm.statusIndex !== "1" && isViewMode) {
            toast.warn("You can only edit on Draft Mode", {
                position: "bottom-center", autoClose: 3000,
            });
            return;
        }
        setIsViewMode((prev) => !prev);
    }, [confirmationForm, isViewMode]);

    const openSettingModal = useCallback(() => {
        if (confirmationForm.statusIndex !== "1" && isViewMode) {
            toast.warn("You can only change Setting on Draft Mode", {
                position: "bottom-center", autoClose: 3000,
            });
            return;
        }
        dispatch(setSettingModalOpen(true));
    }, [dispatch, confirmationForm, isViewMode]);


    const openChooseShipper = useCallback((stopId) => {
        dispatch(setOpenPartySelector("SHIPPER"));
        setEditingStopId(stopId);
        setEditingPartyType("pickup");
    }, [dispatch]);

    const openChooseConsignee = useCallback((stopId) => {
        dispatch(setOpenPartySelector("CONSIGNEE"));
        setEditingStopId(stopId);
        setEditingPartyType("dropoff");
    }, [dispatch]);

    const openChooseCarrier = useCallback(() => {
        dispatch(setOpenCarrierSelector(true));
    }, [dispatch]);

    const openChooseProduct = useCallback(() => {
        dispatch(setOpenProductSelector(true));
    }, [dispatch]);

    const addEmptyProduct = useCallback(() => {
        const emptyProduct = {
            id: nanoid(), name: "", productID: "", weight: 1, unit: "", quantity: 1,
        };
        setConfirmationForm((prev) => {
            let updatedData = {...prev};
            const updateProducts = [...prev.products, emptyProduct];
            const subTotalAmount = sumProductTotal(updateProducts);
            const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
            updatedData.products = updateProducts;
            updatedData.taxes = updateTaxes;
            updatedData.totalAmount = sumTotalAmount(subTotalAmount, sumTotalTaxes(updateTaxes));
            return updatedData;
        });
    }, []);

    const onDeleteProduct = useCallback((prodID) => {
        setConfirmationForm((prev) => {
            let updatedData = {...prev};
            const updateProducts = prev.products.filter((prod) => prod.id !== prodID);
            const subTotalAmount = sumProductTotal(updateProducts);
            const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
            updatedData.products = updateProducts;
            updatedData.taxes = updateTaxes;
            updatedData.totalAmount = sumTotalAmount(subTotalAmount, sumTotalTaxes(updateTaxes));
            return updatedData;
        });
    }, []);

    const handlerConfirmationValue = useCallback((event, keyName) => {
        const value = typeof event === "string" ? event : event?.target?.value;

        setConfirmationForm((prev) => {
            return {...prev, [keyName]: value};
        });
    }, [setConfirmationForm]);

    const handlerProductValue = useCallback((event, keyName, productID) => {
        const value = event.target.value;

        // If Keyname Price or Quantity must be only number
        if (keyName === "quantity") {
            if (!`${value}`.match(/^\d+$/)) {
                return;
            }
        }

        if (keyName === "weight") {
            if (!`${value}`.match(/^[0-9]\d*(\.\d+)?$/)) {
                return;
            }
        }
        // Quantity Zero Case
        if (keyName === "quantity" && value <= 0) {
            return;
        }

        let updatedData = {...confirmationForm};
        let updateProducts = [...confirmationForm.products];

        const isFindIndex = updateProducts.findIndex((prod) => prod.id === productID);

        if (isFindIndex !== -1) {
            updateProducts[isFindIndex] = {
                ...updateProducts[isFindIndex], [keyName]: value,
            };

            updatedData.products = [...updateProducts];
        }

        setConfirmationForm(updatedData);
    }, [confirmationForm]);

    const handlerConfirmationCarrierValue = useCallback((event, keyName) => {
        const value = typeof event === "string" ? new Date(event) : event?.target?.value;

        setConfirmationForm((prev) => {
            return {
                ...prev, carrier: {...prev.carrier, [keyName]: value},
            };
        });
    }, []);

    // save as and format stops for submit
    const saveAs = useCallback((status) => {
        setStatusData({
            statusIndex: status === "Draft" ? "1" : status === "Pending" ? "2" : "3",
            statusName: status,
        });
        const formattedStops = stops.map(stop => ({
            ...stop,
            party : {
                name: stop?.party?.name,
                billingAddress: stop?.party?.billingAddress,
                city: stop?.party?.city,
            },
            scheduledTime: new Date(stop.scheduledTime),
        }));


        setConfirmationForm(prevForm => ({
            ...prevForm,
            stops: formattedStops
        }));

        dispatch(setConfirmModalOpen(true));
    }, [dispatch, stops]);

    const goConfirmationList = useCallback(() => {
        navigate("/confirmations");
    }, [navigate]);

    // choose existing product
    useEffect(() => {
        if (selectedProduct !== null) {
            const {name, productID, unit} = selectedProduct;
            const newProduct = {
                id: nanoid(), name, productID, quantity: 1, weight: 0, unit,
            };

            setConfirmationForm((prev) => {
                let updatedData = {...prev};
                const updateProducts = [...prev.products, newProduct];
                const subTotalAmount = sumProductTotal(updateProducts);
                const updateTaxes = getTotalTaxesWithPercent(prev.taxes, subTotalAmount);
                updatedData.products = updateProducts;
                updatedData.taxes = updateTaxes;
                updatedData.totalAmount = sumTotalAmount(subTotalAmount, sumTotalTaxes(updateTaxes));
                return updatedData;
            });
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (initLoading === false) {
            if (params.id === "new" && confirmationForm === null) {
                setConfirmationForm({
                    ...confirmationNewForm,
                    createdDate: new Date(),
                });

                dispatch(setDefaultBackground(confirmationNewForm.backgroundImage));
                dispatch(setDefaultColor(confirmationNewForm.color));
            } else if (params.id !== "new" && confirmationForm === null) {
                const getConfirmationDetail = allConfirmationDetails.find((inv) => inv._id === params.id);

                if (!getConfirmationDetail) {
                    navigate("/confirmations");
                } else {
                    setConfirmationForm({
                        ...getConfirmationDetail,
                        createdDate: new Date(getConfirmationDetail.createdDate),
                        stops: getConfirmationDetail.stops.map(stop => ({
                            ...stop,
                            scheduledTime: new Date(stop.scheduledTime)
                        }))
                    });

                    dispatch(setDefaultBackground(getConfirmationDetail.backgroundImage));
                    dispatch(setDefaultColor(getConfirmationDetail.color));
                    setIsViewMode(true);
                }
            }
        }
    }, [params, confirmationForm, navigate, confirmationNewForm, initLoading, dispatch, allConfirmationDetails]);

    // Carrier selector
    useEffect(() => {
        if (selectedCarrier !== null) {
            setConfirmationForm((prev) => {
                return {
                    ...prev, carrier: {...selectedCarrier},
                };
            });
        }
    }, [selectedCarrier]);

    useEffect(() => {
        if (!currentRole) return;

        if (currentRole === "shipper") {
            dispatch(setSelectedShipper(selectedShipper));
        } else if (currentRole === "consignee") {
            dispatch(setSelectedConsignee(selectedConsignee));
        }
        setCurrentRole(null);
    }, [currentRole, selectedShipper, selectedConsignee, dispatch, setCurrentRole,]);

    useEffect(() => {
        if (params.id === "new" && confirmationForm !== null) {
            dispatch(updateNewConfirmationForm(confirmationForm));
        } else if (params.id !== "new" && confirmationForm !== null) {
            dispatch(updateExisitingConfirmationForm(confirmationForm));
        }
    }, [dispatch, confirmationForm, params]);

    useEffect(() => {
        if (initLoading === false) {
            setConfirmationForm((prev) => ({
                ...prev, backgroundImage: currentBg, color: currentColor,
            }));
        }
    }, [currentBg, currentColor, initLoading]);

    useEffect(() => {
        const handleNewConfirmationCreation = async () => {
            const confirmationData = {
                ...confirmationForm,
                ...statusData,
                createdDate: new Date(confirmationForm.createdDate),
                stops: confirmationForm.stops.map(stop => ({
                    ...stop,
                    scheduledTime: new Date(stop.scheduledTime),
                }))
            };
            const savedConfirmation = dispatch(createConfirmation(confirmationData));

            dispatch(addNewConfirmation(savedConfirmation));
            setConfirmationForm({
                ...confirmationForm,
                products: [{
                    id: nanoid(),
                    name: "",
                    productID: "",
                    description: "",
                    quantity: 1,
                    weight: 0,
                    unit: "",
                }],
                agreedRate: 0,
                taxes: [],
                stops: []
            });
        };

        if (isConfirm) {
            const isNew = params.id === "new";
            if (isNew) {
                dispatch(setIsConfirm(false));
                handleNewConfirmationCreation();
            } else {
                // Update Existing Confirmation
                dispatch(setIsConfirm(false));
                setIsViewMode(true);
                setConfirmationForm({
                    ...confirmationForm,
                    ...statusData,
                });
            }
        }
    }, [dispatch, confirmationForm, isConfirm, navigate, params.id, statusData]);
    // get next invoice number
    useEffect(() => {
        if (params.id === "new") {
            const getNextConfirmationNumber = async () => {
                try {
                    const nextConfirmationNumber = await dispatch(fetchNextConfirmationNumber(companyId));
                    setConfirmationForm((prevForm) => ({
                        ...prevForm, confirmationNo: nextConfirmationNumber.toString().padStart(4, '0'),
                    }));

                } catch (err) {
                    console.error('Error fetching next confirmation number', err);
                }
            };
            getNextConfirmationNumber();
        }
    }, [companyId, currentCompany, dispatch, params]);

    // get company settings
    useEffect(() => {
        if (currentCompany && currentCompany._id) {
            dispatch(fetchCompanySettings(currentCompany._id));
        }
    }, [currentCompany, dispatch]);

    const renderedStops = useMemo(() => {
        if (!confirmationForm) {
            return <div>loading...</div>;
        }
        const stopsToRender = isViewMode ? confirmationForm?.stops : stops;
        return stopsToRender.map((stop, index) => (
            <div key={stop.id} className="w-full">
                <div className="py-2 px-4">
                    <div
                        className={isExporting ? "flex rounded-lg w-full flex-row px-4 py-1 text-white" : "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white"}
                        style={{backgroundColor: confirmationForm?.color}}
                    >
                        <div className={"font-title " + (isExporting ? " text-sm text-right pr-10" : " w-full text-right sm:pr-10")}>
                            <div className="font-title flex justify-between w-full">
                                <span className="inline-block">{index % 2 === 0 ? "Shipper Details" : "Consignee Details"}</span>
                                <span className="inline-block ml-auto pl-4">{`${index % 2 === 0 ? `Pickup ${index + 1}` : `Dropoff ${index}`}`}</span>
                            </div>
                        </div>
                        {!isViewMode && (
                            <div className="align-right text-right pr-3 ">
                                <Button onClick={() => handleRemoveStop(stop.id)} danger={1}
                                        className="text-red-500 p-2 border rounded border-red-400 bg-red-100 text-right">
                                    <DeleteIcon />
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col sm:flex-row">
                        <div className="flex-1">
                            <div className="flex flex-row pt-5">
                                <div className="font-title font-bold">{index % 2 === 0 ? "Shipper" : "Consignee"}</div>
                                {!isViewMode && (
                                    <div className="w-1/2 relative ml-3" style={{top: "-3px"}}>
                                        <Button
                                            size="sm"
                                            outlined={1}
                                            onClick={() => index % 2 === 0 ? openChooseShipper(stop.id) : openChooseConsignee(stop.id)}
                                        >
                                            <ClientPlusIcon className="w-4 h-4"/> Select
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="client-form-wrapper sm:w-1/2">
                                <div className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}>
                                    {isViewMode ? stop?.party?.name : (
                                        <input
                                            autoComplete="nope"
                                            placeholder={`${index % 2 === 0 ? "Shipper" : "Consignee"} Name`}
                                            className={defaultInputSmStyle}
                                            value={stop?.party?.name}
                                            onChange={(e) => handleUpdateStop(stop.id, "name", e.target.value)}
                                        />
                                    )}
                                </div>
                                <div className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}>
                                    {isViewMode ? stop?.party?.billingAddress : (
                                        <input
                                            autoComplete="nope"
                                            placeholder={`${index % 2 === 0 ? "Shipper" : "Consignee"} Address`}
                                            className={defaultInputSmStyle}
                                            value={stop?.party?.billingAddress}
                                            onChange={(e) => handleUpdateStop(stop.id, "billingAddress", e.target.value)}
                                        />
                                    )}
                                </div>
                                <div className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}>
                                    {isViewMode ? stop?.party?.city : (
                                        <input
                                            autoComplete="nope"
                                            placeholder={`${index % 2 === 0 ? "Shipper" : "Consignee"} City`}
                                            className={defaultInputSmStyle}
                                            value={stop?.party?.city}
                                            onChange={(e) => handleUpdateStop(stop.id, "city", e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 pt-10">
                            <div className="flex-1 sm:flex sm:flex-row sm:items-center sm:justify-between mt-5 sm:mt-0">
                                <div className="font-title">{index % 2 === 0 ? "Pickup" : "Drop off"} Date & Time</div>
                                <div className="text-right">
                                    {isViewMode ? (
                                        new Date(stop.scheduledTime).toLocaleString()
                                    ) : (
                                        <DatePicker
                                            dateFormat="yyyy/MM/dd HH:mm"
                                            showTimeSelect
                                            timeIntervals={15}
                                            selected={stop.scheduledTime ? new Date(stop.scheduledTime) : null}
                                            onChange={(date) => handleUpdateStop(stop.id, "scheduledTime", date.toISOString())}
                                            className={defaultInputSmStyle + " border-gray-300 text-right"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }, [stops, handleUpdateStop, isViewMode, isExporting, confirmationForm, openChooseShipper, openChooseConsignee, handleRemoveStop]);
    return (<div>
        <div className="p-4">
            <PageTitle
                title={<>
                    {params.id === "new" ? "New Confirmation" : `Confirmation Detail ${confirmationForm?.statusName}`}
                </>}
            />
        </div>
        <div className="px-4 pb-3">
            <ConfirmationTopBar
                onClickBack={goConfirmationList}
                viewMode={isViewMode}
                onClickViewAs={toggleViewMode}
                onClickSetting={openSettingModal}
                onClickExport={handleExport}
                onClickDownloadImg={handleDownloadImg}
            />
        </div>

        {confirmationForm && (<div
            className={isExporting ? "bg-white mb-1 pt-1 px-1 " : "bg-white mx-4 rounded-xl mb-1"}
            id="ConfirmationWrapper"
            ref={componentRef}
            style={isExporting ? {width: 1200} : {}}
        >
            {/* Background Image */}
            <div
                className={isExporting ? "py-5 px-8 bg-cover bg-center bg-slate-50 rounded-xl flex flex-row justify-between items-center" : "py-9 px-8 bg-cover bg-center bg-slate-50 rounded-xl flex flex-col sm:flex-row justify-between items-center"}
                style={{
                    backgroundImage: `url(${confirmationForm?.backgroundImage?.base64})`,
                }}
            >
                <div
                    className={isExporting ? "flex xflex-row items-center" : "flex flex-col sm:flex-row items-center"}
                >
                    {currentCompany?.image ? (<img
                        className="object-contain h-20 w-20 mr-3 rounded"
                        alt={currentCompany?.companyName}
                        src={currentCompany?.image}
                    />) : (<span></span>)}

                    <div
                        className={isExporting ? "text-white font-title text-left" : "text-white font-title text-center sm:text-left"}
                    >
                        <p className="font-bold mb-2">
                            {currentCompany?.companyName || "Company Name"}
                        </p>
                        <p className="text-sm font-medium">
                            {currentCompany?.billingAddress || "Plz add First Company Data"}
                        </p>
                        <p className="text-sm font-medium">
                            {currentCompany?.companyMobile || "Company Ph"}
                        </p>
                        <p className="text-sm font-medium">
                            {currentCompany?.companyEmail || "Company@email.com"}
                        </p>
                    </div>
                </div>
                <div className="text-white font-title font-bold text-5xl mt-5 sm:mt-0">
                    Carrier Confirmation
                </div>
            </div>
            {/* Background Image Finished */}
            {/* Party Billing Info */}
            <div
                className={isExporting ? "flex flex-row pt-2 px-8" : "flex flex-col sm:flex-row pt-3 px-8"}
            >
                <div className="flex-1">
                    <div className="flex flex-row">
                        <div className="font-title font-bold"> Carrier</div>
                        <div className="w-1/2 relative ml-3" style={{top: "-3px"}}>
                            {!isViewMode && (<Button size="sm" outlined={1} onClick={openChooseCarrier}>
                                <ClientPlusIcon className="w-4 h-4"/> Existing
                            </Button>)}
                        </div>
                    </div>
                    <div className="client-form-wrapper sm:w-1/2">
                        <div
                            className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}
                        >
                            {!isViewMode ? (<input
                                autoComplete="nope"
                                placeholder="Client Name"
                                className={defaultInputSmStyle}
                                value={confirmationForm?.carrier?.carrierName}
                                onChange={(e) => handlerConfirmationCarrierValue(e, "carrierName")}
                            />) : (confirmationForm?.carrier?.carrierName)}
                        </div>
                        <div
                            className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}
                        >
                            {!isViewMode ? (<input
                                autoComplete="nope"
                                placeholder="Client Address"
                                className={defaultInputSmStyle}
                                value={confirmationForm?.carrier?.address}
                                onChange={(e) => handlerConfirmationCarrierValue(e, "address")}
                            />) : (confirmationForm?.carrier?.address)}
                        </div>
                        <div
                            className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}
                        >
                            {!isViewMode ? (<input
                                autoComplete="nope"
                                placeholder="Phone Number"
                                className={defaultInputSmStyle}
                                value={confirmationForm?.carrier?.phoneNumber}
                                onChange={(e) => handlerConfirmationCarrierValue(e, "phoneNumber")}
                            />) : (confirmationForm?.carrier?.phoneNumber)}
                        </div>
                        <div
                            className={"font-medium " + (isExporting ? "text-xs" : "text-sm mb-1")}
                        >
                            {!isViewMode ? (<input
                                autoComplete="nope"
                                placeholder="MC Number"
                                className={defaultInputSmStyle}
                                value={confirmationForm?.carrier?.mcNumber}
                                onChange={(e) => handlerConfirmationCarrierValue(e, "mcNumber")}
                            />) : (confirmationForm?.carrier?.mcNumber)}
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="flex flex-row justify-between items-center mb-1">
                        <div className="font-title flex-1"> Reference #</div>
                        <div className="font-title text-xl content-right justify-end text-right">
                            {confirmationForm?.confirmationNo}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-1">
                        <div className="font-title flex-1"> Creation Date</div>
                        <div className="font-title flex-1 text-right">
                            <DatePicker
                                selected={confirmationForm.createdDate}
                                onChange={(date) => handlerConfirmationValue(date.toISOString(), "createdDate")}
                                disabled={true}
                                className={!isViewMode ? defaultInputSmStyle + " border-gray-300 text-right" : " text-right bg-white"}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-1">
                        <div className="font-title flex-1"> Agreed Rate</div>
                        <div className="font-title flex-1 text-right">
                            {!isViewMode ? (<input
                                autoComplete="nope"
                                placeholder="Agreed Rate"
                                className={defaultInputSmStyle + " text-right"}
                                value={confirmationForm.agreedRate}
                                onChange={(e) => handlerConfirmationValue(e, "agreedRate")}
                            />) : (<div className="text-2xl">
                                {"$" + confirmationForm.agreedRate || "$0"}
                            </div>)}
                        </div>
                    </div>
                    {!isViewMode && (<div className="flex flex-row justify-between items-center mb-1">
                        <div className="font-title flex-1"> Change Currency</div>
                        <div className="font-title flex-1 text-right">
                            <Menu
                                menuButton={<MenuButton>
                                    <div
                                        className="bg-gray-50 px-2 rounded-xl flex flex-row justify-center items-center">
                                        <DollarIcon/>
                                        <span
                                            className="ml-2">{confirmationForm?.currencyUnit || 'CAD'}</span>
                                    </div>
                                </MenuButton>}
                                transition
                            >
                                <MenuItem
                                    onClick={() => handlerConfirmationCurrencyValue('CAD', 'currencyUnit')}>CAD -
                                    Canadian Dollar</MenuItem>
                                <MenuItem
                                    onClick={() => handlerConfirmationCurrencyValue('USD', 'currencyUnit')}>USD -
                                    US Dollar</MenuItem>
                            </Menu>
                        </div>
                    </div>)}
                </div>
            </div>
            {/* Party Billing Info Finished */}
            <div className="flex flex-row content-right pl-4">
                {!isViewMode && (<Button onClick={handleAddStop}
                                         className="text-blue-500 p-2 border rounded border-blue-400 bg-blue-100">
                    <PlusCircleIcon/>
                </Button>)}
            </div>
            {/*Rendered Shipper & Consignee sections. */}
            {renderedStops}
            <div
                className={isExporting ? "flex rounded-lg w-full flex-row px-4 py-1 text-white" : "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white"}
                style={{backgroundColor: confirmationForm.color}}
            >
                <div
                    className={"font-title " + (isExporting ? " text-sm w-1/4 text-right pr-10" : " w-full sm:w-1/4 text-right sm:pr-10")}
                >
                    <span className="inline-block">Description</span>
                </div>
                <div
                    className={"font-title " + (isExporting ? " text-sm  w-1/4 text-right pr-10" : " w-full sm:w-1/4 text-right sm:pr-10")}
                >
                    Weight
                </div>
                <div
                    className={"font-title " + (isExporting ? " text-sm  w-1/4 text-right pr-10" : " w-full sm:w-1/4 text-right sm:pr-10")}
                >
                    (lbs / kgs)
                </div>
                <div
                    className={"font-title " + (isExporting ? " text-sm  w-1/4 text-right " : " w-full sm:w-1/4 text-right pr-10")}
                >
                    Qty
                </div>
                <div
                    className={"font-title " + (isExporting ? " text-sm  w-1/4 text-right " : " w-full sm:w-1/4 text-right pr-20")}
                >
                    Type
                </div>
                <div
                    className={"font-title " + (isExporting ? " text-sm  w-1/4 text-right pr-10" : " w-full sm:w-1/4 text-right sm:pr-10")}
                >
                    Bill of Lading
                </div>
            </div>
            {confirmationForm?.products?.map((product, index) => (<div
                key={`${index}_${product.id}`}
                className={(isExporting ? "flex flex-row rounded-lg w-full px-4 py-1 items-center relative text-sm" : "flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center relative") + (index % 2 !== 0 ? " bg-gray-50 " : "")}
            >

                <div
                    className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row" : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                >
                    {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center">
                      Description
                    </span>)}
                    <span
                        className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}
                    >
                    {!isViewMode ? (<input
                        autoComplete="nope"
                        value={product.description}
                        placeholder="description"
                        className={defaultInputSmStyle + " text-right"}
                        onChange={(e) => handlerProductValue(e, "description", product.id)}
                    />) : (<span className="pr-3">{product.description}</span>)}
                  </span>
                </div>

                <div
                    className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row" : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                >
                    {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center">
                      Weight
                    </span>)}
                    <span
                        className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}
                    >
                    {!isViewMode ? (<input
                        autoComplete="nope"
                        value={product.weight}
                        type={"number"}
                        placeholder="Weight"
                        className={defaultInputSmStyle + " text-right"}
                        onChange={(e) => handlerProductValue(e, "weight", product.id)}
                    />) : (<span className="pr-3">
                        <NumberFormat
                            value={product.weight}
                            className=""
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value, props) => (<span {...props}>{value}</span>)}
                        />
                      </span>)}
                  </span>
                </div>
                <div
                        className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row " : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                    >
                        {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center text-lg">
                          Unit
                          </span>)}
                        <span
                            className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0 text-lg"}
                        >
                      {!isViewMode ? (<select
                          value={product.unit}
                          className={defaultInputSmStyle + " text-right"}
                          onChange={(e) => handlerProductValue(e, "unit", product.id)}
                          defaultValue={"lbs"}
                      >
                          <option value="lbs">lbs</option>
                          <option value="kgs">kgs</option>
                      </select>) : (<span className="pr-3">{product.unit}</span>)}
                      </span>
                    </div>
                <div
                    className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row" : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                >
                    {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center">
                                Quantity
                            </span>)}
                    <span
                        className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}
                    >
                        {!isViewMode ? (<input
                            autoComplete="nope"
                            value={product.quantity}
                            type={"number"}
                            placeholder="Quantity"
                            className={defaultInputSmStyle + " text-right"}
                            onChange={(e) => handlerProductValue(e, "quantity", product.id)}
                        />) : (
                            <span className="pr-3">
                            <NumberFormat
                                value={product.quantity}
                                className=""
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value, props) => (<span {...props}>{value}</span>)}
                            />
                            </span>)}
                        </span>
                </div>

                <div
                    className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row" : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                >
                    {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center">
                      Type
                    </span>)}
                    <span
                        className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}
                    >
                    {!isViewMode ? (<input
                        autoComplete="nope"
                        value={product.type}
                        placeholder="Skids"
                        className={defaultInputSmStyle + " text-right"}
                        onChange={(e) => handlerProductValue(e, "type", product.id)}
                    />) : (<span className="pr-3">{product.type}</span>)}
                  </span>
                </div>

                <div
                    className={isExporting ? "font-title w-1/4 text-right pr-8 flex flex-row" : "font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block"}
                >
                    {!isExporting && (<span className="sm:hidden w-1/2 flex flex-row items-center">
                      Bill Of Lading
                    </span>)}
                    <span
                        className={isExporting ? "inline-block w-full mb-0" : "inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}
                    >
                    {!isViewMode ? (<input
                        autoComplete="nope"
                        value={product.bol}
                        type={"number"}
                        placeholder="BOL"
                        className={defaultInputSmStyle + " text-right"}
                        onChange={(e) => handlerProductValue(e, "bol", product.id)}
                    />) : (<span className="pr-3">
                        <NumberFormat
                            value={product.bol}
                            className=""
                            displayType={"text"}
                            renderText={(value, props) => (<span {...props}>{value}</span>)}
                        />
                      </span>)}
                  </span>
                </div>
                {!isViewMode && (<div
                    className="w-full sm:w-10 sm:absolute sm:right-0"
                    onClick={() => onDeleteProduct(product.id)}
                >
                    <div
                        className="w-full text-red-500 font-title h-8 sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-red-200 mr-2 flex justify-center items-center">
                        <DeleteIcon className="h-4 w-4" style={IconStyle}/>
                        <span className="block sm:hidden">Delete Product</span>
                    </div>
                </div>)}
            </div>))}

            {/* Add Product Actions */}
            {!isViewMode && (<div
                className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end">
                <div
                    className="font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1">
                    <Button size="sm" block={1} onClick={addEmptyProduct}>
                        <PlusCircleIcon style={IconStyle} className="h-5 w-5"/>
                        Add Empty Product
                    </Button>
                </div>
                <div
                    className="font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1">
                    <Button size="sm" block={1} onClick={openChooseProduct}>
                        <InvoiceIcon style={IconStyle} className="w-5 h-5"/>
                        Add Exisiting Product
                    </Button>
                </div>
            </div>)}
            {/* Add Product Actions Finished*/}
            {isViewMode && (<div className="mt-10">
                <div className="border-t pt-5">
                    <div
                        className="text-gray-700 whitespace-pre-wrap">{companySettings.carrierTerms}</div>
                </div>
                <div className="mt-10">
                    <div className="border-t pt-5 b-width-[30px]">
                        <div className="flex flex-col items-start ">
                            <div className="w-full border-b-2 border-gray-300 mb-2"></div>
                            <div className="text-gray-700">Signature: _________________________________</div>
                            <div className="text-gray-700 mt-2">Position: __________________________________
                            </div>
                            <div className="text-gray-700 mt-2">Aft Hrs/Contact No: ________________________
                            </div>
                            <div className="text-gray-700 mt-2">**** must be signed and sent
                                to {currentCompany.companyName} *****
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            {/* shameless plug */}
            <div className="mt-20">
                <div className="border-t pt-5">
                    <div className="text-gray-700 whitespace-pre-wrap">Powered by FleeTMS - fleetms.ca</div>
                </div>
            </div>
        </div>)}

        {confirmationForm && confirmationForm?.statusIndex !== "3" && (<div className="px-4 pt-3">
            <div className="bg-white rounded-xl px-3 py-3">
                <div className="flex flex-col flex-wrap sm:flex-row">
                    {params.id === "new" && (<div className="w-full flex-1 my-1 sm:my-1 md:my-0 px-1">
                        <Button
                            outlined={1}
                            size="sm"
                            block={1}
                            secondary={1}
                            onClick={() => saveAs("Draft")}
                            className="rounded-xl font-title flex flex-row items-center justify-center w-full  text-sm h-8 px-2  border-gray-500 border text-gray-500"
                        >
                            <CheckCircleIcon className="h-5 w-5 mr-1"/> Save As Draft
                        </Button>
                    </div>)}
                    {confirmationForm?.statusIndex !== "2" && (<div className="w-full flex-1 my-1 sm:my-1 md:my-0 px-1">
                        <Button
                            outlined={1}
                            size="sm"
                            block={1}
                            danger={1}
                            onClick={() => saveAs("Pending")}
                            className="rounded-xl font-title flex flex-row items-center justify-center w-full  text-sm h-8 px-2  border-yellow-500 border text-yellow-500"
                        >
                            <DollarIcon className="h-5 w-5 mr-1 text-red"/>{" "}
                            {params.id === "new" ? "Save as Pending" : "Update"}
                        </Button>
                    </div>)}
                    <div className="w-full flex-1 my-1 sm:my-1 md:my-0 px-1">
                        <Button
                            size="sm"
                            block={1}
                            success={1}
                            onClick={() => saveAs("Confirmed")}
                        >
                            <DollarIcon className="h-5 w-5 mr-1"/>{" "}
                            {params.id === "new" ? "Confirmed" : "Update"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>)}

        {confirmationForm && (<div className="p-4">
            <ConfirmationTopBar
                onClickBack={goConfirmationList}
                viewMode={isViewMode}
                onClickViewAs={toggleViewMode}
                onClickSetting={openSettingModal}
                onClickExport={handleExport}
                onClickDownloadImg={handleDownloadImg}
            />
        </div>)}
    </div>);
}

export default CarrierConfirmationDetailScreen;