export const getEventStatusColors = (statusName, statusIndex) => {
    switch(statusIndex) {
        case "0":
            return {
                bgColor: '#4B5563',  // Light gray
                borderColor: '#9CA3AF',  // Medium gray
                textColor: '#4B5563',  // Dark gray
                customStyle: ''
            };
        case "1":
            return {
                bgColor: '#D97706',  // Light yellow
                borderColor: '#F59E0B',  // Medium yellow
                textColor: '#ffffff',  // Dark yellow
                customStyle: 'font-weight: bold;'
            };
        case "2":
            return {
                bgColor: '#2563EB',  // Light blue
                borderColor: '#3B82F6',  // Medium blue
                textColor: '#ffffff',  // Dark blue
                customStyle: 'font-weight: bold;'
            };
        case "3":
            return {
                bgColor: '#DC2626',  // Light orange
                borderColor: '#F87171',  // Medium orange
                textColor: '#ffffff',  // Dark orange
                customStyle: 'font-weight: bold;'
            };
        case "4":
            return {
                bgColor: '#059669',  // Light green
                borderColor: '#10B981',  // Medium green
                textColor: '#ffffff',  // Dark green
                customStyle: 'font-weight: bold;'
            };
        case "5":
            return {
                bgColor: '#DC2626',  // Light red
                borderColor: '#EF4444',  // Medium red
                textColor: '#ffffff',  // Dark red
                customStyle: 'font-weight: bold;'
            };
        default:
            return {
                bgColor: '#6B7280',  // Very light gray
                borderColor: '#D1D5DB',  // Light gray
                textColor: '#6B7280',  // Medium gray
                customStyle: ''
            };
    }
};