// src/components/RateCalculator.js
import React, { useEffect, useState } from 'react';
import GetRates from './GetRates';
import InfoIcon from "../Icons/InfoIcon";
import { useSelector, useDispatch } from 'react-redux';
import { selectCompanySettings } from '../../store/companySettingsSlice';
import { fetchCompanySettings } from '../../actions/companySettingsActions';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { toast } from 'react-toastify';

const RateCalculator = () => {
    const [pickupLocation, setPickupLocation] = useState('');
    const [deliveryLocation, setDeliveryLocation] = useState('');
    const [pickupCoords, setPickupCoords] = useState(null);
    const [deliveryCoords, setDeliveryCoords] = useState(null);
    const [trailerType, setTrailerType] = useState('Dry Van');
    const [specialRate, setSpecialRate] = useState('');
    const [capacityType, setCapacityType] = useState('ftl');
    const [rates, setRates] = useState(null);
    const [rateHistory, setRateHistory] = useState([]);
    const dispatch = useDispatch();
    const companySettings = useSelector(selectCompanySettings);

    const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    useEffect(() => {
        dispatch(fetchCompanySettings());
    }, [dispatch]);

    const calculateRate = (distance) => {
        const {
            minimumRatePerUnit,
            targetProfitMargin,
            fuelSurchargePercentage,
            baseRateAdjustment,
            specialRateMultipliers,
            equipmentPremiums,
            capacityRateMultipliers,
            distanceUnit
        } = companySettings;

        let baseRate = distance * minimumRatePerUnit;
        baseRate *= (1 + baseRateAdjustment / 100);

        let adjustedRate = baseRate * (1 + targetProfitMargin / 100);
        adjustedRate *= (1 + fuelSurchargePercentage / 100);

        if (specialRate) {
            adjustedRate *= specialRateMultipliers[specialRate] || 1;
        }
        if (trailerType === 'Reefer') {
            adjustedRate *= equipmentPremiums.reefer;
        }
        adjustedRate *= capacityRateMultipliers[capacityType];

        const low = adjustedRate * 0.85;
        const high = adjustedRate * 1.15;

        return {
            low: Math.round(low),
            median: Math.round(adjustedRate),
            high: Math.round(high),
            distanceUnit
        };
    };

    const getRates = async () => {
        if (!pickupCoords || !deliveryCoords) {
            toast.error('Please select both pickup and delivery locations');
            return;
        }

        try {
            const directionsResponse = await fetch(`https://api.mapbox.com/directions/v5/mapbox/driving/${pickupCoords[0]},${pickupCoords[1]};${deliveryCoords[0]},${deliveryCoords[1]}?access_token=${mapboxToken}`);
            const directionsData = await directionsResponse.json();

            if (directionsData.routes && directionsData.routes.length > 0) {
                const distanceInMeters = directionsData.routes[0].distance;
                const distanceUnit = companySettings.distanceUnit;
                const distance = distanceUnit === 'km'
                    ? distanceInMeters / 1000
                    : distanceInMeters / 1609.34;

                const newRates = calculateRate(distance, distanceUnit);
                newRates.pickup = pickupLocation;
                newRates.delivery = deliveryLocation;
                newRates.date = new Date().toLocaleString();
                newRates.distance = Math.round(distance);
                newRates.distanceUnit = distanceUnit;
                newRates.capacityType = capacityType;

                setRates(newRates);
                setRateHistory(prevHistory => [newRates, ...prevHistory].slice(0, 5));
            } else {
                toast.error('Could not calculate route between the given locations');
            }
        } catch (error) {
            console.error('Error getting rates:', error);
            toast.error('Error calculating rates');
        }
    };

    const RateHistoryItem = ({ rate }) => (
        <div className="bg-gray-100 p-4 rounded-md mb-2">
            <div className="font-semibold text-lg mb-1">
                ${rate.low} - ${rate.median} - ${rate.high}
            </div>
            <div className="text-sm text-gray-600">
                From: {rate.pickup} | To: {rate.delivery} | Distance: {rate.distance} {rate.distanceUnit}
            </div>
            <div className="text-sm text-gray-600">
                Capacity Type: {rate.capacityType}
            </div>
            <div className="text-xs text-gray-500 mt-1">
                {rate.date}
            </div>
        </div>
    );

    const handlePickupSelect = (result, lat, lng, text) => {
        setPickupLocation(text);
        setPickupCoords([lng, lat]);
    };

    const handleDeliverySelect = (result, lat, lng, text) => {
        setDeliveryLocation(text);
        setDeliveryCoords([lng, lat]);
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <div className="flex items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Instant Real-Time Rate Calculator</h1>
                <InfoIcon message="Adjust your financial goals in the company settings and get instant rates to reach these goals" />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="pickup">
                        Pickup Location
                    </label>
                    <MapboxAutocomplete
                        publicKey={mapboxToken}
                        inputClass="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        onSuggestionSelect={handlePickupSelect}
                        country="us,ca"
                        resetSearch={false}
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="delivery">
                        Delivery Location
                    </label>
                    <MapboxAutocomplete
                        publicKey={mapboxToken}
                        inputClass="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        onSuggestionSelect={handleDeliverySelect}
                        country="us,ca"
                        resetSearch={false}
                    />
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="trailerType">
                        Trailer Type
                    </label>
                    <select
                        id="trailerType"
                        value={trailerType}
                        onChange={(e) => setTrailerType(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="Dry Van">Dry Van</option>
                        <option value="Reefer">Reefer</option>
                        <option value="Dry/Reefer">Dry/Reefer</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="specialRate">
                        Special Rates
                    </label>
                    <select
                        id="specialRate"
                        value={specialRate}
                        onChange={(e) => setSpecialRate(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="">None</option>
                        <option value="Hazmat">Hazmat</option>
                        <option value="Undesirable Dock">Undesirable Dock</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="capacityType">
                        Capacity Type
                    </label>
                    <select
                        id="capacityType"
                        value={capacityType}
                        onChange={(e) => setCapacityType(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="ftl">Full Truckload (FTL)</option>
                        <option value="ltl">Less Than Truckload (LTL)</option>
                    </select>
                </div>
            </div>

            <button
                onClick={getRates}
                className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out mb-6"
            >
                Get Rate
            </button>

            {rates && <GetRates rates={rates} />}

            {rateHistory.length > 0 && (
                <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Recent Rate Estimates</h2>
                    {rateHistory.map((rate, index) => (
                        <RateHistoryItem key={index} rate={rate} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default RateCalculator;