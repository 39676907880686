import React, {useCallback} from "react";
import {motion} from "framer-motion";
import {NavLink, useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/AppContext";
import {useSelector} from "react-redux";
import HomeIcon from "../Icons/HomeIcon";
import ProductIcon from "../Icons/ProductIcon";
import InvoiceIcon from "../Icons/InvoiceIcon";
import ClientPlusIcon from "../Icons/ClientPlusIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import TrailerIcon from "../Icons/TrailerIcon";
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getCurrentUser, setCurrentUser} from "../../store/userSlice";
import {clearCurrentCompanyId} from "../../utils/storage";
import base_url from "../../utils/config";
import {AccountCircleOutlined, CalculateOutlined, EditCalendar} from "@mui/icons-material";
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

const MAIN_NAV_DATA = [{
    title: "Drivers", link: "drivers", Icon: BadgeOutlinedIcon,
}, {
    title: "Customers", link: "customers", Icon: ClientPlusIcon,
}, {
    title: "Shipper / Consignee", link: "parties", Icon: ClientPlusIcon,
}, {
    title: "Products", link: "products", Icon: ProductIcon,
}, {
    title: "Carriers", link: "carriers", Icon: EmojiTransportationIcon,
}, {
    title: "Tractors", link: "tractors", Icon: LocalShippingOutlinedIcon,
}, {
    title: "Trailers", link: "trailers", Icon: TrailerIcon,
},];

const GROUPED_NAV_DATA = [{
    title: "Dashboard", link: "/", Icon: HomeIcon,
}, {
    title: "Invoices", link: "invoices", Icon: InvoiceIcon,
}, {
    title: "Rate Calculator", link: "calculator", Icon: CalculateOutlined
}, {
    title: "Rate Confirmation", link: "confirmations", Icon: RequestPageOutlinedIcon,
}, {
    title: "Loads", link: "loads", Icon: LocalGasStationOutlinedIcon,
}, {
    title: "Schedule", link: "schedule", Icon: EditCalendar,
},];

const handleLogout = async () => {
    try {
        await axios.post(`${base_url}/api/auth/logout`, {}, {withCredentials: true});
        clearCurrentCompanyId();
        window.location.reload();
    } catch (error) {
        console.error("Failed to log out", error);
    }
};

const navDefaultClasses = "fixed inset-0 duration-200 transform z-10 w-70 lg:w-70 lg:opacity-100 bg-white h-screen p-3 md:w-72 sm:w-full";

const navItemDefaultClasses = "block px-4 py-2 rounded-md flex flex-1";

function Sidebar() {
    const {showNavbar, initLoading, toggleNavbar} = useAppContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${base_url}/api/auth/me`, {
                    withCredentials: true,
                });
                dispatch(setCurrentUser(response.data));
            } catch (error) {
                console.error("Failed to fetch user:", error);
            }
        };

        fetchUser();
    }, [dispatch]);

    const user = useSelector(getCurrentUser);

    const onClickNavbar = useCallback(() => {
        const width = window.innerWidth;

        if (width <= 767 && showNavbar) {
            toggleNavbar();
        }
    }, [showNavbar, toggleNavbar]);

    const onProfileClick = useCallback(() => {
        const width = window.innerWidth;
        navigate("/settings/user");

        if (width <= 767 && showNavbar) {
            toggleNavbar();
        }
    }, [navigate, toggleNavbar, showNavbar]);

    return (<>
        <nav
            className={showNavbar ? navDefaultClasses + " translate-x-0 ease-in" : navDefaultClasses + " -translate-x-full ease-out"}
        >
            <div className="flex justify-between">
                <motion.span
                    className="font-bold font-title text-2xl sm:text-2xl p-2 flex justify-center items-center"
                    initial={{
                        translateX: -300,
                    }}
                    animate={{
                        translateX: showNavbar ? -10 : -300, color: "#0066FF",
                    }}
                    transition={{
                        type: "spring", damping: 18,
                    }}
                >
                    FleeTMS
                </motion.span>
            </div>

            {initLoading && <Skeleton className="px-4 py-5 rounded-md"/>}
            <motion.span
                className={navItemDefaultClasses + " bg-gray-100 flex items-center px-3 cursor-pointer"}
                onClick={onProfileClick}
                whileHover={{scale: 1.03, backgroundColor: "rgba(0, 102, 255, 0.1)"}}
                whileTap={{scale: 0.9}}
            >
                <AccountCircleOutlined className="w-6 h-6"/>
                <span
                    className="flex-1 pl-2 font-title font-bold rounded-r py-1 border-r-4 border-indigo-400 flex items-center inline-block whitespace-nowrap text-ellipsis overflow-hidden ">
                    {user?.firstName}
                </span>
            </motion.span>

            <ul className="mt-4">
                {GROUPED_NAV_DATA.map(({title, link, Icon}) => (<li key={title} className="mb-2">
                    <NavLink
                        to={link}
                        className={" rounded-md side-link"}
                        onClick={onClickNavbar}
                    >
                        {({isActive}) => (<motion.span
                            key={`${title}_nav_item`}
                            className={isActive ? navItemDefaultClasses + " primary-self-text " : navItemDefaultClasses + " text-default-color "}
                            whileHover={{
                                color: "rgb(0, 102, 255)",
                                backgroundColor: "rgba(0, 102, 255, 0.1)",
                                translateX: isActive ? 0 : 4,
                                transition: {
                                    backgroundColor: {
                                        type: "spring", damping: 18,
                                    },
                                },
                            }}
                            whileTap={{scale: isActive ? 1 : 0.9}}
                        >
                            <Icon className="h-6 w-6 mr-4"/>
                            {title}
                        </motion.span>)}
                    </NavLink>
                </li>))}
            </ul>

            <hr/>

            <ul className="mt-4">
                {MAIN_NAV_DATA.map(({title, link, Icon}) => (<li key={title} className="mb-2">
                    <NavLink
                        to={link}
                        className={" rounded-md side-link"}
                        onClick={onClickNavbar}
                    >
                        {({isActive}) => (<motion.span
                            key={`${title}_nav_item`}
                            className={isActive ? navItemDefaultClasses + " primary-self-text " : navItemDefaultClasses + " text-default-color "}
                            whileHover={{
                                color: "rgb(0, 102, 255)",
                                backgroundColor: "rgba(0, 102, 255, 0.1)",
                                translateX: isActive ? 0 : 4,
                                transition: {
                                    backgroundColor: {
                                        type: "spring", damping: 18,
                                    },
                                },
                            }}
                            whileTap={{scale: isActive ? 1 : 0.9}}
                        >
                            <Icon className="h-6 w-6 mr-4"/>
                            {title}
                        </motion.span>)}
                    </NavLink>
                </li>))}
            </ul>

            <div className="mt-4">
                <motion.a
                    href={"#!"}
                    className="block px-4 py-2 rounded-md flex"
                    initial={{
                        color: "#EC7474", backgroundColor: "#FFEEEE",
                    }}
                    whileHover={{
                        translateX: 6, color: "#777", backgroundColor: "#dfdfdf", transition: {
                            backgroundColor: {
                                type: "spring", damping: 18,
                            },
                        },
                    }}
                    whileTap={{scale: 0.9}}
                    onClick={handleLogout}
                >
                    <DeleteIcon className="h-6 w-6 mr-4"/>
                    Log Out
                </motion.a>
            </div>
        </nav>
    </>);
}

export default Sidebar;