// actions/companySettingsActions.js
import axios from 'axios';
import base_url from "../utils/config";
import {
    fetchSettingsStart,
    fetchSettingsSuccess,
    fetchSettingsFailure,
    updateSettingsStart,
    updateSettingsSuccess,
    updateSettingsFailure,
} from '../store/companySettingsSlice';

export const fetchCompanySettings = (companyId) => async (dispatch) => {
    dispatch(fetchSettingsStart());
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
        };
        let response = await axios.get(`${base_url}/api/company-settings/${companyId}`, config);
        if (!response.data) {
            // If settings don't exist, create them
            response = await axios.post('/api/company-settings', { companyId }, config);
        }
        dispatch(fetchSettingsSuccess(response.data));
    } catch (error) {
        dispatch(fetchSettingsFailure(error.message));
    }
};

export const updateCompanySettings = (companyId, settings) => async (dispatch) => {
    dispatch(updateSettingsStart());
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            withCredentials: true,
        };
        const response = await axios.put(`${base_url}/api/company-settings/${companyId}`, settings, config);
        dispatch(updateSettingsSuccess(response.data));
        return response.data;
    } catch (error) {
        dispatch(updateSettingsFailure(error.message));
        throw error;
    }
};