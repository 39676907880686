/* eslint-disable no-useless-escape */
import React, {useCallback, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {
    getAllEventsSelector,
    getEditedIdForm,
    setEditedId,
    onConfirmEditEvent,
} from "../../store/eventSlice";
import {getAllTractorsSelector} from "../../store/tractorSlice";
import {getAllPartiesSelector} from "../../store/partySlice";
import {getAllDriversSelector} from '../../store/driverSlice';
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {updateEvent} from "../../actions/eventActions";
import {updateTractor} from "../../actions/tractorActions";
import StatusWidget from "../Widgets/StatusWidget";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";

const emptyForm = {
    id: "",
    user: "",
    tractor: "",
    driver: "",
    shipper: "",
    consignee: "",
    startDate: "",
    endDate: "",
    fuel: 0,
    unit: "",
    price: 0,
    tax: 0,
    startOdometer: 0,
    endOdometer: 0,
    mileage: 0,
};

function EventEditModal() {
    const dispatch = useDispatch();
    const editedID = useSelector(getEditedIdForm);
    const events = useSelector(getAllEventsSelector);
    const [currentTractorId, setCurrentTractorId] = useState(0);
    const allTractors = useSelector(getAllTractorsSelector || []);
    const allParties = useSelector(getAllPartiesSelector) || [];
    const allDrivers = useSelector(getAllDriversSelector) || [];
    const [widgetIndex, setWidgetIndex] = useState(0);

    const allShippers = Array.isArray(allParties)
        ? allParties.filter((cus) => cus.type === "SHIPPER")
        : [];

    const allConsignees = Array.isArray(allParties)
        ? allParties.filter((cus) => cus.type === "CONSIGNEE")
        : [];

    const [animate, setAnimate] = useState(true);
    const [eventForm, setEventForm] = useState(emptyForm);
    const [isTouched, setIsTouched] = useState(false);
    const [validForm, setValidForm] = useState(
        Object.keys(emptyForm).reduce((a, b) => {
            return {...a, [b]: false};
        }, {})
    );

    const onEditHandler = useCallback(() => {
        setIsTouched(true);
        const isValid = Object.keys(validForm).every((key) => validForm[key]);

        if (!isValid) {
            toast.error("Invalid Event Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        if (editedID) {
            if (eventForm.endOdometer > 0) {
                dispatch(updateTractor(currentTractorId, {odometerReading: eventForm.endOdometer}));
            }
            dispatch(updateEvent(editedID, eventForm))
                .then(() => {
                    toast.success("Successfully Updated Event", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                })
                .finally(() => {
                    dispatch(onConfirmEditEvent(eventForm));
                    setIsTouched(false);
                });
        }

    }, [dispatch, validForm, eventForm, editedID, currentTractorId]);

    const handlerEventValue = useCallback((event, keyName) => {
        let value = "";
        if (keyName === "startDate" || keyName === "endDate") {
            value = event.toISOString();
        } else {
            value = event.target.value;
        }

        setEventForm((prev) => {
            return {...prev, [keyName]: value};
        });
    }, []);


    const onCancelHandler = useCallback(() => {
        dispatch(setEditedId(null));
    }, [dispatch]);

    // useCallback(() => {}, [])

    useEffect(() => {
        if (eventForm.tractor) {
            const trac = allTractors.find((tractor) => {
                    return tractor._id === (eventForm.tractor?._id || eventForm.tractor);
                }
            );

            setCurrentTractorId(trac._id);
            eventForm.startOdometer = trac?.odometerReading;
        }

        if (eventForm.endOdometer && eventForm.startOdometer) {
            eventForm.mileage = eventForm.endOdometer - eventForm.startOdometer;
        }
    }, [eventForm, allTractors]);

    useEffect(() => {
        setValidForm({
            id: true,
            tractor: !!eventForm.tractor,
            driver: !!eventForm.driver,
            shipper: !!eventForm.shipper,
            consignee: !!eventForm.consignee,
        });
    }, [eventForm]);


    useEffect(() => {
        if (editedID !== null) {
            setAnimate(true);
            const isFindIndex = events.findIndex((event) => event._id === editedID);
            if (isFindIndex !== -1) {
                setEventForm({...events[isFindIndex]});
            }
        } else {
            setAnimate(false);
        }
    }, [events, editedID]);

    useEffect(() => {
        if (parseInt(eventForm.statusIndex) < 3) {
            setWidgetIndex(0);
        } else if (eventForm.statusIndex === "3") {
            setWidgetIndex(1)
        } else if (eventForm.statusIndex === "4") {
            setWidgetIndex(2)
        }
    }, [eventForm]);

    return editedID !== null ? (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: animate ? 1 : 0,
            }}
            transition={{
                type: "spring",
                damping: 18,
            }}
        >
            <div className="relative">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div
                            className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center w-full">
                                        <h3
                                            className="text-lg leading-6 font-medium text-gray-900"
                                            id="modal-title"
                                        >
                                            Edit Event
                                        </h3>
                                        <StatusWidget index={widgetIndex}/>
                                        <div className="grid grid-cols-2 space-x-3">
                                            <div className="relative mt-2">
                                                <div className="bg-white rounded-xl mt-4">

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Tractor
                                                        </div>
                                                        <div className="flex-1">
                                                            {eventForm.tractorId}
                                                            <Autocomplete
                                                                options={allTractors}
                                                                getOptionLabel={(option) => option.tractorID || ""}
                                                                value={allTractors.find(tractor => tractor._id === (eventForm.tractor?._id || eventForm.tractor)) || null}
                                                                onChange={(event, newValue) => {
                                                                    handlerEventValue({ target: { value: newValue ? newValue._id : '' } }, "tractor");
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Tractor"
                                                                        className={!validForm.tractor && isTouched ? defaultInputInvalidStyle : defaultInputStyle}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Driver Name
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                options={allDrivers}
                                                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}` || ""}
                                                                value={allDrivers.find(driver => driver._id === (eventForm.driver?._id || eventForm.driver)) || null}
                                                                onChange={(event, newValue) => {
                                                                    handlerEventValue({ target: { value: newValue ? newValue._id : '' } }, "driver");
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Driver"
                                                                        className={!validForm.driver && isTouched ? defaultInputInvalidStyle : defaultInputStyle}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Shipper:
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                options={allShippers}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                value={allShippers.find(shipper => shipper._id === (eventForm.shipper?._id || eventForm.shipper)) || null}
                                                                onChange={(event, newValue) => {
                                                                    handlerEventValue({ target: { value: newValue ? newValue._id : '' } }, "shipper");
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Shipper"
                                                                        className={!validForm.shipper && isTouched ? defaultInputInvalidStyle : defaultInputStyle}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Consignee:
                                                        </div>
                                                        <div className="flex-1">
                                                            <Autocomplete
                                                                options={allConsignees}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                value={allConsignees.find(consignee => consignee._id === (eventForm.consignee?._id || eventForm.consignee)) || null}
                                                                onChange={(event, newValue) => {
                                                                    handlerEventValue({ target: { value: newValue ? newValue._id : '' } }, "consignee");
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Consignee"
                                                                        className={!validForm.consignee && isTouched ? defaultInputInvalidStyle : defaultInputStyle}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="relative mt-2">
                                                <div className="bg-white rounded-xl mt-4">

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Amount of fuel used
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Fuel QTY"
                                                                className={
                                                                    !validForm.fuel && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={eventForm.fuel}
                                                                onChange={(e) => handlerEventValue(e, "fuel")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Fuel Units
                                                        </div>
                                                        <div className="flex-1">
                                                            <Select
                                                                value={eventForm.unit}
                                                                onChange={(e) => handlerEventValue(e, "unit")}
                                                                className={
                                                                    !validForm.unit && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                            >
                                                                <MenuItem value="litres">Litres</MenuItem>
                                                                <MenuItem value="gallons">Gallons</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Price
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Price"
                                                                className={
                                                                    !validForm.price && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={eventForm.price}
                                                                onChange={(e) => handlerEventValue(e, "price")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Starting Odometer
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Starting Odometer"
                                                                className={
                                                                    !validForm.startOdometer && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={eventForm.startOdometer}
                                                                readOnly={true}
                                                                onChange={(e) => handlerEventValue(e, "startOdometer")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Ending Odometer
                                                        </div>
                                                        <div className="flex-1">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="End Odometer"
                                                                className={
                                                                    !validForm.endOdometer && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={eventForm.endOdometer}
                                                                onChange={(e) => handlerEventValue(e, "endOdometer")}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onEditHandler}
                                >
                                    Confirm
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onCancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    ) : null;
}

export default EventEditModal;
