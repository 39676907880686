// src/components/InfoIcon.js
import React, { useRef, useState, useEffect } from 'react';

const InfoIcon = ({ message }) => {
    const iconRef = useRef(null);
    const [showBelow, setShowBelow] = useState(false);

    useEffect(() => {
        const checkPosition = () => {
            if (iconRef.current) {
                const rect = iconRef.current.getBoundingClientRect();
                const spaceAbove = rect.top;
                const spaceBelow = window.innerHeight - rect.bottom;
                setShowBelow(spaceAbove < 200 && spaceBelow > spaceAbove);
            }
        };

        checkPosition();
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', checkPosition);

        return () => {
            window.removeEventListener('scroll', checkPosition);
            window.removeEventListener('resize', checkPosition);
        };
    }, []);

    return (
        <div className="relative inline-block group" ref={iconRef}>
            <svg
                className="w-6 h-6 cursor-pointer text-blue-600"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
            >
                <path
                    fillRule="evenodd"
                    d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8 5a1 1 0 100-2 1 1 0 000 2zm-1-4V7a1 1 0 112 0v4a1 1 0 01-2 0z"
                    clipRule="evenodd"
                />
            </svg>
            <span
                className={`absolute z-10 w-48 p-2 text-white bg-black rounded opacity-0 
                ${showBelow ? 'top-full' : 'bottom-full'} 
                left-1/2 -translate-x-1/2 transition-opacity duration-300 
                group-hover:opacity-75 pointer-events-none`}
            >
                {message}
            </span>
        </div>
    );
};

export default InfoIcon;
