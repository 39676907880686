import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
  defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import { getAllConfirmationSelector, setDeleteId } from "../../store/confirmationSlice";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import InvoiceIcon from "../Icons/InvoiceIcon";
import { useAppContext } from "../../context/AppContext";
import EmptyBar from "../Common/EmptyBar";
import { fetchConfirmations, updateConfirmationStatus } from "../../actions/confirmationActions";

const itemsPerPage = 10;
const emptySearchForm = {
  confirmationNo: "",
  carrier: "",
};

const statuses = [
  { index: "1", name: "Draft", colorClass: "bg-gray-100 text-gray-600" },
  { index: "2", name: "Pending", colorClass: "bg-yellow-100 text-yellow-500" },
  { index: "3", name: "Confirmed", colorClass: "bg-green-200 text-green-600" },
];

function ConfirmationTable({ showAdvanceSearch = false }) {
  const { initLoading } = useAppContext();
  const dispatch = useDispatch();
  const allConfirmations = useSelector(getAllConfirmationSelector);
  const navigate = useNavigate();

  const [searchForm, setSearchForm] = useState(emptySearchForm);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const confirmations = useMemo(() => {
    let filterData = allConfirmations.length > 0 ? [...allConfirmations].reverse() : [];

    if (searchForm.confirmationNo?.trim()) {
      filterData = filterData.filter((confirmation) =>
          confirmation.confirmationNo.includes(searchForm.confirmationNo)
      );
    }

    if (searchForm.carrier?.trim()) {
      filterData = filterData.filter((confirmation) =>
          confirmation.carrier.toLowerCase().includes(searchForm.carrier.name.toLowerCase())
      );
    }

    return filterData;
  }, [allConfirmations, searchForm]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % confirmations.length;
    setItemOffset(newOffset);
  };

  const handleDelete = useCallback(
      (item) => {
        dispatch(setDeleteId(item._id));
      },
      [dispatch]
  );

  const handleEdit = useCallback(
      (item) => {
        navigate("/confirmations/" + item._id);
      },
      [navigate]
  );

  const handlerSearchValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setSearchForm((prev) => {
      return { ...prev, [keyName]: value };
    });

    setItemOffset(0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchConfirmations());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(confirmations.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(confirmations.length / itemsPerPage));
  }, [confirmations, itemOffset]);



  const handleIndexChange = (confirmation, status) => {
    dispatch(updateConfirmationStatus(confirmation._id, status.name, status.index));
  };

  return (
      <>
        {showAdvanceSearch === true && (
            <div className="bg-white rounded-xl px-3 py-3 mb-3">
              <div className="font-title mb-2">Advanced Search</div>
              <div className="flex w-full flex-col sm:flex-row">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                    <InvoiceIcon className="h-6 w-6 text-gray-400" />
                  </div>
                  <input
                      autoComplete="nope"
                      value={searchForm.confirmationNo}
                      placeholder="Confirmation No"
                      className={defaultSearchStyle}
                      onChange={(e) => handlerSearchValue(e, "confirmationNo")}
                  />
                </div>
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                      <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                      autoComplete="nope"
                      value={searchForm.carrier?.name}
                      placeholder="Carrier Name"
                      className={defaultSearchStyle}
                      onChange={(e) => handlerSearchValue(e, "carrierName")}
                  />
                </div>
              </div>
            </div>
        )}

        <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
          <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row">
            <div className="sm:text-left text-default-color font-title flex-1">
              Confirmation #
            </div>
            <div className="sm:text-left text-default-color font-title flex-1">
              Customer Name
            </div>
            <div className="sm:text-left text-default-color font-title flex-1">
              Status
            </div>
            <div className="sm:text-left text-default-color font-title flex-1">
              Amount
            </div>
            <div className="sm:text-left text-default-color font-title sm:w-11">
              Action
            </div>
          </div>

          <div>
            {currentItems &&
                currentItems.filter(confirmation => confirmation !== undefined && confirmation !== null).map((confirmation) => (
                    <div className={defaultTdWrapperStyle} key={`${confirmation._id}`}>
                      <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Confirmation #</div>
                        <div className={defaultTdContent}>
                    <span
                        className="whitespace-nowrap text-ellipsis overflow-hidden text-blue-500 cursor-pointer"
                        onClick={() => handleEdit(confirmation)}
                    >
                      {confirmation.confirmationNo}
                    </span>
                        </div>
                      </div>

                      <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Customer Name</div>
                        <div className={defaultTdContent}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {confirmation?.carrier?.carrierName || 'N/A'}
                    </span>
                        </div>
                      </div>
                      <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Status</div>
                        <div className={defaultTdContent}>
                          <Menu
                              menuButton={
                                <MenuButton>
                          <span
                              className={
                                  "whitespace-nowrap text-ellipsis overflow-hidden px-3 rounded-xl py-1 " +
                                  (confirmation.statusIndex === "2"
                                      ? "bg-yellow-100 text-yellow-500"
                                      : confirmation.statusIndex === "3"
                                          ? "bg-green-200 text-green-600"
                                          : "bg-gray-100 text-gray-600 ")
                              }
                          >
                            {confirmation.statusName}
                          </span>
                                </MenuButton>
                              }
                              transition
                          >
                            {statuses.map((status) => (
                                <MenuItem
                                    key={status.index}
                                    onClick={() => handleIndexChange(confirmation, status)}
                                    className={status.colorClass}
                                >
                                  {status.name}
                                </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </div>

                      <div className={defaultTdStyle}>
                        <div className={defaultTdContentTitleStyle}>Status</div>
                        <div className={defaultTdContent + " "}>
                    <span className="whitespace-nowrap text-ellipsis overflow-hidden ">
                      <NumberFormat
                          value={confirmation.agreedRate}
                          className=""
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value, props) => (
                              <span {...props}>{"$"}{value}</span>
                          )}
                      />
                    </span>
                        </div>
                      </div>

                      <div className={defaultTdActionStyle}>
                        <div className={defaultTdContentTitleStyle}>Action</div>
                        <div className={defaultTdContent}>
                          <Menu
                              menuButton={
                                <MenuButton>
                                  <div className="bg-gray-50 px-2 rounded-xl">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-blue-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                      <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                      />
                                    </svg>
                                  </div>
                                </MenuButton>
                              }
                              transition
                          >
                            <MenuItem onClick={() => handleEdit(confirmation)}>
                              Detail
                            </MenuItem>
                            <MenuItem onClick={() => handleDelete(confirmation)}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                ))}

            {confirmations.length <= 0 && !initLoading && (
                <EmptyBar title={"Confirmation"} />
            )}

            {confirmations.length > 0 && (
                <ReactPaginate
                    className="inline-flex items-center -space-x-px mt-2"
                    previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="<"
                    nextLabel={">"}
                    renderOnZeroPageCount={null}
                />
            )}
          </div>
        </div>
      </>
  );
}
export default ConfirmationTable;