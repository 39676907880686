// driverActions.js

import axios from "axios";
import { setAllDrivers } from "../store/driverSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createDriver = (driverForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedDriverForm = { ...driverForm, company: company};

    const response = await axios.post(
      `${base_url}/api/drivers`,
      updatedDriverForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Driver created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllDrivers(response.data));
  } catch (error) {
    console.error("Error creating client:", error);
  }
};

export const createDriverAccount = (driverId, password) => async () => {
  const company = getCurrentCompanyId();
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.post(
        `${base_url}/api/auth/register-driver`,
        { driverId, password, company: company },
        config
    );

    if (response.status === 201) {
      toast.success("Driver account created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  } catch (error) {
    let msg = error.response.data.message;
    toast.error(msg, {
      position: "bottom-center",
      autoClose: 2000,
    });
  }
};

export const fetchDriversSuccess = (data) => ({
  type: "FETCH_CARRIERS_SUCCESS",
  payload: data,
});

export const fetchDriversFailure = (error) => ({
  type: "FETCH_CARRIERS_FAILURE",
  payload: error,
});

// Action to fetch drivers by the authenticated user
export const fetchDrivers = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      return Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/drivers/company/${currentCompany}`,
      config,
    );
    dispatch(setAllDrivers(response.data));
  } catch (error) {
    console.error("Error fetching drivers:", error);
    // Handle error if needed
  }
};

export const deleteDriverSuccess = (driverId) => ({
  type: "DELETE_CARRIER_SUCCESS",
  payload: driverId,
});

export const deleteDriverFailure = (error) => ({
  type: "DELETE_CARRIER_FAILURE",
  payload: error,
});

export const deleteDriver = (driverId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/drivers/${driverId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteDriverSuccess(driverId));
  } catch (error) {
    console.error("Error deleting driver:", error);
    dispatch(deleteDriverFailure(error.message));
  }
};

export const saveDriverSuccess = (driver) => ({
  type: "SAVE_DRIVER_SUCCESS",
  payload: driver,
});

export const saveDriverFailure = (error) => ({
  type: "SAVE_DRIVER_FAILURE",
  payload: error,
});

export const updateDriver= (driverId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/drivers/${driverId}`, data, config);
    dispatch(fetchDrivers());
  } catch (error) {
    console.error("Error updating Driver:", error);
    dispatch(saveDriverFailure(error.message));
  }
};
