import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import SectionTitle from "../Common/SectionTitle";
import { useAppContext } from "../../context/AppContext";
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
    defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import { useDispatch } from 'react-redux';
import { createParty, fetchParties } from '../../actions/partyActions';
import SubmitButton from "../Button/SubmitButton";

const emptyForm = {
    name: "",
    billingAddress: "",
    city: "",
    province: "",
    postalCode: "",
    type: "",
};

function AddParty() {
    const [partyForm, setPartyForm] = useState(emptyForm);
    const [isTouched, setIsTouched] = useState(false);
    const { initLoading: isInitLoading } = useAppContext();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState("SHIPPER");

    const [validForm, setValidForm] = useState({
        name: false,
        billingAddress: false,
        city: false,
        province: false,
        postalCode: false,
        type: false,
    });

    const handlerPartyValue = (event, keyName) => {
        const value = event.target.value;
        setPartyForm((prev) => ({
            ...prev,
            [keyName]: value,
        }));
    };

    const submitHandler = async () => {
        setIsTouched(true);

        const isValid = Object.values(validForm).every(Boolean);

        if (!isValid) {
            toast.error("Invalid Party Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        const updatedPartyForm = {
            ...partyForm, type: selectedOption 
        }

        try {
            dispatch(createParty(updatedPartyForm));
            dispatch(fetchParties());

            setPartyForm(emptyForm);
            setIsTouched(false);
        } catch (error) {
            console.error("Error saving party:", error);
            toast.error("An error occurred. Please try again.", {
                position: "bottom-center",
                autoClose: 2000,
            });
        }
    };

    useEffect(() => {
        setValidForm({
          name: partyForm.name? partyForm.name.trim() !== "" : false,
          billingAddress: partyForm.billingAddress? partyForm.billingAddress.trim() !== "" : false,
          city: partyForm.city? partyForm.city.trim() !== "" : false,
          province: partyForm.province? partyForm.province.trim() !== "" : false,
          postalCode: partyForm.postalCode? partyForm.postalCode.trim() !== "" : false,
          type: selectedOption? selectedOption.trim() !== "" : false,
        });
    }, [partyForm, selectedOption]);

    
    return (
        <div className="bg-white rounded-xl p-4">
            <SectionTitle> Add Party </SectionTitle>
            <div className="flex mt-2">
                <div className="flex-1 pl-3">
                    <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                        <option value="SHIPPER">Shipper</option>
                        <option value="CONSIGNEE">Consignee</option>
                    </select>
                </div>
                <div className="flex-1">
                    {isInitLoading ? (
                        <Skeleton className={defaultSkeletonNormalStyle} />
                    ) : (
                        <div>
                            <input
                                autoComplete="nope"
                                value={partyForm.name}
                                placeholder="Party Name"
                                className={
                                    !validForm.name && isTouched
                                        ? defaultInputInvalidStyle
                                        : defaultInputStyle
                                }
                                onChange={(e) => handlerPartyValue(e, "name")}
                                disabled={isInitLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="flex mt-2">
                <div className="flex-1">
                    {isInitLoading ? (
                        <Skeleton className={defaultSkeletonNormalStyle} />
                    ) : (
                        <input
                            autoComplete="nope"
                            placeholder="Billing Address"
                            className={
                                !validForm.billingAddress && isTouched
                                    ? defaultInputInvalidStyle
                                    : defaultInputStyle
                            }
                            disabled={isInitLoading}
                            value={partyForm.billingAddress}
                            onChange={(e) => handlerPartyValue(e, "billingAddress")}
                        />
                    )}
                </div>
            </div>
            <div className="flex mt-2">
                <div className="flex-1">
                    {isInitLoading ? (
                        <Skeleton className={defaultSkeletonNormalStyle} />
                    ) : (
                        <input
                            autoComplete="nope"
                            placeholder="City"
                            className={
                                !validForm.city && isTouched
                                    ? defaultInputInvalidStyle
                                    : defaultInputStyle
                            }
                            disabled={isInitLoading}
                            value={partyForm.city}
                            onChange={(e) => handlerPartyValue(e, "city")}
                        />
                    )}
                </div>
            </div>
            <div className="flex mt-2">
                <div className="flex-1">
                    {isInitLoading ? (
                        <Skeleton className={defaultSkeletonNormalStyle} />
                    ) : (
                        <input
                            autoComplete="nope"
                            placeholder="State / Province"
                            className={
                                !validForm.province && isTouched
                                    ? defaultInputInvalidStyle
                                    : defaultInputStyle
                            }
                            disabled={isInitLoading}
                            value={partyForm.province}
                            onChange={(e) => handlerPartyValue(e, "province")}
                        />
                    )}
                </div>
            </div>
            <div className="flex mt-2">
                <div className="flex-1">
                    {isInitLoading ? (
                        <Skeleton className={defaultSkeletonNormalStyle} />
                    ) : (
                        <input
                            autoComplete="nope"
                            placeholder="Postal / Zip Code"
                            className={
                                !validForm.postalCode && isTouched
                                    ? defaultInputInvalidStyle
                                    : defaultInputStyle
                            }
                            disabled={isInitLoading}
                            value={partyForm.postalCode}
                            onChange={(e) => handlerPartyValue(e, "postalCode")}
                        />
                    )}
                </div>
            </div>

            <div className="mt-3">
                <SubmitButton onClick={submitHandler} />
            </div>
        </div>
    );
}

export default AddParty;
