import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  currentCompany: null,
  data: [],
  newForm: {
    id: nanoid(),
    firstName: '',
    lastName: '',
    email: '',
    role: 'user',
    company: [],
  },
  deletedID: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
        state.data = action.payload;
    },
    addNewUser: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        firstName: '',
        lastName: '',
        email: '',
        role: 'user',
        company: [], 
      };

      state.newForm = { ...reNewForm };
    },

    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },

    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
    setCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    updateUserForm: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (user) => user._id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    updateNewUserForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewUserFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllUsers: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setUser, addNewUser,updateUserForm, updateNewUserForm, updateNewUserFormField, setAllUsers, setCurrentUser, setCurrentCompany, clearCurrentUser } = userSlice.actions;

export const getUser = (state) => state.user.data;

export const getCurrentUser = (state) => state.user.currentUser;

export const getCurrentCompany = (state) => state.user.currentCompany;

export default userSlice.reducer;