/* eslint-disable no-useless-escape */
import React, {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import {useSelector, useDispatch} from "react-redux";
import {
    getAllCarriersSelector,
    getEditedIdForm,
    setEditedId,
    onConfirmEditCarrier,
} from "../../store/carrierSlice";
import {
    defaultInputStyle,
    defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import {updateCarrier} from "../../actions/carrierActions";

const emptyForm = {
    carrierId: "",
    carrierName: "",
    email: "",
    address: "",
    phoneNumber: "",
    mcNumber: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function CarrierEditModal() {
    const dispatch = useDispatch();
    const editedID = useSelector(getEditedIdForm);
    const carriers = useSelector(getAllCarriersSelector);
    const [animate, setAnimate] = useState(true);
    const [carrierForm, setCarrierForm] = useState(emptyForm);
    const [isTouched, setIsTouched] = useState(false);
    const [validForm, setValidForm] = useState(
        Object.keys(emptyForm).reduce((a, b) => {
            return {...a, [b]: false};
        }, {})
    );

    const onEditHandler = useCallback(() => {
        setIsTouched(true);
        const isValid = Object.keys(validForm).every((key) => validForm[key]);

        if (!isValid) {
            toast.error("Invalid Carrier Form!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }

        if (editedID) {
            dispatch(updateCarrier(editedID, carrierForm))
                .then(() => {
                    toast.success("Successfully Updated Carrier", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                })
                .finally(() => {
                    dispatch(onConfirmEditCarrier(carrierForm));
                    setIsTouched(false);
                });
        }

    }, [dispatch, validForm, carrierForm, editedID]);

    const handlerCarrierValue = useCallback((event, keyName) => {
        const value = event.target.value;

        setCarrierForm((prev) => {
            return {...prev, [keyName]: value};
        });
    }, []);


    const onCancelHandler = useCallback(() => {
        dispatch(setEditedId(null));
    }, [dispatch]);

    useEffect(() => {
        const isValidEmail = carrierForm?.email?.trim() && carrierForm?.email.match(emailRegex);

        setValidForm({
            carrierId: carrierForm.carrierId > 0,
            carrierName: carrierForm.carrierName ? carrierForm.carrierName.trim() !== "" : false,
            email: isValidEmail ? true : false,
            address: carrierForm.address ? carrierForm.address.trim() !== "" : false,
            phoneNumber: carrierForm.phoneNumber ? carrierForm.phoneNumber.trim() !== "" : false,
            mcNumber: carrierForm.mcNumber > 0,
        });
    }, [carrierForm]);

    useEffect(() => {
        if (editedID !== null) {
            setAnimate(true);
            const isFindIndex = carriers.findIndex((carrier) => carrier._id === editedID);
            if (isFindIndex !== -1) {
                setCarrierForm({...carriers[isFindIndex]});
            }
        } else {
            setAnimate(false);
        }
    }, [carriers, editedID]);

    return editedID !== null ? (
        <motion.div
            className="modal-container"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: animate ? 1 : 0,
            }}
            transition={{
                type: "spring",
                damping: 18,
            }}
        >
            <div className="relative">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <div
                            className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <h3
                                            className="text-lg leading-6 font-medium text-gray-900"
                                            id="modal-title"
                                        >
                                            Edit Carrier Form
                                        </h3>
                                        <div className="mt-2">
                                            {/*  */}
                                            <div className="bg-white rounded-xl mt-4">
                                                <div className="flex flex-row w-full ">
                                                    <div className="mt-2 w-[1/4]">
                                                        <div
                                                            className="font-title text-sm text-default-color">Carrier
                                                            ID
                                                        </div>
                                                        <div className="">
                                                            <input
                                                                autoComplete="nope"
                                                                type="number"
                                                                placeholder="Carrier ID"
                                                                className={
                                                                    !validForm.carrierId && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={carrierForm.carrierId || ""}
                                                                onChange={(e) => handlerCarrierValue(e, "carrierId")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 w-full">
                                                        <div className="font-title text-sm text-default-color">
                                                            Carrier Name
                                                        </div>
                                                        <div className="">
                                                            <input
                                                                autoComplete="nope"
                                                                type="text"
                                                                placeholder="Carrier Name"
                                                                className={
                                                                    !validForm.carrierName && isTouched
                                                                        ? defaultInputInvalidStyle
                                                                        : defaultInputStyle
                                                                }
                                                                value={carrierForm.carrierName || ""}
                                                                onChange={(e) => handlerCarrierValue(e, "carrierName")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">
                                                        Email
                                                    </div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        placeholder="example@email.com"
                                                        className={
                                                            !validForm.email && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={carrierForm.email || ""}
                                                        onChange={(e) => handlerCarrierValue(e, "email")}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <div className="font-title text-sm text-default-color">Address</div>
                                                    <input
                                                        autoComplete="nope"
                                                        type="text"
                                                        placeholder="Address"
                                                        className={
                                                            !validForm.address && isTouched
                                                                ? defaultInputInvalidStyle
                                                                : defaultInputStyle
                                                        }
                                                        value={carrierForm.address || ""}
                                                        onChange={(e) => handlerCarrierValue(e, "address")}
                                                    />
                                                </div>

                                                <div className="flex flex-row justify-center">
                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">MC Number
                                                        </div>
                                                        <input
                                                            autoComplete="nope"
                                                            type="number"
                                                            placeholder="MC Number"
                                                            className={
                                                                !validForm.mcNumber && isTouched
                                                                    ? defaultInputInvalidStyle
                                                                    : defaultInputStyle
                                                            }
                                                            value={carrierForm.mcNumber || ""}
                                                            onChange={(e) => handlerCarrierValue(e, "mcNumber")}
                                                        />
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="font-title text-sm text-default-color">
                                                            Phone Number
                                                        </div>
                                                        <input
                                                            autoComplete="nope"
                                                            type="text"
                                                            placeholder="Phone Number"
                                                            className={
                                                                !validForm.phoneNumber && isTouched
                                                                    ? defaultInputInvalidStyle
                                                                    : defaultInputStyle
                                                            }
                                                            value={carrierForm.phoneNumber || ""}
                                                            onChange={(e) => handlerCarrierValue(e, "phoneNumber")}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            {/*  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onEditHandler}
                                >
                                    Confirm
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={onCancelHandler}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    ) : null;
}

export default CarrierEditModal;
