import React from 'react';

const TabBar = ({ tabs, onTabClick, activeTab }) => {
  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {tabs.map((tab) => (
          <li className="me-2" key={tab.name}>
            <button
              className={`inline-flex items-center justify-center p-4 border-b-2 ${
                activeTab === tab.name
                  ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              } rounded-t-lg`}
              onClick={() => onTabClick(tab.name)}
            >
              <svg
                className={`w-4 h-4 me-2 ${
                  activeTab === tab.name
                    ? 'text-blue-600 dark:text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300'
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d={tab.iconPath} />
              </svg>
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabBar;