import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { motion } from "framer-motion";
import { setOpenUserAdder} from "../../store/companySlice";
import { getCurrentCompany } from "../../store/userSlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
  defaultInputLargeStyle,
  defaultInputLargeInvalidStyle,
  defaultSkeletonLargeStyle,
  defaultSkeletonNormalStyle,
} from "../../constants/defaultStyles";
import { useAppContext } from "../../context/AppContext";
import Skeleton from "react-loading-skeleton";
import SectionTitle from "../Common/SectionTitle";
import base_url from "../../utils/config";

const emptyForm = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "user",
};

function UserAddDialog() {
  const dispatch = useDispatch();
  const currentCompany = useSelector(getCurrentCompany);
  const { initLoading: isInitLoading } = useAppContext();
  const openModal = useSelector((state) => state.company.openUserAdder);
  const [userForm, setUserForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const handleInputChange = (event, keyName) => {
    const value = event.target.value;
    setUserForm((prev) => ({
      ...prev,
      [keyName]: value,
    }));
    setValidForm((prev) => ({
      ...prev,
      [keyName]: !!value,
    }));
  };

  const submitHandler = async () => {
    setIsTouched(true);

    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid User Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      const payload = {
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        email: userForm.email,
        password: userForm.password,
        role: userForm.role,
        company: [currentCompany._id],
      };

      const response = await axios.post(
        `${base_url}/api/auth/registerWithCompany`,
        payload,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("User saved successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });

        setUserForm(emptyForm);
        setIsTouched(false);
        dispatch(setOpenUserAdder(false));
      } else {
        toast.error("Failed to save user. Please try again.", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error saving user:", error);
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        errorMessage = error.response.data.message || errorMessage;
      }
      toast.error(errorMessage, {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    setValidForm({
      firstName: userForm.firstName.trim().length > 0,
      lastName: userForm.lastName.trim().length > 0,
      email: userForm.email.includes("@"),
      password: userForm.password.length >= 6,
      role: userForm.role.trim().length > 0,
    });
  }, [userForm]);

  return openModal ? (
    <motion.div
      className="modal-container fixed inset-0 z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="bg-white rounded-xl p-4 mt-4 z-10 w-full max-w-2xl">
        <SectionTitle>Add User</SectionTitle>
        <div className="flex mt-2">
          <div className="flex-1 pl-3">
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonLargeStyle} />
            ) : (
              <input
                value={userForm.firstName}
                placeholder="First Name"
                className={
                  !validForm.firstName && isTouched
                    ? defaultInputLargeInvalidStyle
                    : defaultInputLargeStyle
                }
                onChange={(e) => handleInputChange(e, "firstName")}
                disabled={isInitLoading}
              />
            )}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="flex-1">
            <SectionTitle>Last Name</SectionTitle>
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonNormalStyle} />
            ) : (
              <input
                value={userForm.lastName}
                placeholder="Last Name"
                className={
                  !validForm.lastName && isTouched
                    ? defaultInputInvalidStyle
                    : defaultInputStyle
                }
                onChange={(e) => handleInputChange(e, "lastName")}
                disabled={isInitLoading}
              />
            )}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="flex-1">
            <SectionTitle>Email</SectionTitle>
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonNormalStyle} />
            ) : (
              <input
                value={userForm.email}
                placeholder="Email"
                className={
                  !validForm.email && isTouched
                    ? defaultInputInvalidStyle
                    : defaultInputStyle
                }
                onChange={(e) => handleInputChange(e, "email")}
                disabled={isInitLoading}
              />
            )}
          </div>
        </div>

        <div className="flex mt-2">
          <div className="flex-1">
            <SectionTitle>Password</SectionTitle>
            {isInitLoading ? (
              <Skeleton className={defaultSkeletonNormalStyle} />
            ) : (
              <input
                value={userForm.password}
                type="password"
                placeholder="Password"
                className={
                  !validForm.password && isTouched
                    ? defaultInputInvalidStyle
                    : defaultInputStyle
                }
                onChange={(e) => handleInputChange(e, "password")}
                disabled={isInitLoading}
              />
            )}
          </div>
        </div>
        <div className="mt-2">
          <div className="font-title text-sm text-default-color">Role</div>
          <div className="flex">
            <div className="flex-1">
              <select
                value={userForm.role}
                onChange={(e) => handleInputChange(e, "role")}
                className="border rounded p-2 w-full"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={submitHandler}
          >
            Add
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => dispatch(setOpenUserAdder(false))}
          >
            Cancel
          </button>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default UserAddDialog;

