import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const EmployeeProfileModal = ({ open, onClose, user }) => {
    if (!user) return null;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{user.firstName}</DialogTitle>
            <DialogContent>
                <div className="flex flex-col">
                    <img
                        src={user.profilePicture}
                        alt={"profile"}
                        className="w-24 h-24 rounded-full mb-4"
                    />
                </div>
                <div className="mt-4">
                    <div className="flex flex-row space-x-10">
                        <div className="flex flex-col">
                            <p className="text-gray-400 text-sm">First Name</p>
                            <p><strong>{user.firstName}</strong></p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-gray-400 text-sm">Last Name</p>
                            <p><strong>{user.lastName}</strong></p>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <p className="text-gray-400 text-sm">Email</p>
                        <p><strong>{user.email}</strong></p>
                    </div>

                    <div className="flex flex-col">
                        <p className="text-gray-400 text-sm">Company Role</p>
                        <p><strong>{user.role}</strong></p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmployeeProfileModal;
