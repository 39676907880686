import React from "react";
import CustomerTable from "../../components/Customers/CustomerTable";
import PageTitle from "../../components/Common/PageTitle";
import QuickAddCustomer from "../../components/Customers/QuickAddCustomer";

function CustomerListScreen() {
  return (
    <div>
      <div className="p-4">
        <PageTitle title="Customers" />
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/6 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1">
          <CustomerTable showAdvanceSearch />
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
          <QuickAddCustomer />
        </div>
      </div>
    </div>
  );
}

export default CustomerListScreen;
