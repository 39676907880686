import React from "react";
import PageTitle from "../../components/Common/PageTitle";
import QuickAddTrailer from "../../components/Trailer/QuickAddTrailer";
import TrailerTable from "../../components/Trailer/TrailerTable";

function TrailerListScreen() {
  return (
    <div>
      <div className="p-4">
        <PageTitle title="Trailers" />
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/6 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1">
          <TrailerTable showAdvanceSearch />
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
          <QuickAddTrailer/>
        </div>
      </div>
    </div>
  );
}

export default TrailerListScreen;
