/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllDriversSelector,
  getEditedIdForm,
  setEditedId,
  onConfirmEditDriver,
} from "../../store/driverSlice";
import {
  defaultInputStyle,
  defaultInputInvalidStyle,
} from "../../constants/defaultStyles";
import { updateDriver } from "../../actions/driverActions";

const emptyForm = {
  firstName: "",
  lastName: "",
  email: "",
  ownerOperator: false,
  wage: 0,
  rate: 0,
  address: "",
  phoneNumber: "",
};

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

function DriverEditModal() {
  const dispatch = useDispatch();
  const editedID = useSelector(getEditedIdForm);
  const drivers = useSelector(getAllDriversSelector);
  const [animate, setAnimate] = useState(true);
  const [driverForm, setDriverForm] = useState(emptyForm);
  const [isTouched, setIsTouched] = useState(false);
  const [validForm, setValidForm] = useState(
    Object.keys(emptyForm).reduce((a, b) => {
      return { ...a, [b]: false };
    }, {})
  );

  const onEditHandler = useCallback(() => {
    setIsTouched(true);
    const isValid = Object.values(validForm).every(Boolean);

    if (!isValid) {
      toast.error("Invalid Driver Form!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    if(editedID){
      dispatch(updateDriver(editedID, driverForm))
       .then(() => {
         toast.success("Successfully Updated Driver", {
           position: "bottom-center",
           autoClose: 2000,
         });
       })
       .finally(() => {
         dispatch(onConfirmEditDriver(driverForm));         
          setIsTouched(false);
       });
    }
  }, [dispatch, validForm, driverForm, editedID]);

  const handlerDriverValue = (event, keyName) => {
    setDriverForm((prev) => ({
      ...prev,
      [keyName]: event.target.value,
    }));
  };


  const onCancelHandler = useCallback(() => {
    dispatch(setEditedId(null));
  }, [dispatch]);

  useEffect(() => {
    const isValidEmail = driverForm?.email?.trim() && driverForm?.email.match(emailRegex);
    
    setValidForm({
      driverId: driverForm.driverId > 0,
      firstName: driverForm.firstName? driverForm.firstName.trim() !== "" : false,
      lastName: driverForm.lastName? driverForm.lastName.trim() !== "" : false,
      email: isValidEmail ? true : false,
      ownerOperator: driverForm.ownerOperator !== undefined,
      wage: !driverForm.ownerOperator ? driverForm.wage >= 0 : true,
      rate: driverForm.ownerOperator ? driverForm.rate >= 0 : true,
      address: driverForm.address? driverForm.address.trim() !== "" : false,
      phoneNumber: driverForm.phoneNumber? driverForm.phoneNumber.trim() !== "" : false,
    });
  }, [driverForm]);

  useEffect(() => {
    if (editedID !== null) {
      setAnimate(true);
      const isFindIndex = drivers.findIndex((driver) => driver._id === editedID);
      if (isFindIndex !== -1) {
        setDriverForm({ ...drivers[isFindIndex] });
      }
    } else {
      setAnimate(false);
    }
  }, [drivers, editedID]);

  return editedID !== null ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Edit Driver
                    </h3>
                  <div className="grid grid-cols-2 space-x-3">

                    <div className="relative mt-2">
                      <div className="bg-white rounded-xl mt-4">
                        
                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Driver ID</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="number"
                              placeholder="Driver ID"
                              className={
                                !validForm.driverId && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.driverId || 0}
                              onChange={(e) => handlerDriverValue(e, "driverId")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">First Name</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="First Name"
                              className={
                                !validForm.firstName&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.firstName || ""}
                              onChange={(e) => handlerDriverValue(e, "firstName")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Last Name</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Last Name"
                              className={
                                !validForm.lastName&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.lastName || ""}
                              onChange={(e) => handlerDriverValue(e, "lastName")}
                            />
                          </div>
                        </div></div>

                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Email</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="email"
                              placeholder="Email"
                              className={
                                !validForm.email&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.email || ""}
                              onChange={(e) => handlerDriverValue(e, "email")}
                            />
                          </div>
                        </div></div>

                      </div>
                    </div>

                    <div className="relative mt-2">
                      <div className="bg-white rounded-xl mt-4"></div>                      

                        {!driverForm.ownerOperator &&
                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Wager $/hr</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="number"
                              placeholder="Wage"
                              min={0}
                              className={
                                !validForm.wage&& isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.wage || 0}
                              onChange={(e) => handlerDriverValue(e, "wage")}
                            />
                          </div>
                        </div></div>}

                        {driverForm.ownerOperator &&
                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Rate %</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="number"
                              placeholder="Rate"
                              min={0}
                              className={
                                !validForm.rate && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.rate || 0}
                              onChange={(e) => handlerDriverValue(e, "rate")}
                            />
                          </div>
                        </div></div>}

                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Address</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Address"
                              className={
                                !validForm.address && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.address || ""}
                              onChange={(e) => handlerDriverValue(e, "address")}
                            />
                          </div>
                        </div></div>
                        
                        <div className="mt-2">
                        <div className="font-title text-sm text-default-color">Phone Number</div>
                        <div className="flex">
                          <div className="flex-1">
                            <input
                              autoComplete="nope"
                              type="text"
                              placeholder="Phone Number"
                              className={
                                !validForm.phoneNumber && isTouched
                                  ? defaultInputInvalidStyle
                                  : defaultInputStyle
                              }
                              value={driverForm.phoneNumber || ""}
                              onChange={(e) => handlerDriverValue(e, "phoneNumber")}
                            />
                          </div>
                        </div></div>

                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onEditHandler}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default DriverEditModal;
