import React, { useMemo } from "react";
import { motion } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";
import RuleIcon from "@mui/icons-material/Rule";

function Button(props) {
  const {
    children,
    size = "",
    icon = "",
    block = false,
    outlined = false,
    secondary = false,
    success = false,
    danger = false,
  } = props;

  const { buttonClasses, IconComponent } = useMemo(() => {
    let defaultClasses =
      "rounded-xl font-title text-white flex flex-row items-center justify-center";
    let IconComponent = null;

    switch (icon) {
      case "plus":
        defaultClasses =
          "outline-none rounded-xl mr-1 ml-4 px-3 py-1 bg-transparent text-xs font-bold text-blue-500 uppercase focus:outline-none";
        IconComponent = AddIcon;
        break;
      case "select":
        defaultClasses =
          "outline-none rounded-xl mr-1 ml-4 px-3 py-1 bg-transparent text-xs font-bold text-blue-500 uppercase focus:outline-none";
        IconComponent = RuleIcon;
        break;

      default:
        break;
    }

    if (block) {
      defaultClasses += " w-full ";
    }

    if (size === "sm") {
      defaultClasses += " text-sm h-8 px-2 ";
    } else {
      defaultClasses += " h-12 px-4 ";
    }

    if (outlined) {
      if (secondary) {
        defaultClasses += " border-gray-400 border text-gray-600";
      } else if (success) {
        defaultClasses += " border-green-600 border text-green-500";
      } else if (danger) {
        defaultClasses += " border-red-500 border text-red-500";
      } else defaultClasses += " primary-self-text border-blue-400 border ";
    } else {
      if (secondary) {
        defaultClasses += " bg-gray-400 ";
      } else if (success) {
        defaultClasses += " bg-green-600 ";
      } else if (danger) {
        defaultClasses += " bg-red-500 ";
      } else defaultClasses += " primary-background-color ";
    }

    return { buttonClasses: defaultClasses, IconComponent };
  }, [block, danger, outlined, secondary, size, success, icon]);

  return (
    <motion.button
      whileHover={{
        scale: size === "sm" ? 1.02 : 1.04,
        transition: {
          type: "spring",
          damping: 15,
          duration: 0.1,
        },
      }}
      whileTap={{ scale: 0.9 }}
      type="button"
      className={buttonClasses}
      {...props}
    >
      {icon && <IconComponent />}
      {children}
    </motion.button>
  );
}

export default Button;
