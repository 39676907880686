import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
    getIsOpenLoadSelector,
    getAllLoadsSelector,
    setLoadSelector,
    setOpenLoadSelector,
    setDeleteId,
    setEditedId
} from "../../store/loadSlice";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";

import {
  defaultTdStyle,
  defaultTdActionStyle,
  defaultTdWrapperStyle,
  defaultTdContent,
  defaultTdContentTitleStyle,
  defaultSearchStyle,
} from "../../constants/defaultStyles";
import ReactPaginate from "react-paginate";
import Button from "../Button/Button";
import TractorIcon from "../Icons/TractorIcon";

// Example items, to simulate fetching from another resources.:w
const itemsPerPage = 6;
const emptySearchForm = {
  tractorId: "",
  driverName: "",
};

function LoadChooseModal() {
  const dispatch = useDispatch();
  const allLoads = useSelector(getAllLoadsSelector);
  const openModal = useSelector(getIsOpenLoadSelector);

  const [animate, setAnimate] = useState(true);
  const [searchForm, setSearchForm] = useState(emptySearchForm);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

   const loads = useMemo(() => {
        let filterData = allLoads.length > 0 ? [...allLoads].reverse() : [];
        if (searchForm.driverName?.trim()) {
            filterData = filterData.filter((load) =>
            load.driverName.toLowerCase().includes(searchForm.driverName.toLowerCase())
            );
        }

        if (searchForm.tractorId) {
            filterData = filterData.filter((load) =>{
            var str = load.tractorId + "";
            return str.includes(searchForm.tractorId);}
            );
        }
  
        return filterData;
    }, [allLoads, searchForm]);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % loads.length;
    setItemOffset(newOffset);
  };

  const handlerSearchValue = useCallback((event, keyName) => {
    const value = event.target.value;

    setSearchForm((prev) => {
      return { ...prev, [keyName]: value };
    });

    setItemOffset(0);
  }, []);

  const onCloseHandler = useCallback(() => {
    dispatch(setOpenLoadSelector(false));
  }, [dispatch]);

  const handleSelect = useCallback(
    (e, item) => {
      e.stopPropagation();
      dispatch(setLoadSelector(item.id));

    },
    [dispatch]
  );

  const handleDelete = (load) => {
    dispatch(setDeleteId(load._id));
  };

  const handleEdit = useCallback(
    (load) => {
      dispatch(setEditedId(load._id));
    },
    [dispatch]
  );

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(loads.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(loads.length / itemsPerPage));
  }, [loads, itemOffset]);

  useEffect(() => {
    if (openModal) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  }, [loads, openModal]);

  return openModal ? (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 18,
      }}
    >
      <div className="relative">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex justify-center min-h-full p-4 text-center">
            <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 flex flex-col w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex-1">
                <div className="rounded-xl px-3 py-3 mb-3">
                  <div className="font-title mb-2">Advanced Search</div>
                  <div className="flex w-full flex-col sm:flex-row">
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 sm:px-2">
                      <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                      <TractorIcon />
                      </div>
                      <input
                        autoComplete="nope"
                        value={searchForm.tractorId}
                        placeholder="Tractor Id"
                        className={defaultSearchStyle}
                        onChange={(e) => handlerSearchValue(e, "tractorId")}
                      />
                    </div>
                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 sm:px-2">
                      <div className="h-12 w-12 rounded-2xl bg-gray-100 mr-2 flex justify-center items-center">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        autoComplete="nope"
                        value={searchForm.driverName}
                        placeholder="Driver Name"
                        className={defaultSearchStyle}
                        onChange={(e) => handlerSearchValue(e, "driverName")}
                      />
                    </div>
        
                  </div>
                </div>

                <div className="sm:bg-white rounded-xl sm:px-3 sm:py-3">
                  <div className="hidden sm:flex invisible sm:visible w-full flex-col sm:flex-row  bg-gray-50 py-2 px-2 rounded-xl mb-1">
                    <div className="sm:text-left text-default-color font-title flex-1">
                      Tractor Id
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                      Mileage
                    </div>
                    <div className="sm:text-left text-default-color font-title flex-1">
                      Price
                    </div>
                    <div className="sm:text-left text-default-color font-title sm:w-11">
                      Action
                    </div>
                  </div>

                  <div>
                    {currentItems &&
                      currentItems.map((load, index) => (
                        <div className={defaultTdWrapperStyle} key={index}>
                          
                          <div className={defaultTdStyle}>
                            <div className={defaultTdContentTitleStyle}>
                              Tractor Id
                            </div>
                            <div className={defaultTdContent}>
                              <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                {load.tractorId}
                              </span>
                            </div>
                          </div>

                          <div className={defaultTdStyle}>
                            <div className={defaultTdContentTitleStyle}>
                              Mileage
                            </div>
                            <div className={defaultTdContent}>
                              <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                {load.mileage}
                              </span>
                            </div>
                          </div>

                          <div className={defaultTdStyle}>
                            <div className={defaultTdContentTitleStyle}>
                              Total Price
                            </div>
                            <div className={defaultTdContent}>
                              <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                {load.price + load.tax}{" "}
                              </span>
                            </div>
                          </div>

                          <div className={defaultTdActionStyle}>
                            <div className={defaultTdContentTitleStyle}>
                              Action
                            </div>
                            <div className={defaultTdContent}>
                              <Button
                                size="sm"
                                block={1}
                                onClick={e => handleSelect(e, load)}
                              >
                                Select
                              </Button>
                              <Menu
                                menuButton={
                                  <MenuButton>
                                    <div className="bg-gray-50 px-2 rounded-xl">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-blue-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                        />
                                      </svg>
                                    </div>
                                  </MenuButton>
                                }
                                transition
                              >
                                <MenuItem onClick={() => handleEdit(load)}>
                                  Edit
                                </MenuItem>
                                <MenuItem onClick={() => handleDelete(load)}>
                                  Delete
                                </MenuItem>
                              </Menu>                              
                            </div>
                          </div>
                        </div>
                      ))}

                    {loads.length > 0 && (
                      <ReactPaginate
                        className="inline-flex items-center -space-x-px mt-2"
                        previousLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        nextLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        pageLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        breakLinkClassName="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        activeLinkClassName="py-2 px-3 text-blue-600 bg-blue-50 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                        breakLabel="..."
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel="<"
                        nextLabel={">"}
                        renderOnZeroPageCount={null}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onCloseHandler}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}

export default LoadChooseModal;
