import React from "react";
import { motion } from "framer-motion";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { CheckCircle } from "@mui/icons-material";
import { Inventory2 } from "@mui/icons-material";


function StatusWidget({ index }) {
    const [text, setText] = React.useState("");

    const iconComponents = [
        <Inventory2 className="text-blue-500" />,
        <LocalShippingIcon className="text-blue-500" />,
        <CheckCircle className="text-blue-500" />,
    ];

    React.useEffect(() => {
        const statusTexts = ["Pending", "On The Way", "Delivered"];
        if (index >= 0 && index < statusTexts.length) {
            setText(statusTexts[index]);
        } else {
            setText("");
        }
    }, [index]);

    return (
        <motion.div
            className="status-widget"
            initial={{ opacity: 0 }}
            transition={{ duration: 1.0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="w-[90%] h-1 rounded mb-2 relative mt-[50px] bg-blue-300">
                <div className="absolute inset-0 bg-blue-300 rounded" style={{ width: '100%' }}></div>
                <div className="absolute inset-0 bg-blue-500 rounded" style={{ width: `${(index / 2) * 100}%` }}></div>
                <div
                    className="absolute flex flex-col top-[-35px] left-0 items-center transition-transform duration-300 ease-in-out"
                    style={{ width: `${(index) * 100}%`}}
                >
                    <div className="text-sm mb-1 text-blue-500 animate-bounce">{text}</div>
                    <div className="bg-blue-500 rounded-full w-5 h-5"></div>
                    {iconComponents[index]}
                </div>
            </div>
        </motion.div>
    );
}

export default StatusWidget;


