import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  data: [],
  newForm: {
    id: nanoid(),
    userId: "",
    tractor: "",
    date: "",
    fuel: "",
    unit: "",
    startOdometer: "",
    endOdometer: "",
    mileage: "",
  },
  editedID: null,
  deletedID: null,
};

export const mileageSlice = createSlice({
  name: "mileages",
  initialState,
  reducers: {
    addNewMileage: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        userId: "",
        tractor: "",
        date: "",
        fuel: "",
        unit: "",
        startOdometer: "",
        endOdometer: "",
        mileage: "",
      };

      state.newForm = { ...reNewForm };
    },

    updateNewMileageForm: (state, action) => {
      const userId = localStorage.getItem("userId");
      state.newForm = { ...action.payload, userId };
    },

    updateNewMileageFormField: (state, action) => {
      const userId = localStorage.getItem("userId");
      state.newForm = { ...state.newForm, userId };
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllMileages: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedMileage: (state) => {
      state.data = state.data.filter((mileage) => mileage.id !== state.deletedID);
      state.deletedID = null;
    },

    onConfirmEditMileage: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (mileage) => mileage.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenMileageSelector: (state, action) => {
      state.openMileageSelector = action.payload;
      if (!action.payload) {
        state.selectedMileage = null;
      }
    },

    setMileageSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (mileage) => mileage.id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedMileage = state.data[isFindIndex];
      }
    },
    onDeleteMileageSuccess: (state, action) => {
      const mileageId = action.payload;
      state.data = state.data.filter((mileage) => mileage.id !== mileageId);
    },

    onDeleteMileageFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewMileage,
  updateNewMileageForm,
  updateNewMileageFormField,
  setAllMileages,
  setDeleteId,
  setEditedId,
  onConfirmDeletedMileage,
  onConfirmEditMileage,
  setOpenMileageSelector,
  setMileageSelector,
  onDeleteMileageSuccess,
  onDeleteMileageFailure,
} = mileageSlice.actions;

export const getAllMileagesSelector = (state) => state.mileages.data;

export const getMileageNewForm = (state) => state.mileages.newForm;

export const getDeletedMileageForm = (state) => state.mileages.deletedID;

export const getEditedIdForm = (state) => state.mileages.editedID;

export const getIsOpenMileageSelector = (state) => state.mileages.openMileageSelector;

export const getSelectedMileage = (state) => state.mileages.selectedMileage;

export default mileageSlice.reducer;