import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { setOpenDriverAdder } from "../../store/companySlice";
import Skeleton from "react-loading-skeleton";
import SectionTitle from "../Common/SectionTitle";
import { fetchDrivers, createDriverAccount } from "../../actions/driverActions";
import {getAllDriversSelector} from "../../store/driverSlice";

const CreateDriverAccountDialog = () => {
    const dispatch = useDispatch();
    const openModal = useSelector((state) => state.company.openDriverAdder);
    const drivers = useSelector(getAllDriversSelector);
    const [selectedDriver, setSelectedDriver] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (openModal) {
            dispatch(fetchDrivers())
                .then(() => setIsLoading(false))
                .catch((error) => {
                    console.error("Error fetching drivers:", error);
                    toast.error("Failed to load drivers.", {
                        position: "bottom-center",
                        autoClose: 2000,
                    });
                    setIsLoading(false);
                });
        }
    }, [openModal, dispatch]);

    const submitHandler = async () => {
        if (!selectedDriver || !password) {
            toast.error("All fields are required!", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }
        if(password !== passwordConfirm) {
            toast.error("Passwords must match", {
                position: "bottom-center",
                autoClose: 2000,
            });
            return;
        }
        dispatch(createDriverAccount(selectedDriver, password));
    };

    return openModal ? (
        <motion.div
            className="modal-container fixed inset-0 z-50 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <div className="bg-white rounded-xl p-4 mt-4 z-10 w-full max-w-2xl">
                <SectionTitle>Create Driver Account</SectionTitle>
                {isLoading ? (
                    <Skeleton className="h-10 w-full mb-4" />
                ) : (
                    <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Select Driver</label>
                        <select
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            value={selectedDriver}
                            onChange={(e) => setSelectedDriver(e.target.value)}
                        >
                            <option value="">Select Driver</option>
                            {drivers.map((driver) => (
                                <option key={driver._id} value={driver._id}>
                                    {driver.firstName} {driver.lastName} ({driver.email})
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Password</label>
                    <input
                        type="password"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <label className="block text-sm font-medium text-gray-700">Repeat Password</label>
                    <input
                        type="password"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                </div>

                <div className="mt-4 flex justify-end">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={submitHandler}
                    >
                        Create
                    </button>
                    <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        onClick={() => dispatch(setOpenDriverAdder(false))}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </motion.div>
    ) : null;
};

export default CreateDriverAccountDialog;
