

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardTabs from "../components/Dashboard/DashboardTabs";
import EmptyBar from "../components/Common/EmptyBar";
import { fetchInvoiceCount } from "../actions/invoiceActions";
import {Box, CircularProgress} from "@mui/material";

function DashboardScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [hasInvoices, setHasInvoices] = useState(false);

    useEffect(() => {
        const loadInvoiceCount = async () => {
            try {
                const count = await dispatch(fetchInvoiceCount());
                setHasInvoices(count > 0);
            } catch (error) {
                console.error("Failed to fetch invoice count:", error);
            } finally {
                setLoading(false);
            }
        };

        loadInvoiceCount();
    }, [dispatch]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <text> loading... </text>
                <CircularProgress />
            </Box>
        );
    }
    return (
        <div>
            <div className="p-4">
                {hasInvoices ? (
                    <DashboardTabs />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <EmptyBar title="Invoices" />
                        <p className="mt-4 text-lg text-gray-600">
                            Start creating invoices to see your dashboard come to life!
                        </p>
                        <p className="mt-4 text-lg text-gray-600">
                            You need to select / add a company in the settings to get started!
                        </p>
                        <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            onClick={() => navigate("/settings/company")}
                        >
                            Go to settings
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DashboardScreen;