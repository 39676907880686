// src/components/GetRates.js
import React from 'react';

import { motion } from 'framer-motion';
import {ArrowDownward, ArrowUpward, SentimentNeutral} from "@mui/icons-material";

const GetRates = ({ rates }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, translateX: 30 }}
            transition={{ duration: 0.8 }}
            className="mt-4 p-4 border rounded"
        >
            <h2 className="text-lg font-semibold mb-4">Estimated Rate</h2>
            <div className="flex justify-between items-center mb-2">
                <span className="text-orange-500 flex items-center">
                    <ArrowDownward className="mr-2" /> Low: ${rates.low}
                </span>
                <span className="text-gray-700 flex items-center">
                    <SentimentNeutral className="mr-2" /> Median: ${rates.median}
                </span>
                <span className="text-green-500 flex items-center">
                    <ArrowUpward className="mr-2" /> High: ${rates.high}
                </span>
            </div>
        </motion.div>
    );
};

export default GetRates;
