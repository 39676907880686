import { createSlice, nanoid } from "@reduxjs/toolkit";
import imageData from "../shared/imageData.json";
import colorData from "../shared/colorData.json";

const initialState = {
  isConfirmModal: false,
  isConfirm: false,
  settingOpen: false,
  defaultColor: colorData[0],
  defaultBgImage: imageData[0],
  colors: colorData,
  images: imageData,
  data: [],
  detailList: [],
  deletedID: null,
  currentEditedID: null,
  carrierTerms:"",
  stops:[],
  newForm: {
    id: nanoid(),
    confirmationNo: "",
    statusIndex: "1",
    statusName: "Draft",
    totalAmount: 0,
    color: colorData[0],
    backgroundImage: imageData[0],
    dueDate: "",
    createdDate: "",
    currencyUnit: "$",
    stops: [
      {
        id: 1,
        party: null,
        scheduledTime: ''
      }
    ],
    carrier: {
      id: "",
      carrierName: "",
      lastName: "",
      ownerOperator: "",
      wage: 0,
      company: "",
    },
    products: [
      {
        id: "",
        name: "",
        productID: "",
        quantity: 1,
      },
    ],
    taxes: [],
  },
};

export const confirmationSlice = createSlice({
  name: "confirmations",
  initialState,
  reducers: {
    addNewConfirmation: (state, action) => {
      const newDatas = [...state.data, action.payload];
      state.data = newDatas;

      const reNewForm = {
        newForm: {
          id: nanoid(),
          confirmationNo: "",
          statusIndex: "",
          statusName: "Draft",
          color: colorData[0],
          backgroundImage: imageData[0],
          dueDate: "",
          createdDate: "",
          currencyUnit: "$",
          stops: [
            {
              id: 1,
              party: null,
              scheduledTime: ''
            }
          ],
          carrier: {
            id: "",
            carrierName: "",
            address: "",
            mcNumber: "",
            phoneNumber: "",
            email: "",
          },
          products: [
            {
              amount: 1,
              id: "",
              name: "",
              productID: "",
              quantity: 1,
            },
          ],
          taxes: [],
          showCustomer: false,
        },
      };
      state.newForm = { ...reNewForm };
    },
    setAllConfirmation: (state, action) => {
      state.data = [...action.payload];
    },

    setAllConfirmationDetailList: (state, action) => {
      state.detailList = [...action.payload];
    },

    setDefaultColor: (state, action) => {
      const newColor = action.payload;
      state.defaultColor = newColor;
    },

    setDefaultBackground: (state, action) => {
      const newBackground = action.payload;
      state.defaultBgImage = newBackground;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.currentEditedID = action.payload;
    },

    onConfirmDeletedConfirmation: (state, action) => {
      const newDatas = state.data.filter(
        (confirmation) => confirmation._id !== state.deletedID
      );
      state.data = newDatas;
      state.deletedID = null;
    },

    onConfirmEditConfirmation: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (product) => product.id === state.currentEditedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.currentEditedID = null;
    },

    updateNewConfirmationFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    updateNewConfirmationForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateExisitingConfirmationForm: (state, action) => {
      const {
        id,
        confirmationNo,
        statusIndex,
        statusName,
        totalAmount,
        dueDate,
        createdDate,
        pickupDate,
        dropoffDate,
      } = action.payload;

      const findIndexOfList = state.data.findIndex(
        (product) => product.id === id
      );

      const newConfirmation = {
        id,
        confirmationNo,
        statusIndex,
        statusName,
        totalAmount,
        dueDate,
        createdDate,
        pickupDate,
        dropoffDate,
      };

      if (findIndexOfList !== -1) {
        state.data[findIndexOfList] = { ...newConfirmation };
      }
      const findIndexOfDetail = state.detailList.findIndex(
        (product) => product.id === id
      );

      if (findIndexOfDetail !== -1) {
        state.detailList[findIndexOfDetail] = { ...action.payload };
      }
    },

    setSettingModalOpen: (state, action) => {
      state.settingOpen = action.payload;
    },

    setConfirmModalOpen: (state, action) => {
      state.isConfirmModal = action.payload;
    },

    setIsConfirm: (state, action) => {
      state.isConfirm = action.payload;
    },
    addStop: (state, action) => {
      const newStop = {
        id: nanoid(),
        type: state.stops.length % 2 === 0 ? 'pickup' : 'dropoff',
        party: null,
        scheduledTime: '',
      };
      state.stops.push(newStop);
    },

    removeStop: (state, action) => {
      state.stops = state.stops.filter(stop => stop.id !== action.payload);
    },

    updateStop: (state, action) => {
      const { stopId, data } = action.payload;
      const stopIndex = state.stops.findIndex(stop => stop.id === stopId);
      if (stopIndex !== -1) {
        state.stops[stopIndex] = { ...state.stops[stopIndex], ...data };
      }
    },
  },
});

export const {
  setAllConfirmation,
  addNewConfirmation,
  setAllConfirmationDetailList,
  setDefaultColor,
  setDefaultBackground,
  setDeleteId,
  setEditedId,
  setSettingModalOpen,
  setConfirmModalOpen,
  setIsConfirm,
  onConfirmDeletedConfirmation,
  onConfirmEditConfirmation,
  updateNewConfirmationForm,
  updateNewConfirmationFormField,
  updateExisitingConfirmationForm,
    addStop,
    removeStop,
    updateStop
} = confirmationSlice.actions;

export const getAllConfirmationSelector = (state) => state.confirmations.data;

export const getAllColorSelector = (state) => state.confirmations.colors;

export const getAllImageSelector = (state) => state.confirmations.images;

export const getCurrentBGImage = (state) => state.confirmations.defaultBgImage;

export const getCurrentColor = (state) => state.confirmations.defaultColor;

export const getAllConfirmationDetailSelector = (state) =>
  state.confirmations.detailList;

export const getConfirmationDetailByID = (id) => (state) =>
  state.confirmations.detailList.find((detail) => detail._id === id);

export const getDeletedConfirmationForm = (state) =>
  state.confirmations.deletedID;

export const getConfirmationNewForm = (state) => state.confirmations.newForm;

export const getConfirmationSettingModal = (state) =>
  state.confirmations.settingOpen;

export const getIsConfirmationConfirmModal = (state) => {
  return state?.confirmations?.isConfirmModal ?? false;
};

export const getIsConfirm = (state) => state.confirmations.isConfirm;

export const getStopCount = (state) => state.confirmations.stops.length;

export const getAllStops = (state) => state.confirmations.stops;

export default confirmationSlice.reducer;
