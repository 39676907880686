// ErrorPage.jsx
import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import { toast } from 'react-toastify';

const ErrorPage = () => {
    const [bugReport, setBugReport] = useState({
        name: '',
        email: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBugReport({ ...bugReport, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/bug-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bugReport),
            });
            if (response.ok) {
                toast.success('Bug report submitted successfully!');
                setBugReport({ name: '', email: '', description: '' });
            } else {
                toast.error('Failed to submit bug report.');
            }
        } catch (error) {
            toast.error('An error occurred while submitting the bug report.');
        }
    };

    return (
        <Container>
            <Typography variant="h3" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="body1" gutterBottom>
                Sorry, the page you are looking for does not exist.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Submit a Bug Report
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    name="name"
                    value={bugReport.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="error-name"
                />
                <TextField
                    label="Email"
                    name="email"
                    value={bugReport.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    id="error-email"
                />
                <TextField
                    label="Description"
                    name="description"
                    value={bugReport.description}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    id="error-description"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default ErrorPage;