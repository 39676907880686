import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openCarrierSelector: false,
  selectedCarrier: null,
  data: [],
  newForm: {
    isCompany: false,
    id: nanoid(),
    carrierId: 0,
    carrierName: "",
    lastName: "",
    ownerOperator: "",
    wage: 0,
    address: "",
    phoneNumber: "",
    company: null,
  },
  editedID: null,
  deletedID: null,
};

export const carriersSlice = createSlice({
  name: "carriers",
  initialState,
  reducers: {
    addNewCarrier: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        isCompany: false,
        id: nanoid(),
        carrierId: 0,
        carrierName: "",
        lastName: "",
        ownerOperator: "",
        wage: 0,
        address: "",
        phoneNumber: String,
        company: null,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewCarrierForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewCarrierFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllCarriers: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedCarrier: (state) => {
      state.data = state.data.filter(
        (carrier) => carrier.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditCarrier: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (carrier) => carrier.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenCarrierSelector: (state, action) => {
      state.openCarrierSelector = action.payload;
      if (!action.payload) {
        state.selectedCarrier = null;
      }
    },

    setCarrierSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (carrier) => carrier._id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedCarrier = state.data[isFindIndex];
      }
    },
    onDeleteCarrierSuccess: (state, action) => {
      const carrierId = action.payload;
      state.data = state.data.filter((carrier) => carrier.id !== carrierId);
    },

    onDeleteCarrierFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  addNewCarrier,
  updateNewCarrierForm,
  updateNewCarrierFormField,
  setAllCarriers,
  setDeleteId,
  setEditedId,
  onConfirmDeletedCarrier,
  onConfirmEditCarrier,
  setOpenCarrierSelector,
  setCarrierSelector,
  onDeleteCarrierSuccess,
  onDeleteCarrierFailure,
} = carriersSlice.actions;

export const getAllCarriersSelector = (state) => state.carriers.data;

export const getCarrierNewForm = (state) => state.carriers.newForm;

export const getDeletedCarrierForm = (state) => state.carriers.deletedID;

export const getEditedIdForm = (state) => state.carriers.editedID;

export const getIsOpenCarrierSelector = (state) =>
  state.carriers.openCarrierSelector;

export const getSelectedCarrier = (state) => state.carriers.selectedCarrier;

export default carriersSlice.reducer;
