// carrierActions.js

import axios from "axios";
import { setAllCarriers } from "../store/carrierSlice";
import { toast } from "react-toastify";
import { getCurrentCompanyId } from "../utils/storage";
import base_url from "../utils/config";

export const createCarrier = (carrierForm) => async (dispatch) => {
  try {
    const company = getCurrentCompanyId();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };
    const updatedCarrierForm = { ...carrierForm, company: company};

    const response = await axios.post(
      `${base_url}/api/carriers`,
      updatedCarrierForm,
      config,
    );
    if (response.status === 200) {
      toast.success("Carrier created successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }

    dispatch(setAllCarriers(response.data));
  } catch (error) {
    console.error("Error creating client:", error);
  }
};

export const fetchCarriersSuccess = (data) => ({
  type: "FETCH_CARRIERS_SUCCESS",
  payload: data,
});

export const fetchCarriersFailure = (error) => ({
  type: "FETCH_CARRIERS_FAILURE",
  payload: error,
});

// Action to fetch carriers by the authenticated user
export const fetchCarriers = () => async (dispatch) => {
  try {
    // Use the selector to get the current company
    const currentCompany = getCurrentCompanyId();

    if (!currentCompany) {
      throw new Error("No current company found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      withCredentials: true,
    };

    const response = await axios.get(
      `${base_url}/api/carriers/company/${currentCompany}`,
      config,
    );
    dispatch(setAllCarriers(response.data));
  } catch (error) {
    console.error("Error fetching carriers:", error);
    // Handle error if needed
  }
};

export const deleteCarrierSuccess = (carrierId) => ({
  type: "DELETE_CARRIER_SUCCESS",
  payload: carrierId,
});

export const deleteCarrierFailure = (error) => ({
  type: "DELETE_CARRIER_FAILURE",
  payload: error,
});

export const deleteCarrier = (carrierId) => async (dispatch) => {
  try {
    await axios.delete(`${base_url}/api/carriers/${carrierId}`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    dispatch(deleteCarrierSuccess(carrierId));
  } catch (error) {
    console.error("Error deleting carrier:", error);
    dispatch(deleteCarrierFailure(error.message));
  }
};

export const updateCarrierSuccess = (carrierId) => ({
  type: "UPDATE_CARRIER_SUCCESS",
  payload: carrierId,
});

export const updateCarrierFailure = (error) => ({
  type: "UPDATE_CARRIER_FAILURE",
  payload: error,
});

export const updateCarrier = (carrierId, data) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      withCredentials: true,
    };
    await axios.put(`${base_url}/api/carriers/${carrierId}`, data, config);
    dispatch(fetchCarriers());
  } catch (error) {
    console.error("Error updating Carrier:", error);
    dispatch(updateCarrierFailure(error.message));
  }
};