import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ReferenceLine,
} from 'recharts';
import { Typography, Box, Paper } from '@mui/material';
import { MonetizationOn, Assessment, BarChart as BarChartIcon } from '@mui/icons-material';
import {
  fetchTopCustomersRevenueTrends,
  fetchCostAnalysis,
  fetchProfitMargins
} from '../../actions/invoiceActions';

const FinancialOverview = () => {
  const dispatch = useDispatch();
  const [topCustomersRevenueTrends, setTopCustomersRevenueTrends] = useState([]);
  const [costAnalysis, setCostAnalysis] = useState([]);
  const [profitMargins, setProfitMargins] = useState([]);

  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const trendsData = await dispatch(fetchTopCustomersRevenueTrends());
        setTopCustomersRevenueTrends(trendsData);

        const costData = await dispatch(fetchCostAnalysis());
        setCostAnalysis(costData);

        const profitData = await dispatch(fetchProfitMargins());
        setProfitMargins(profitData);
      } catch (error) {
        console.error("Error fetching financial data:", error);
      }
    };

    fetchFinancialData();
  }, [dispatch]);

  return (
      <div>
        <Typography variant="h5" gutterBottom className="text-blue-600">
          <Assessment fontSize="large" style={{ verticalAlign: 'middle', marginRight: 8 }} />
          Financial Overview
        </Typography>
        <Box mb={4} component={Paper} elevation={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
          <Typography variant="h6" gutterBottom>
            <MonetizationOn fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
            Top 3 Customers Revenue Trends
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            This line chart shows the revenue trends over time for the top 3 customers. Each line represents a customer, and the y-axis shows the revenue in dollars.
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" label={{ value: 'Date', position: 'insideBottomRight', offset: -5 }} />
              <YAxis label={{ value: 'Revenue ($)', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend />
              {topCustomersRevenueTrends.map((customerData, index) => (
                  <Line
                      key={customerData.customer}
                      type="monotone"
                      data={customerData.data}
                      dataKey="revenue"
                      name={customerData.customer}
                      stroke={index === 0 ? "#8884d8" : index === 1 ? "#82ca9d" : "#ffc658"}
                  />
              ))}
              <ReferenceLine y={0} stroke="#000" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4">
            <Box component={Paper} elevation={3} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
              <Typography variant="h6" gutterBottom>
                <BarChartIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                Cost Analysis
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                This stacked bar chart represents the cost analysis for each load. It includes the total costs associated with each load, distinguishing between profit and carrier cost.
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={costAnalysis}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="invoiceName" label={{ value: 'Invoices', position: 'insideBottomRight', offset: -5 }} />
                  <YAxis label={{ value: 'Amount ($)', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="profit" stackId="a" fill="#82ca9d" />
                  <Bar dataKey="carrierCost" stackId="a" fill="#ff6666" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <Box component={Paper} elevation={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: 20 }}>
              <Typography variant="h6" gutterBottom>
                <MonetizationOn fontSize="small" style={{ verticalAlign: 'middle', marginRight: 8 }} className="text-blue-600" />
                Profit Margins
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                This bar chart shows the profit margins for each load. The profit is calculated as the revenue minus the total costs.
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={profitMargins}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="loadId" label={{ value: 'Loads', position: 'insideBottomCenter'}} />
                  <YAxis label={{ value: 'Profit ($)', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="profit" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </div>
        </div>
      </div>
  );
};

export default FinancialOverview;
