import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const initialState = {
  openEventSelector: false,
  selectedEvent: null,
  data: [],
  pendingEvents: [],
  newForm: {
    id: nanoid(),
    title: "",
    description: "",
    start: "",
    end: "",
    completed: false,
    isView:false,
    completedDate: "",
    tractor: null,
    carrier: null,
    shipper: null,
    consignee: null,
    company: null,
    fuel: 0,
    unit: "",
    price: 0,
    tax: 0,
    startOdometer: 0,
    endOdometer: 0,
    mileage: 0,
  },
  editedID: null,
  deletedID: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    addNewEvent: (state, action) => {
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        title: "",
        description: "",
        start: "",
        end: "",
        completed: false,
        isView: false,
        completedDate: "",
        tractor: null,
        carrier: null,
        shipper: null,
        consignee: null,
        company: null,
        fuel: 0,
        unit: "",
        price: 0,
        tax: 0,
        startOdometer: 0,
        endOdometer: 0,
        mileage: 0,
      };

      state.newForm = { ...reNewForm };
    },

    updateNewEventForm: (state, action) => {
      state.newForm = { ...action.payload };
    },

    updateNewEventFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },

    setAllEvents: (state, action) => {
      state.data = action.payload;
    },

    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },

    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },

    onConfirmDeletedEvent: (state) => {
      state.data = state.data.filter(
        (event) => event.id !== state.deletedID
      );
      state.deletedID = null;
    },

    onConfirmEditEvent: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (event) => event.id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },

    setOpenEventSelector: (state, action) => {
      state.openEventSelector = action.payload;
      if (!action.payload) {
        state.selectedEvent = null;
      }
    },

    setEventSelector: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (event) => event.id === action.payload
      );
      if (isFindIndex !== -1) {
        state.selectedEvent = state.data[isFindIndex];
      }
    },
    markEventPendingSuccess: (state, action) => {
      const eventIndex = state.data.findIndex(event => event.id === action.payload.id);
      if (eventIndex !== -1) {
        state.data[eventIndex] = action.payload;
      }
      state.pendingEvents.push(action.payload);
      state.error = null;
    },

    markEventPendingFailure: (state, action) => {
      state.error = action.payload;
    },

    setPendingEvents: (state, action) => {
      state.pendingEvents = action.payload;
      state.error = null;
    },

    getPendingEventsFailure: (state, action) => {
      state.error = action.payload;
    },

    clearPendingEvents: (state) => {
      state.pendingEvents = [];
    },
    onDeleteEventSuccess: (state, action) => {
      const eventId = action.payload;
      state.data = state.data.filter((event) => event.id !== eventId);
    },

  },
});

export const {
  addNewEvent,
  updateNewEventForm,
  updateNewEventFormField,
  setAllEvents,
  setDeleteId,
  setEditedId,
  onConfirmDeletedEvent,
  onConfirmEditEvent,
  setOpenEventSelector,
  setEventSelector,
  markEventPendingSuccess,
  markEventPendingFailure,
  setPendingEvents,
  getPendingEventsFailure,
  clearPendingEvents,
  onDeleteEventSuccess,
  onDeleteEventFailure,
} = eventsSlice.actions;

export const getAllEventsSelector = (state) => state.events.data;

export const getEventNewForm = (state) => state.events.newForm;

export const getDeletedEventForm = (state) => state.events.deletedID;

export const getEditedIdForm = (state) => state.events.editedID;

export const getIsOpenEventSelector = (state) =>
  state.events.openEventSelector;

export const getPendingEventsSelector = (state) => state.events.pendingEvents;

export const getSelectedEvent = (state) => state.events.selectedEvent;

export default eventsSlice.reducer;
