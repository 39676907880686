import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { nanoid } from "nanoid";
import {getCurrentCompanyId} from "../utils/storage";

const initialState = {
  selectedCompany: null,
  openCompanySelector: false,
  openUserAdder: false,
  openDriverAdder: false,
  openAddCompany: false,
  currentCompany: null,
  status: 'idle',
  data: [],
  newForm: {
    id: nanoid(),
    image: "",
    billingAddress: "",
    companyName: "",
    companyEmail: "",
    companyMobile: "",
    country: "",
    province: "",
    city: "",
    postalCode:"",
    fuelSurcharge: 0,
    users: [],
  },
  editedID: null,
  deletedID: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    addNewCompany: (state, action) => {
      state.data.push(action.payload);
      state.data = [...state.data, action.payload];

      const reNewForm = {
        id: nanoid(),
        image: "",
        billingAddress: "",
        companyName: "",
        companyEmail: "",
        companyMobile: "",
        country: "",
        province: "",
        city: "",
        postalCode:"",
        fuelSurcharge: 0,
        users: [],
      };

      state.newForm = { ...reNewForm };
    },
    updateCompanyForm: (state, action) => {
      const companyIndex = state.data.findIndex(
        (company) => company._id === action.payload.companyId
      );
      if (companyIndex >= 0) {
        state.data[companyIndex] = action.payload;
      }
    },
    setDefaultCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    updateNewCompanyForm: (state, action) => {
      state.newForm = { ...action.payload };
    },
    updateNewCompanyFormField: (state, action) => {
      state.newForm[action.payload.key] = action.payload.value;
    },
    setAllCompanies: (state, action) => {
      state.data = action.payload;
    },
    setOpenUserAdder: (state, action) => {
      state.openUserAdder = action.payload;
    },
    setOpenDriverAdder: (state, action) => {
      state.openDriverAdder = action.payload;
    },
    setOpenAddCompany: (state, action) => {
      state.openAddCompany= action.payload;
    },
    setUsers: (state, action) => {
      const { users } = action.payload;
      const companyId = getCurrentCompanyId();
      const company = state.data.find((company) => company._id === companyId);
      if (company) {
        company.users = users;
      } else {
        console.error(`Company with ID ${companyId} not found in state`);
      }
    },
    addUser: (state, action) => {
      const { companyId, user } = action.payload;
      const company = state.data.find((company) => company._id === companyId);
      if (company) {
        company.users.push(user);
      }
    },
    removeUser: (state, action) => {
      const { companyId, userId } = action.payload;
      const company = state.data.find((company) => company._id === companyId);
      if (company) {
        company.users = company.users.filter((user) => user._id !== userId);
      }
    },
    setLoading: (state, action) => {
      state.status = action.payload ? 'loading' : 'idle';
    },
    setDeleteId: (state, action) => {
      state.deletedID = action.payload;
    },
    setEditedId: (state, action) => {
      state.editedID = action.payload;
    },
    onConfirmDeletedCompany: (state) => {
      state.data = state.data.filter(
        (company) => company._id !== state.deletedID
      );
      state.deletedID = null;
    },
    onConfirmEditCompany: (state, action) => {
      const isFindIndex = state.data.findIndex(
        (company) => company._id === state.editedID
      );
      if (isFindIndex !== -1) {
        state.data[isFindIndex] = { ...action.payload };
      }
      state.editedID = null;
    },
    setOpenCompanySelector: (state, action) => {
      state.openCompanySelector = action.payload;
      if (!action.payload) {
        state.selectedCompany = null;
      }
    },
    setCompanySelector: (state, action) => {
      const company = state.data.find((company) => company._id === action.payload);
      if (company && (!state.selectedCompany || state.selectedCompany._id !== company._id)) {
        state.selectedCompany = { ...company };
      }
    },
    onDeleteCompanySuccess: (state, action) => {
      const companyId = action.payload;
      state.data = state.data.filter((company) => company._id !== companyId);
    },
    onDeleteCompanyFailure: (state, action) => {
      // Handle delete failure if needed
    },
  },
});

export const {
  setCompany,
  setOpenUserAdder,
  setOpenAddCompany,
  addUser,
  removeUser,
  setUsers,
  addNewCompany,
  setDefaultCompany,
  updateCompanyForm,
  updateNewCompanyForm,
  updateNewCompanyFormField,
  setAllCompanies,
  setDeleteId,
  setEditedId,
  onConfirmDeletedCompany,
  onConfirmEditCompany,
  setOpenCompanySelector,
  setCompanySelector,
  onDeleteCompanySuccess,
  onDeleteCompanyFailure,
  setLoading,
    setOpenDriverAdder
} = companySlice.actions;

export const getCompanyData = (state) => state.company.data;
export const getAllCompaniesSelector = (state) => state.company.data;
export const getCompanyNewForm = (state) => state.company.newForm;
export const getDeletedCompanyForm = (state) => state.company.deletedID;
export const getEditedIdForm = (state) => state.company.editedID;
export const getIsOpenCompanySelector = (state) => state.company.openCompanySelector;
export const getDefaultCompany = (state) => state.company.currentCompany;
export const getIsOpenUserAdder = (state) => state.company.openUserAdder;
export const getOpenAddCompany = (state) => state.company.openAddCompany;

export const getSelectedCompany = createSelector(
    state => state.company.selectedCompany,
    selectedCompany => selectedCompany
);

export const getUsers = createSelector(
  [getDefaultCompany],
  (currentCompany) => {
    return currentCompany? currentCompany.users : [];
  }
);

export default companySlice.reducer;
