import React from "react";
import { NavLink } from "react-router-dom";

function SettingsNavbar() {
    return(
        <div className="sticky flex flex-row gap-2 p-4 text-sm border-r border-indigo-100 top-12">
            <NavLink
            to={"/settings/user"}
            className="flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900 border rounded-full"
            >
            Account Settings
            </NavLink>
            <NavLink
            to={"/settings/company"}
            className="flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900 border rounded-full"
            >
            Company Settings
            </NavLink>
        </div>
    )
}

export default SettingsNavbar;